import React, {Component} from 'react';
import Webcam from "react-webcam";
import { Animate, AnimateGroup } from "react-simple-animate";
import { Toolbar, Paper, Tooltip, Button, FormControlLabel,TextField, Typography, CircularProgress, Checkbox, Select } from '@material-ui/core';
import ReCAPTCHA from "react-google-recaptcha";
import logo from '../assets/logo.png';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import IconButton from '@material-ui/core/IconButton';
import {companies,preguntas, preguntasEPF, privacypolicy, privacypolicyEPF, storageTests, shuffleArray} from '../utils/controller';
import { isEmail, isNumber } from '../utils';
import ReactCountdownClock from 'react-countdown-clock';

const siteKey = '6Lf9Xa8bAAAAADuAP1YLYJhHaqPhHLsSo5wsxD2e';
const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

export default class Prueba extends Component {
  constructor(props){
    super(props);
    this.state={
      isLoading:true,
      companyId:null,
      company:null,
      mail: '', 
      password: '',
      initialValueEPF: 0,
      initialLeft:3,
      initialRight:0,
      answers: {},
      answersEPF: {},
      user: null,
      dateStarted: 0,
      currentQuestion: 0,
      currentQuestionJoke: 0,
      currentQuestionEPF: 0,
      currentQuestionJokeEPF: 0,
      preguntasArr:[],
      preguntasEPFArr:[],
      honestyShield: [],
      error: false,
      leido: false,
      privacypolicy: '',
      privacypolicyEPF: '',
      pictureOn:[0, 6, 12, 18, 24, 29],
      secondAdvertence: false,
      step: 0,
      epfStep: 0,
      isFirstScreen: true,
      isEPP: false,
      isEPF: false,
      selectedIndexEPF: -1,
      isDisabledEPP: true,
      isDisabledEPF: true,
      isPausedCountdown: true,
      isCaptchaOk: false
    }
  }
  componentWillMount() {
    let companyId = this.props.match.params.id;
    let emailUrl = this.props.match.params.email;
    let passwordUrl = this.props.match.params.password;
    if(companyId){
      this.setState({companyId});
      preguntas.on('value',async snapshot => {
        let preguntasArr = Object.values(snapshot.toJSON());
        this.setState({preguntasArr});
        preguntasEPF.on('value',async snapshot => {
          const preguntasEPFOrigin = Object.values(snapshot.toJSON());
          let preguntasEPFArr = shuffleArray(preguntasEPFOrigin.map(obj => {
            const respuestas = shuffleArray(Object.values(obj.respuestas));
            obj.respuestas = respuestas;
            return obj;
          }));
          this.setState({preguntasEPFArr});
          companies.on('value', async snapshot => {
            let companiesArr = Object.values(snapshot.toJSON());
            let company = companiesArr.filter(obj=>obj.id===this.state.companyId)[0];
            this.setState({company});
            privacypolicy.on('value',async snapshot => {
              let privacypolicy = snapshot.toJSON();
              privacypolicyEPF.on('value',async snapshot => {
                let privacypolicyEPF = snapshot.toJSON();
                this.setState({privacypolicy, privacypolicyEPF, isLoading: false});
                if (emailUrl && passwordUrl) {
                  this.onLoginCustom(emailUrl, passwordUrl, company);
                }
              })
            })
          })
        })
      })
    }
  }
  setRef = webcam => {
      this.webcam = webcam;
  };
  changeValueEPF = (initialValueEPF, selectedIndexEPF)=>{
    this.setState({initialValueEPF, selectedIndexEPF})
  }
  changeLeft=(e,question)=>{
    let initialLeft = e.target.value;
    if(isNumber(initialLeft)) {
      let initialRight = 0;
      switch (initialLeft) {
        case "":
            initialRight = 3;
            initialLeft = "";
        break;
        case "0":
            initialRight = 3;
            initialLeft = 0;
            break;
        case "1":
            initialRight = 2;
            initialLeft = 1;
            break;
        case "2":
            initialRight = 1;
            initialLeft = 2;
            break;
        case "3":
            initialRight = 0;
            initialLeft = 3;
            break;
        default: 
            initialRight = 3;
            initialLeft = "";
            break;
      }
      this.setState({initialLeft,initialRight})
    }
  }
    changeRight=(e,question)=>{
        let initialRight = e.target.value;
        if(isNumber(initialRight)){
            let initialLeft = 0;
            switch (initialRight) {
                case "":
                    initialLeft = 3;
                    initialRight = "";
                    break;
                case "0":
                    initialLeft = 3;
                    initialRight = 0;
                    break;
                case "1":
                    initialLeft = 2;
                    initialRight = 1;
                    break;
                case "2":
                    initialLeft = 1;
                    initialRight = 2;
                    break;
                case "3":
                    initialLeft = 0;
                    initialRight = 3;
                    break;
                default: 
                    initialLeft = 3;
                    initialRight = "";
                    break;
            }
            this.setState({initialLeft,initialRight})
        }
    }
    renderNextQuestion = (question) => {
      window.scrollTo(0, 0);
      this.setState({isLoading: true});
      const {company, pictureOn,honestyShield, initialLeft,initialRight, currentQuestion, answers,companyId,user, dateStarted} = this.state;
      question.leftResult = initialLeft;
      question.rightResult = initialRight;
      answers[currentQuestion] = question;
      companies.child(companyId+'/candidates/'+user.id+'/answers').set(answers)
      companies.child(companyId+'/candidates/'+user.id+'/dateFinished').set(Date.now())
      companies.child(companyId+'/candidates/'+user.id+'/dateStarted').set(dateStarted)
      if (user.honestyShield === true) {
        if(pictureOn.includes(currentQuestion)){
          const imageSrc = this.webcam.getScreenshot();
          if (imageSrc) {
            storageTests.child(Date.now()+'').putString(imageSrc, 'data_url', {contentType:'image/png'}).then(function(snapshot) {
              snapshot.ref.getDownloadURL().then(function(downloadURL) {
                honestyShield.push(downloadURL);
                companies.child(companyId+'/candidates/'+user.id+'/honestyShieldPictures').set(honestyShield);
              });
            });
          }
        }  
      }
      if(currentQuestion === 0) {
        companies.child(companyId+'/candidates/'+user.id+'/started').set(true)
        let examsLeft = company.examsLeft;
        examsLeft--;
        companies.child(companyId+'/examsLeft/').set(examsLeft);
      }
      if(currentQuestion === 29) {
        companies.child(companyId+'/candidates/'+user.id+'/finished').set(true)
      }
      this.setState({currentQuestion: currentQuestion+1, initialLeft: 3, initialRight: 0,answers, honestyShield,isLoading: false});
    }

    renderNextQuestionEPF = (question) => {
      window.scrollTo(0, 0);
      this.setState({isLoading: true});
      const {company, pictureOn,honestyShield, currentQuestionEPF, answersEPF, companyId,user, dateStarted, preguntasEPFArr, initialValueEPF} = this.state;
      question.answer = initialValueEPF;
      answersEPF[currentQuestionEPF] = question;
      companies.child(companyId+'/candidates/'+user.id+'/answersEPF').set(answersEPF)
      companies.child(companyId+'/candidates/'+user.id+'/dateFinishedEPF').set(Date.now())
      companies.child(companyId+'/candidates/'+user.id+'/dateStartedEPF').set(dateStarted)
      if (user.honestyShield === true) {
        if(pictureOn.includes(currentQuestionEPF)){
          const imageSrc = this.webcam.getScreenshot();
          if (imageSrc) {
            storageTests.child(Date.now()+'').putString(imageSrc, 'data_url', {contentType:'image/png'}).then(function(snapshot) {
              snapshot.ref.getDownloadURL().then(function(downloadURL) {
                honestyShield.push(downloadURL);
                companies.child(companyId+'/candidates/'+user.id+'/honestyShieldPicturesEPF').set(honestyShield);
              });
            });
          }
        }  
      }
      if(currentQuestionEPF === 0) {
        companies.child(companyId+'/candidates/'+user.id+'/startedEPF').set(true)
        let testsLeft = company.testsLeft;
        testsLeft--;
        companies.child(companyId+'/testsLeft/').set(testsLeft);
      }
      if(currentQuestionEPF === preguntasEPFArr.length - 1) {
        companies.child(companyId+'/candidates/'+user.id+'/finishedEPF').set(true)
      }
      this.setState({
        currentQuestionEPF: currentQuestionEPF+1, 
        answersEPF, 
        honestyShield,
        isLoading: false, 
        selectedIndexEPF: -1,
        initialValueEPF: -1,
        isCaptchaOk: false
      });
    }

    renderNextQuestionJoke = (question) => {
        const {currentQuestionJoke} = this.state;
        window.scrollTo(0, 0);
        this.setState({
          currentQuestionJoke: currentQuestionJoke+1,
          isPausedCountdown: true
        });
    }

    renderNextQuestionJokeEPF = (question) => {
      const {currentQuestionJokeEPF} = this.state;
      window.scrollTo(0, 0);
      this.setState({
        currentQuestionJokeEPF: currentQuestionJokeEPF+1, 
        selectedIndexEPF: -1,
        isPausedCountdown: true
      });
    }
    validate = () => {
        const {mail, password} = this.state;
        if(mail!=='' &&  password!=='' && isEmail(mail)){
            return false;
        }
        return true;
    }

    onLoginCustom = (mail, password, company) => {
      this.setState({mail, password, company});
      if(company){
        if(company.examsLeft >=1){
          if(company.candidates){
            this.setState({isLoading:true});
            const candidates = Object.values(company.candidates);
            let user = candidates.filter(obj=>obj.mail === mail)[0];
            if(user){
              if(user.password === password) {
                if(user.started === true && user.startedEPF === true) {
                  this.setState({error: true, isLoading:false})
                } else{
                  this.setState({user, isLoading:false, error: false, dateStarted: Date.now()})
                }
              } else {
                this.setState({error: true, isLoading:false})
              }
            } else {
              this.setState({error: true, isLoading:false})
            }
          }
        } 
      }
    }
    onLoginClick=()=>{
        const {mail, password, company} = this.state;
        if(company) {
          if(company.examsLeft > 0 || company.testsLeft > 0) {
            if(company.candidates){
              this.setState({isLoading:true});
              const candidates = Object.values(company.candidates);
              let user = candidates.filter(obj=>obj.mail === mail)[0];
              if(user){
                  if(user.password === password) {
                    if(user.started === true && user.startedEPF === true) {
                      this.setState({error: true, isLoading:false})
                    } else{
                      this.setState({user, isLoading:false, error: false, dateStarted: Date.now()})
                    }
                  } else {
                    this.setState({error: true, isLoading:false})
                  }
              }
              else {
                this.setState({error: true, isLoading:false})
              }
            }
          } 
        }
    }
    renderButton = () => {
        const {isLoading} = this.state;
        if (!isLoading){
            return(
              <Button
                type="button"
                fullWidth
                variant="contained"
                disabled={this.validate()}
                color="primary"
                onClick={this.onLoginClick}
                className={"submit"}
              >
                Iniciar Sesión
              </Button>
            )
        }
        return <CircularProgress color="secondary" className={"progress"}/>;
    }
    renderMessage = () => {
        return this.state.error && <div style={{color:'red'}}> <Typography variant="subtitle1" style={{color: 'red'}}>Error, usuario incorrecto </Typography> </div>;
    }
    renderer = () => {
      const {isEPF, isEPP, step, epfStep, user} = this.state;
      if (isEPP) {
        switch(step) { 
          case 0 :
            return this.renderStep0();                
          case 1:
            return this.renderStep1();
          case 1.5:
            if (user.honestyShield === true) {
              return this.renderCameraScreen(true);
            } else return this.renderStep2();
          case 2: 
            return this.renderStep2();
          case 3: 
            return this.renderStep3();
          case 4: 
            return this.renderStep4();
          case 5: 
            return this.renderStep5();
          case 6: 
            return this.renderStep6();
          default:
            return this.renderStep0();
        }
      } else if (isEPF) {
        switch(epfStep) { 
          case 0 :
            return this.renderEPFStep0();                
          case 0.5 :
            if (user.honestyShield === true) {
              return this.renderCameraScreen(false);
            } else return this.renderEPFStep1();           
          case 1:
            return this.renderEPFStep1();
          case 2: 
            return this.renderEPFStep2();
          case 3: 
            return this.renderEPFStep3();
          case 4: 
            return this.renderEPFStep4();
          case 5: 
            return this.renderEPFStep5();
          default:
            return this.renderEPFStep0();
        }
      }
      return null;
    }
    
    _onReady = (event) => event.target.pauseVideo();

    renderEPFStep0 = () => {
      const {company} = this.state;
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
          <Toolbar >
            <Tooltip title="Atras">
              <IconButton onClick={()=>this.setState({isEPP: false, isEPF: false, isFirstScreen: true})}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6">{company.name + ' - Política de privacidad'} </Typography>
            <div className="spacer" />
            <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
          </Toolbar>
          <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
              <Typography variant="body2" style={{textAlign: 'justify', fontSize: 16}}>
                  {company.name + ' ' + this.state.privacypolicyEPF}
              </Typography>
              <br />
              <br />
              <br />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.leido}
                    onChange={(e)=>this.setState({leido:e.target.checked})}
                  />
                }
                label="He leído y acepto la política de privacidad"
              />
              <br />
              <br />
              <Button
                variant="outlined"
                onClick={() => {
                  window.scrollTo(0, 0);
                  this.setState({epfStep: 0.5, leido: false});
                }}
                disabled={!this.state.leido}
              >
                Siguiente
              </Button>
          </div>
        </Paper>
      );
    }

    renderEPFStep1 = () => {
      const {company} = this.state;
      return (
        <>
          <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
                <Tooltip title="Atras">
                    <IconButton onClick={()=>this.setState({epfStep: 0, leido: false})}>
                        <ArrowBackIos />
                    </IconButton>
                </Tooltip>
                <Typography variant="h6" style={{width: 500}}>{company.name + ' - Instrucciones'} </Typography>
                <div className="spacer" />
                <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                <Typography variant='body2' style={{fontSize: 16}}>
                    Está a punto de iniciar su evaluación. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                    Relájese
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                    En este test nadie aprueba ni reprueba, solamente arroja indicadores que permiten conocer su adecuación al puesto. Sin embargo, deberá contestar con la mayor velocidad posible, esto debido a que se limita el tiempo de respuesta de cada pregunta. Es importante que conteste todas las preguntas, de lo contrario su resultado se verá afectado.
                </Typography>
                <Typography variant='body2' style={{fontSize: 16}}>
                    Recuerde ver cada uno de los videos explicativos mostrados durante toda la evaluación. Los videos cuentan con audio, sin embargo si su dispositivo no cuenta con bocinas o audífonos, podrá leer los subtítulos que se muestran debajo de ellos. Puede reproducir los videos el numero de veces que considere necesarios para su comprensión, sin embargo le recomendamos que preste atención a los mismos para que no tenga que repetir el proceso en reiteradas ocasiones, ya que ello provocará que tarde mucho más en rendir su evaluación.
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                No hay respuestas "buenas" o "malas", "correctas" o "incorrectas". No trate de contestar lo que usted cree que se espera de usted. Conteste lo que sinceramente considera cierto según sea el caso en cada caso o pregunta. Si no lo hace así, el sistema no arrojará calificación.
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                A continuación vendrá un ejercicio sin ningún peso en el resultado, es decir que no afectará en su resultado final, únicamente sirve para que se familiarice con la forma en que se le presentarán las preguntas y la forma en que debe contestarlas. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                    Lea las preguntas y elija las opción de respuesta que considere oportuna en base a lo que considera cierto según sea el caso. Una vez leída la pregunta, conteste lo más rápidamente posible, si no lo hace así, no tendrá tiempo suficiente para responder.
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                Si tiene alguna duda antes de iniciar, si no se siente bien o tiene un impedimento para hacer la evaluación, por favor comuníqueselo en este momento al evaluador encargado. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                ¡Por favor apague todos los aparatos que puedan distraerle!
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                Usted podrá usar el teclado o el mouse para responder. Una vez que se decida por uno de los dos, se recomienda no cambiar entre uno y otro. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                Vamos a iniciar el proceso de evaluación.
                </Typography>
                <br />
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.leido}
                            onChange={(e)=>this.setState({leido:e.target.checked})}
                        />
                    }
                    label="He leído y acepto la información"
                />
                <br />
                <br />
                <Button
                    variant="outlined"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        this.setState({epfStep: 2, leido: true});
                    }}
                    disabled={!this.state.leido}
                >
                    Siguiente
                </Button>
            </div>
          </Paper>
        </>
      )
    }

    renderEPFStep2 = () => {
      const {company} = this.state;
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
        <Toolbar >
            <Tooltip title="Atras">
                <IconButton onClick={()=>this.setState({epfStep: 1, leido: false})}>
                    <ArrowBackIos />
                </IconButton>
            </Tooltip>
            <Typography variant="h6" style={{width: 500}}>{company.name + ' - Tutorial'} </Typography>
            <div className="spacer" />
            <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
        </Toolbar>
        <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
            <iframe src="https://worknesty.com/videos/2.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="2" />
            <br />
            <br />
            <Button
            variant="outlined"
            onClick={() => {
                window.scrollTo(0, 0);
                this.setState({epfStep: 3, leido: false});
            }}
            >
                Entendido
            </Button>
        </div>
        </Paper>
      )
    }

    renderEPFStep3 = () => {
        const {company} = this.state;
        return (
          <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
              <Tooltip title="Atras">
                <IconButton onClick={()=>this.setState({epfStep: 2, leido: false})}>
                  <ArrowBackIos />
                </IconButton>
              </Tooltip>
              <Typography variant="h6" style={{width: 900}}>{company.name + ' - Preguntas de Prueba'} </Typography>
              <div className="spacer" />
              <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
              <iframe src="https://worknesty.com/videos/3.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="3" />
              <br />
              <br />
              <br />
              <br />
              <Typography variant='body2' style={{fontSize: 16}}>
              A continuación encontrará 2 preguntas de prueba que le permitirán familiarizarse con la evaluación. 
              </Typography>
              <br />
              <Typography variant='body2' style={{fontSize: 16}}>
              Se mostrará una pregunta, seleccione la opción que corresponda según lo que considere <b>cierto </b> 
              de cada frase en su vida personal.
              Recuerde seleccionar una respuesta dentro del tiempo disponible.
              </Typography>
              <br />
              <br />
              <br />
              <br />
              <Button
                variant="outlined"
                onClick={() => {
                    window.scrollTo(0, 0);
                    this.setState({epfStep: 4, leido: false});
                }}
              >
                Entendido
              </Button>
            </div>
          </Paper>
        )
    }

    renderEPFStep4 = () => {
      const {currentQuestionJokeEPF, company} = this.state;
      const preguntas = [
        {
          numero : 1,
          plantilla : "Información Personal",
          pregunta : "En este momento vivo",
          respuestas : [
            {
              respuesta : "Solo",
              valor : 5
            }, 
            {
              respuesta : "Con mis padres",
              valor : 5
            }, 
            {
              respuesta : "Con mi pareja",
              valor : 5
            }, 
            {
              respuesta : "Con amigos",
              valor : 5
            }, 
            {
              respuesta : "Otro",
              valor : 5
            }, 
          ]
        },
        {
          numero : 2,
          plantilla : "Información Personal",
          pregunta : "La evaluación sirve para que la organización conozca mejor mis capacidades",
          respuestas : [
            {
              respuesta : "Cierto",
              valor : 5
            }, 
            {
              respuesta : "Falso",
              valor : 5
            }, 
          ]
        },
      ]
      const questions = preguntas.length > 0 && preguntas.map((question,index)=>{
        return (
          <div key={index}>
            <AnimateGroup play>
              {/*<Animate 
                play 
                duration={0.2} 
                start={{ opacity: '0' }} 
                end={{ opacity: '1' }} 
                sequnceIndex={question.respuestas.length} 
                key={index} 
                delay={question.respuestas.length+1}
              >*/}
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center', paddingTop: 20, paddingBottom: 20}}>
                  <ReactCountdownClock 
                    seconds={30} 
                    color="#db4a4a" 
                    size={75} 
                    onComplete={e=>this.renderNextQuestionJokeEPF(question)} 
                    style={{margin:'0 auto',display:'table', alignSelf: 'center'}}
                    paused={this.state.isPausedCountdown}
                  />
                </div>
              {/*</Animate>*/}
              <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', }}>
                <Animate play duration={0.2} start={{ opacity: '0' }} end={{ opacity: '1' }} sequnceIndex={0} key={index} delay={0}>
                  <Typography variant="subtitle1" style={{textAlign: 'center'}}>
                    {question.pregunta}
                  </Typography>
                </Animate>
                <br />
                <br />
                <div style={{
                  display: 'block'
                }}>
                    {
                      question.respuestas.map((obj, index) => {
                        const number = index + 1;
                        return (
                          <Animate 
                            play 
                            duration={0.2} 
                            start={{ opacity: '0' }} 
                            end={{ opacity: '1' }} 
                            sequnceIndex={number} 
                            key={index} 
                            delay={number}
                            onComplete={() => {
                              if (index === question.respuestas.length - 1) {
                                this.setState({isPausedCountdown: false});
                              }
                            }}
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                this.changeValueEPF(obj.valor, index);
                                this.renderNextQuestionJokeEPF(question);
                              }}
                              style={{
                                marginBottom: 20,
                                textTransform: 'inherit'
                              }}
                            >
                              {number + '. '+ obj.respuesta}
                            </Button>
                          </Animate>
                        )
                      })
                    }
                  </div>
              </div>
            </AnimateGroup>
           
          </div>
        );
      });
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
              <Typography variant="h6" style={{width: 900}}>{currentQuestionJokeEPF !== 2 ? company.name + ' - Preguntas de Prueba' : company.name + ' - Primera Parte'} </Typography>
              <div className="spacer" />
              <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style={{display:'table',margin:'0 auto'}}>
              {questions[currentQuestionJokeEPF]}
            </div>
            {
              currentQuestionJokeEPF === 2 &&
              <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                <Typography variant="h6"><span style={{fontWeight: 'bold'}}>Empezar Evaluación</span></Typography>
                <br />
                <br />
                <iframe src="https://worknesty.com/videos/4.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="4"/>
                <br />
                <br />
                <br />
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                El ejercicio ha concluido ahora empezará el examen. Ponga mucha atención no se distraiga, ya que el tiempo no se detiene y no podrá volver a las preguntas anteriores. Por favor haga clic en el botón de Aceptar, gracias y buena suerte.
                </Typography>
                <br />
                <br />
                <br />
                <br />
                <Button
                  variant="outlined"
                  onClick={e=>this.setState({epfStep: 5, leido: false})}
                >
                  Aceptar
                </Button>
              </div>
            }
        </Paper>
      );
    }

    renderEPFStep5 = () => {
      const {currentQuestionEPF,company, isLoading, user, preguntasEPFArr, isCaptchaOk} = this.state;
      if(isLoading) {
        return <CircularProgress style={{margin:'0 auto',display:'table', marginTop: 100}}  color="secondary"/>
      }
      const questions = preguntasEPFArr.length > 0 && preguntasEPFArr.map((question,index)=>{
        return (
          <div key={index}>
           <br />
           <br />
           <br />
            {
              index%10 === 0 ? (
                <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', }}>
                  {
                    isCaptchaOk ? (
                      <AnimateGroup play>
                        {/*<Animate play duration={0.2} start={{ opacity: '0' }} end={{ opacity: '1' }} sequnceIndex={question.respuestas.length} key={index} delay={question.respuestas.length+1}>*/}
                          <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center', paddingTop: 20, paddingBottom: 20}}>
                            <ReactCountdownClock 
                              paused={this.state.isPausedCountdown} 
                              seconds={30} 
                              color="#db4a4a" 
                              size={75} 
                              onComplete={e=>this.renderNextQuestionEPF(question)} 
                              style={{margin:'0 auto',display:'table', alignSelf: 'center'}}
                            />
                          </div>
                        {/*</Animate>*/}
                        <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', }}>
                          <Animate play duration={0.2} start={{ opacity: '0' }} end={{ opacity: '1' }} sequnceIndex={0} key={index} delay={0}>
                            <Typography variant="subtitle1" style={{textAlign: 'center'}}>
                              {question.pregunta}
                            </Typography>
                          </Animate>
                          <br />
                          <br />
                          <div style={{
                            display: 'block'
                          }}>
                              {
                                question.respuestas.map((obj, index) => {
                                  const number = index + 1;
                                  return (
                                    <Animate 
                                      play 
                                      duration={0.2} 
                                      start={{ opacity: '0' }} 
                                      end={{ opacity: '1' }} 
                                      sequnceIndex={number} 
                                      key={index} 
                                      delay={number}
                                      onComplete={() => {
                                        if (index === question.respuestas.length - 1) {
                                          this.setState({isPausedCountdown: false});
                                        }
                                      }}
                                    >
                                      <Button
                                        variant="outlined"
                                        onClick={() => {
                                          this.changeValueEPF(obj.valor, index);
                                          this.renderNextQuestionEPF(question);
                                        }}
                                        style={{
                                          marginBottom: 20,
                                          textTransform: 'inherit'
                                        }}
                                      >
                                        {number + '. '+ obj.respuesta}
                                      </Button>
                                    </Animate>
                                  )
                                })
                              }
                          </div>
                        </div>
                      </AnimateGroup>
                    ) : (
                      <ReCAPTCHA
                        sitekey={siteKey}
                        onChange={this.onChangeRecaptchaQuestions}
                      />
                    )
                  }
                </div>
              ) : (
                <>
                  <AnimateGroup play>
                  {/*<Animate play duration={0.2} start={{ opacity: '0' }} end={{ opacity: '1' }} sequnceIndex={question.respuestas.length} key={index} delay={question.respuestas.length+1}>*/}
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center', paddingTop: 20, paddingBottom: 20}}>
                      <ReactCountdownClock 
                        paused={this.state.isPausedCountdown} 
                        seconds={30} 
                        color="#db4a4a" 
                        size={75} 
                        onComplete={e=>this.renderNextQuestionEPF(question)} 
                        style={{margin:'0 auto',display:'table', alignSelf: 'center'}}
                      />
                    </div>
                  {/*</Animate>*/}
                  <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', }}>
                    <Animate play duration={0.2} start={{ opacity: '0' }} end={{ opacity: '1' }} sequnceIndex={0} key={index} delay={0}>
                      <Typography variant="subtitle1" style={{textAlign: 'center'}}>
                        {question.pregunta}
                      </Typography>
                    </Animate>
                    <br />
                    <br />
                    <div style={{
                      display: 'block'
                    }}>
                        {
                          question.respuestas.map((obj, index) => {
                            const number = index + 1;
                            return (
                              <Animate 
                                play 
                                duration={0.2} 
                                start={{ opacity: '0' }} 
                                end={{ opacity: '1' }} 
                                sequnceIndex={number} 
                                key={index} 
                                delay={number}
                                onComplete={() => {
                                  if (index === question.respuestas.length - 1) {
                                    this.setState({isPausedCountdown: false});
                                  }
                                }}
                              >
                                <Button
                                  variant="outlined"
                                  onClick={() => {
                                    this.changeValueEPF(obj.valor, index);
                                    this.renderNextQuestionEPF(question);
                                  }}
                                  style={{
                                    marginBottom: 20,
                                    textTransform: 'capitalize'
                                  }}
                                >
                                  {number + '. '+ obj.respuesta}
                                </Button>
                              </Animate>
                            )
                          })
                        }
                    </div>
                  </div>
                </AnimateGroup>
                </>
              )
            }
          <br />
          <br />
          <br />
        </div>
        );
      });
      return (
        <>
          <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
                <Typography variant="h6" style={{width: 900}}>{company.name +' - Evaluación Psicológica Forense'}</Typography>
                <div className="spacer" />
                <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style={{display:'table',margin:'0 auto'}}>
              {questions[currentQuestionEPF]}
            </div>
            {
              currentQuestionEPF === preguntasEPFArr.length - 1 &&
              <div style={{padding: 10, display: 'flex', flexDirection: 'column', margin: '0 auto'}}>
                <iframe src="https://worknesty.com/videos/6.html" style={{width: '100%', height: 390, maxWidth: 700, alignSelf: 'center'}} title="6" />
                <br />
                <br />
                <Typography style={{textAlign: 'center'}} variant="h6" >Has terminado tu evaluación, los resultados han sido enviados exitosamente, ya puedes cerrar esta ventana. Gracias.</Typography>
              </div>
            }
          </Paper>
          {
            user.honestyShield === true &&
              <div style={{visibility:'hidden'}}>
                  <Webcam 
                    audio={false}
                    screenshotFormat={'image/png'}
                    ref={this.setRef}
                    videoConstraints={videoConstraints}
                    height={1280}
                    width={1280}
                  />
              </div>
          }
        </>
      );
    }

    handleAcceptCamera = () => {
      const {isEPP, isEPF} = this.state;
      window.scrollTo(0, 0);
      if (isEPP) {
        this.setState({step: 3});
      } else if (isEPF) {
        this.setState({epfStep: 1});
      }
      /*
      navigator.getMedia({video: true}, function() {
      }, function() {
          this.setState({step: 0, epfStep: 0});
      });
      */
    }

    renderCameraScreen = (isEPP = true) => {
      const {company} = this.state;
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
          <Toolbar >
              <Tooltip title="Atras">
                  <IconButton onClick={()=> isEPP ? this.setState({step: 1.5, leido: false}) : this.setState({epfStep: 0, leido: false})}>
                      <ArrowBackIos />
                  </IconButton>
              </Tooltip>
              <Typography variant="h6" style={{width: 500}}>{company.name}</Typography>
              <div className="spacer" />
              <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
          </Toolbar>
          <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', alignContent: 'center', padding: 20}}>
            <Typography variant="h6" style={{textAlign: 'center'}}>Honesty Shield</Typography>
            <Typography variant="body1">El sistema identificará al evaluado a través de fotografías aleatorias a través del test que se presentan al final del reporte.</Typography>
            <Typography variant="body1">La empresa ha solicitado el uso de <b>Honesty Shield</b> para su evaluación, por lo que requiere su autorización para continuar. En caso de rechazar, el sistema no le permitirá continuar y deberá notificar a la empresa que le asignó la evaluación.</Typography>
            <br />
            <br />
            <Webcam 
                audio={false}
                screenshotFormat={'image/png'}
                ref={this.setRef}
                videoConstraints={videoConstraints}
                style={{width: '100%', maxHeight: 720}}
            />
            <br />
            <br />
            <Button
                variant="outlined"
                style={{maxWidth: 100, alignSelf: 'center'}}
                onClick={() => this.handleAcceptCamera()}
            >
                Aceptar
            </Button>
          </div>
        </Paper>
      );
    }

    renderStep0 = () => {
      const {company} = this.state;
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
          <Toolbar>
            <Tooltip title="Atras">
              <IconButton onClick={()=>this.setState({isEPP: false, isEPF: false, isFirstScreen: true})}>
                <ArrowBackIos />
              </IconButton>
            </Tooltip>
            <Typography variant="h6" style={{width: 500}}>{company.name} </Typography>
            <div className="spacer" />
            <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
          </Toolbar>
          <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
            <iframe src="https://worknesty.com/videos/1.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="1"/>
            <br />
            <br />
            <br />
            <br />
            <Button
              variant="outlined"
              onClick={() => {
                  window.scrollTo(0, 0);
                  this.setState({step: 1, leido: false});
              }}
            >
              Siguiente
            </Button>
          </div>
        </Paper>
      )
    }
    renderStep1 = () => {
      const {company} = this.state;
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
          <Toolbar>
              <Tooltip title="Atras">
                  <IconButton onClick={()=>this.setState({step: 0, leido: false})}>
                      <ArrowBackIos />
                  </IconButton>
              </Tooltip>
              <Typography variant="h6">{company.name + ' - Política de privacidad'} </Typography>
              <div className="spacer" />
              <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
          </Toolbar>
          <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
              <Typography variant="body2" style={{textAlign: 'justify', fontSize: 16}}>
                  {company.name + ' ' + this.state.privacypolicy}
              </Typography>
              <br />
              <br />
              <br />
              <FormControlLabel
                  control={
                      <Checkbox
                          checked={this.state.leido}
                          onChange={(e)=>this.setState({leido:e.target.checked})}
                      />
                  }
                  label="He leído y acepto la política de privacidad"
              />
              <br />
              <br />
              <Button
                  variant="outlined"
                  onClick={() => {
                      window.scrollTo(0, 0);
                      this.setState({step: 1.5, leido: false});
                  }}
                  disabled={!this.state.leido}
              >
                  Siguiente
              </Button>
          </div>
        </Paper>
      );
    }
    renderStep2 = () => {
      const {company} = this.state;
      return (
        <>
          <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
                <Tooltip title="Atras">
                    <IconButton onClick={()=>this.setState({step: 1.5, leido: false})}>
                        <ArrowBackIos />
                    </IconButton>
                </Tooltip>
                <Typography variant="h6" style={{width: 500}}>{company.name + ' - Instrucciones'} </Typography>
                <div className="spacer" />
                <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                <Typography variant='body2' style={{fontSize: 16}}>
                    Está a punto de iniciar su evaluación. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                    Relájese
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                    En este test nadie aprueba ni reprueba, solamente arroja indicadores que permiten conocer su adecuación al puesto. Sin embargo, deberá contestar con la mayor velocidad posible, esto debido a que se limita el tiempo de respuesta de cada pregunta. Es importante que conteste todas las preguntas, de lo contrario su resultado se verá afectado.
                </Typography>
                <Typography variant='body2' style={{fontSize: 16}}>
                    Recuerde ver cada uno de los videos explicativos mostrados durante toda la evaluación. Los videos cuentan con audio, sin embargo si su dispositivo no cuenta con bocinas o audífonos, podrá leer los subtítulos que se muestran debajo de ellos. Puede reproducir los videos el numero de veces que considere necesarios para su comprensión, sin embargo le recomendamos que preste atención a los mismos para que no tenga que repetir el proceso en reiteradas ocasiones, ya que ello provocará que tarde mucho más en rendir su evaluación.
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                No hay respuestas "buenas" o "malas", "correctas" o "incorrectas". No trate de contestar lo que usted cree que se espera de usted. Conteste lo que sinceramente considera más importante o más inaceptable según sea el caso en cada caso o pregunta. Si no lo hace así, el sistema no arrojará calificación.
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                A continuación vendrá un ejercicio sin ningún peso en el resultado, es decir que no afectará en su resultado final, únicamente sirve para que se familiarice con la forma en que se le presentarán las preguntas y la forma en que debe contestarlas. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                    Lea las preguntas y elija las opción de respuesta que considere oportuna en base a lo que considera más importante o más inaceptable según sea el caso. Una vez leída la pregunta, conteste lo más rápidamente posible, si no lo hace así, no tendrá tiempo suficiente para responder.
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                Si tiene alguna duda antes de iniciar, si no se siente bien o tiene un impedimento para hacer la evaluación, por favor comuníqueselo en este momento al evaluador encargado. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                ¡Por favor apague todos los aparatos que puedan distraerle!
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                Usted podrá usar el teclado o el mouse para responder. Una vez que se decida por uno de los dos, se recomienda no cambiar entre uno y otro. 
                </Typography>
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                Vamos a iniciar el proceso de evaluación.
                </Typography>
                <br />
                <br />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.leido}
                            onChange={(e)=>this.setState({leido:e.target.checked})}
                        />
                    }
                    label="He leído y acepto la información"
                />
                <br />
                <br />
                <Button
                    variant="outlined"
                    onClick={() => {
                        window.scrollTo(0, 0);
                        this.setState({step: 3, leido: true});
                    }}
                    disabled={!this.state.leido}
                >
                    Siguiente
                </Button>
            </div>
          </Paper>
        </>
      )
    }
    renderStep3 = () => {
        const {company, user} = this.state;
        return (
            <>
                <Paper style={{width:'95%',margin:'0 auto'}}>
                    <Toolbar >
                        <Tooltip title="Atras">
                            <IconButton onClick={()=>this.setState({step: 2, leido: false})}>
                                <ArrowBackIos />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="h6" style={{width: 500}}>{company.name + ' - Tutorial'} </Typography>
                        <div className="spacer" />
                        <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
                    </Toolbar>
                    <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                        <iframe src="https://worknesty.com/videos/2.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="2" />
                        <br />
                        <br />
                        <Button
                            variant="outlined"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                this.setState({step: 4, leido: false});
                            }}
                        >
                            Entendido
                        </Button>
                    </div>
                </Paper>
                {
                    user.honestyShield === true &&
                        <div style={{visibility:'hidden'}}>
                            <Webcam 
                                audio={false}
                                screenshotFormat={'image/png'}
                                ref={this.setRef}
                                videoConstraints={videoConstraints}
                                height={1280}
                                width={1280}
                            />
                        </div>
                }
            </>
        )
    };
    renderStep4 = () => {
        const {company, user} = this.state;
        return (
            <>
                <Paper style={{width:'95%',margin:'0 auto'}}>
                    <Toolbar >
                        <Tooltip title="Atras">
                            <IconButton onClick={()=>this.setState({step: 3, leido: false})}>
                                <ArrowBackIos />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="h6" style={{width: 900}}>{company.name + ' - Preguntas de Prueba'} </Typography>
                        <div className="spacer" />
                        <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
                    </Toolbar>
                    <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                        <iframe src="https://worknesty.com/videos/3.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="3" />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Typography variant='body2' style={{fontSize: 16}}>
                        A continuación encontrará 2 preguntas de prueba que le permitirán familiarizarse con la evaluación. 
                        </Typography>
                        <br />
                        <Typography variant='body2' style={{fontSize: 16}}>
                        Se mostrarán 2 frases con 2 casillas de respuesta, seleccione la opción que corresponda según la <b>importancia</b> de cada frase en su vida personal. Las únicas opciones de respuesta son: (Muy Importante – No es Importante), (Importante - Poco Importante), (Poco Importante – Importante) y (No es importante – Muy Importante). Recuerde que al seleccionar una respuesta en cualquiera de las dos casillas, la otra casilla cambiará automáticamente en función de las opciones de respuesta aquí explicadas.
                        </Typography>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Button
                            variant="outlined"
                            onClick={() => {
                                window.scrollTo(0, 0);
                                this.setState({step: 5, leido: false});
                            }}
                        >
                            Entendido
                        </Button>
                    </div>
                </Paper>
                {
                    user.honestyShield === true &&
                        <div style={{visibility:'hidden'}}>
                            <Webcam 
                                audio={false}
                                screenshotFormat={'image/png'}
                                ref={this.setRef}
                                videoConstraints={videoConstraints}
                                height={1280}
                                width={1280}
                            />
                        </div>
                }
            </>
        )
    };
    renderStep5 = () => {
      const {currentQuestionJoke,company} = this.state;
      const preguntas = [
        {
          enunciadoDerecho: 'Actúo con valor',
          enunciadoIzquierdo: 'Muestro cariño a las personas que me importan',
          valorDerecho: 'Rectitud',
          valorIzquierdo: 'Amor'
        },
        {
          enunciadoDerecho: 'Actúo con cobardía',
          enunciadoIzquierdo: 'Muestro egoismo a las personas que me importan',
          valorDerecho: 'Rectitud',
          valorIzquierdo: 'Amor'
        },
      ]
      const questions = preguntas.length>0 && preguntas.map((question,index)=>{
        return (
          <div key={index}>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center', paddingTop: 20, paddingBottom: 20}}>
                <ReactCountdownClock seconds={30} color="#db4a4a" size={75} onComplete={e=>this.renderNextQuestionJoke(question)} style={{margin:'0 auto',display:'table', alignSelf: 'center'}}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', }}>
                <div>
                    <Typography variant="subtitle1" style={{fontSize: 13}}>
                        {question.enunciadoIzquierdo}
                    </Typography>
                </div>
                <Select
                    value={this.state.initialLeft}
                    native
                    style={{width:180, marginLeft: 5, marginRight: 5}}
                    onChange={e=>this.changeLeft(e,question)}

                >
                    <option value={"3"}>Muy importante</option>
                    <option value={"2"}>Importante</option>
                    <option value={"1"}>Poco importante</option>
                    <option value={"0"}>No es importante</option>
                </Select>
                <br />
                <br />
                <div>
                    <Typography variant="subtitle1" style={{fontSize: 13}}>
                        {question.enunciadoDerecho}
                    </Typography>
                </div>
                <Select
                    value={this.state.initialRight}
                    native
                    style={{width:180, marginLeft: 5, marginRight: 5}}
                    onChange={e=>this.changeRight(e,question)}
                >
                    <option value={"3"}>Muy importante</option>
                    <option value={"2"}>Importante</option>
                    <option value={"1"}>Poco importante</option>
                    <option value={"0"}>No es importante</option>
                </Select>
            </div>
            <br />
            <div className="submitContainer">
                <Button
                    variant="outlined"
                    onClick={e=>this.renderNextQuestionJoke(question)}
                >
                    Siguiente
                </Button>
            </div>
            <br />
          </div>
        );
      });
      return (
        <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
              <Typography variant="h6" style={{width: 900}}>{currentQuestionJoke !== 2 ? company.name + ' - Preguntas de Prueba' : company.name + ' - Primera Parte'} </Typography>
              <div className="spacer" />
              <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style={{display:'table',margin:'0 auto'}}>
              {questions[currentQuestionJoke]}
            </div>
            {
              currentQuestionJoke === 2 &&
              <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                <Typography variant="h6"><span style={{fontWeight: 'bold'}}>EVALUACIÓN VALORES</span></Typography>
                <br />
                <br />
                <iframe src="https://worknesty.com/videos/4.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="4"/>
                <br />
                <br />
                <br />
                <br />
                <Typography variant='body2' style={{fontSize: 16}}>
                A continuación se mostrarán 2 frases con 2 casillas de respuesta, seleccione la opción que corresponda según la <b>importancia</b> de cada frase en su vida personal.  El puntaje más alto, será para la frase que indique lo <span style={{fontWeight: 'bold',  color: 'blue'}}> más importante </span> según su juicio. Las únicas opciones de respuesta son: (Muy Importante – No es Importante), (Importante - Poco Importante), (Poco Importante – Importante) y (No es importante – Muy Importante). Recuerde que al seleccionar una respuesta en cualquiera de las dos casillas de respuesta, la otra cambiará automáticamente en función de las opciones de respuesta aquí explicadas.
                </Typography>
                <br />
                <br />
                <br />
                <br />
                <Button
                  variant="outlined"
                  onClick={e=>this.setState({step: 6, leido: false})}
                >
                  Empezar evaluación
                </Button>
              </div>
            }
        </Paper>
      );
    }

    renderStep6 = () => {
      const {currentQuestion,company, isLoading, user, secondAdvertence} = this.state;
      if(isLoading) {
        return <CircularProgress style={{margin:'0 auto',display:'table', marginTop: 100}}  color="secondary"/>
      }
      const questions = this.state.preguntasArr.length>0 && this.state.preguntasArr.map((question,index)=>{
        if (index === 9 && secondAdvertence === false) {
          return (
            <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
              <Typography variant="h6"><span style={{fontWeight: 'bold'}}>EVALUACIÓN ANTIVALORES</span></Typography>
              <br />
              <br />
              <iframe src="https://worknesty.com/videos/5.html" style={{width: '100%', height: 390, maxWidth: 700,  }} title="5" />
              <br />
              <br />

              <Typography variant="body2" style={{fontSize: 16}}>
                A continuación se mostrarán 2 frases con 2 casillas de respuesta, seleccione la opción que corresponda <span style={{fontWeight: 'bold', color: 'blue'}}> para la frase más inaceptable</span>, según su juicio. El puntaje más alto, será para la frase que indique lo <span style={{fontWeight: 'bold', color: 'blue'}}>peor</span>. Las únicas opciones de respuesta son: (Inaceptable – Aceptable), (Muy Malo - Malo), (Malo – Muy Malo) y (Aceptable - Inaceptable). Recuerde que al seleccionar una respuesta en cualquiera de las dos casillas de respuesta, la otra cambiará automáticamente en función de las opciones de respuesta aquí explicadas.
              </Typography>
              <br />
              <Button
                variant="outlined"
                onClick={() => {
                  window.scrollTo(0, 0);
                  this.setState({secondAdvertence: true});
                }}
              >
                Continuar
              </Button>
            </div>
          )
        }
        return (
          <div key={index}>
            <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center', alignContent: 'center', paddingTop: 20, paddingBottom: 20}}>
              <ReactCountdownClock seconds={company?company.time ?company.time: 10:10} color="#db4a4a" size={75} onComplete={e=>this.renderNextQuestion(question)} style={{margin:'0 auto',display:'table', alignSelf: 'center'}}/>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'space-around', alignItems: 'center', alignContent: 'center', }}>
              <div>
                <Typography variant="subtitle1" style={{fontSize: 13}}>
                    {question.enunciadoIzquierdo}
                </Typography>
              </div>
              <Select
                value={this.state.initialLeft}
                native
                style={{width:180, marginLeft: 5, marginRight: 5}}
                onChange={e=>this.changeLeft(e,question)}
              >
                <option value={"3"}>{index < 9 ? 'Muy importante' : 'Inaceptable'}</option>
                <option value={"2"}>{index < 9 ? 'Importante' : 'Muy malo'}</option>
                <option value={"1"}>{index < 9 ? 'Poco Importante' : 'Malo'}</option>
                <option value={"0"}>{index < 9 ? 'No Es Importante' : 'Aceptable'}</option>
              </Select>
              <br />
              <br />
              <div>
                <Typography variant="subtitle1" style={{fontSize: 13}}>
                  {question.enunciadoDerecho}
                </Typography>
              </div>
              <Select
                native
                value={this.state.initialRight}
                style={{width:180, marginLeft: 5, marginRight: 5}} 
                onChange={e=>this.changeRight(e,question)}
              >
                <option value={"3"}>{index < 9 ? 'Muy importante' : 'Inaceptable'}</option>
                <option value={"2"}>{index < 9 ? 'Importante' : 'Muy malo'}</option>
                <option value={"1"}>{index < 9 ? 'Poco Importante' : 'Malo'}</option>
                <option value={"0"}>{index < 9 ? 'No Es Importante' : 'Aceptable'}</option>
              </Select>
            </div>
            <br />
            <div className="submitContainer">
              <Button
                variant="outlined"
                onClick={e=>this.renderNextQuestion(question)}
              >
                Siguiente
              </Button>
            </div>
            <br />
          </div>
        );
      });
      return (
        <>
          <Paper style={{width:'95%',margin:'0 auto'}}>
            <Toolbar >
                <Typography variant="h6" style={{width: 900}}>{currentQuestion === 30 ? company.name +' - Fin de la Evaluación' : currentQuestion < 9 ? company.name + ' - Primera Parte' : company.name + ' - Segunda Parte' } </Typography>
                <div className="spacer" />
                <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
            </Toolbar>
            <div style={{display:'table',margin:'0 auto'}}>
              {questions[currentQuestion]}
            </div>
            {
              currentQuestion === 30 &&
              <div style={{padding: 10, display: 'flex', flexDirection: 'column', margin: '0 auto'}}>
                <iframe src="https://worknesty.com/videos/6.html" style={{width: '100%', height: 390, maxWidth: 700, alignSelf: 'center'}} title="6" />
                <br />
                <br />
                <Typography style={{textAlign: 'center'}} variant="h6" >Has terminado tu evaluación, los resultados han sido enviados exitosamente, ya puedes cerrar esta ventana. Gracias.</Typography>
              </div>
            }
          </Paper>
          {
            user.honestyShield === true &&
              <div style={{visibility:'hidden'}}>
                  <Webcam 
                    audio={false}
                    screenshotFormat={'image/png'}
                    ref={this.setRef}
                    videoConstraints={videoConstraints}
                    height={1280}
                    width={1280}
                  />
              </div>
          }
        </>
      );
    }

    onChangeRecaptcha = (value) => {
      const {user} = this.state;
      if (user.active) {
        this.setState({isDisabledEPP: false});
      }
      if (user.activeEPF) {
        this.setState({isDisabledEPF: false});
      }

    }

    onChangeRecaptchaQuestions = (value) => {
      this.setState({isCaptchaOk: true})
    }
    
    render() {
        const {isLoading,user, isFirstScreen, company, isDisabledEPF, isDisabledEPP} = this.state;
        return isLoading?<CircularProgress style={{margin:'0 auto',display:'table', marginTop: 100}} color="secondary" />:(
          <div>
          {
            user === null ?
                <div className={"main"}>
                  <Paper className={"paper"}>
                    <img src={logo} alt="logo" className={"logo"} />
                    <Typography component="h1" variant="h5">
                        Iniciar Sesión
                    </Typography>
                    <div className={"form"}>
                        <TextField 
                          required 
                          value={this.state.mail} 
                          autoFocus 
                          onChange={e=>this.setState({mail:e.target.value})} 
                          fullWidth 
                          label="Correo electrónico" 
                          className={"input"} 
                        />
                        <TextField 
                          required 
                          value={this.state.password} 
                          onChange={e=>this.setState({password:e.target.value})} 
                          fullWidth 
                          type="password" 
                          label="Contraseña"  
                          className={"input"} 
                        />
                        {this.renderMessage()}
                        {this.renderButton()}
                      </div>
                  </Paper>
                </div>
              :
                <div>
                  <br />
                  {
                    isFirstScreen ? (
                      <Paper style={{width:'95%',margin:'0 auto'}}>
                        <Toolbar>
                            <Typography variant="h6" style={{width: 500}}>{company.name}</Typography>
                            <div className="spacer" />
                            <img src={this.state.company.logo} style={{width:70, paddingTop: 10}} alt="logo"/>
                        </Toolbar>
                        <div style ={{padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', flexDirection: 'column'}}>
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    this.setState({isFirstScreen: false, isEPP: true});
                                }}
                                disabled={isDisabledEPP}
                            >
                                Evaluación de Perfilamiento Psicológico
                            </Button>
                            <br />
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    this.setState({isFirstScreen: false, isEPF: true});
                                }}
                                disabled={isDisabledEPF}
                            >
                                Evaluación Psicológica Forense
                            </Button>
                            <br />
                            <br />
                            <ReCAPTCHA
                              sitekey={siteKey}
                              onChange={this.onChangeRecaptcha}
                            />
                        </div>
                    </Paper>
                    ) : this.renderer()
                  }
              </div>
          }
          </div>
        )
    }
}