export function isEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function isPhone(phone) {
    var re = /^\d*$/;
    return re.test(String(phone));
}
export function isNumber(number) {
    if (number) {
        if (number === '') {
            return true;
        } else {
            var re = /^[0-9.]+$/;
            let test = re.test(String(number));
            return test;
        }
    } else {
        return true;
    }
}
export const dateFormat = 'YYYY-MM-DD';
export function copyObject(obj) {
    return (JSON.parse(JSON.stringify(obj)))
}
export const idiomasList = [
    "Español (Ecuador)",
    "Español (México)",
    "Español (Colombia)",
    "Español (Perú)",
    "Español (Bolivia)",
    "Español (Chile)",
    "Ingles"
];
export const paisesList = [
    "Afghanistan",
    "Albania",
    "Germany",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Netherlands Antilles",
    "Saudi Arabia",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Belgium",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belize",
    "Benin",
    "Bhutan",
    "Belarus",
    "Myanmar",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cape Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Chad",
    "Chile",
    "China",
    "Cyprus",
    "Vatican City State",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo",
    "North Korea",
    "South Korea",
    "Ivory Coast",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Denmark",
    "Dominica",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "United Arab Emirates",
    "Eritrea",
    "Slovakia",
    "Slovenia",
    "Spain",
    "United States of America",
    "Estonia",
    "Ethiopia",
    "Philippines",
    "Finland",
    "Fiji",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Ghana",
    "Gibraltar",
    "Grenada",
    "Greece",
    "Greenland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "French Guiana",
    "Guernsey",
    "Guinea",
    "Equatorial Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Bouvet Island",
    "Isle of Man",
    "Christmas Island",
    "Norfolk Island",
    "Iceland",
    "Bermuda Islands",
    "Cayman Islands",
    "Cocos (Keeling) Islands",
    "Cook Islands",
    "√Öland Islands",
    "Faroe Islands",
    "South Georgia and the South Sandwich Islands",
    "Heard Island and McDonald Islands",
    "Maldives",
    "Falkland Islands (Malvinas)",
    "Northern Mariana Islands",
    "Marshall Islands",
    "Pitcairn Islands",
    "Solomon Islands",
    "Turks and Caicos Islands",
    "United States Minor Outlying Islands",
    "Virgin Islands",
    "United States Virgin Islands",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kyrgyzstan",
    "Kiribati",
    "Kuwait",
    "Lebanon",
    "Laos",
    "Lesotho",
    "Latvia",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Mexico",
    "Monaco",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malaysia",
    "Malawi",
    "Mali",
    "Malta",
    "Morocco",
    "Martinique",
    "Mauritius",
    "Mauritania",
    "Mayotte",
    "Estados Federados de",
    "Moldova",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Mozambique",
    "Namibia",
    "Nauru",
    "Nepal",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norway",
    "New Caledonia",
    "New Zealand",
    "Oman",
    "Netherlands",
    "Pakistan",
    "Palau",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "French Polynesia",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "United Kingdom",
    "Central African Republic",
    "Czech Republic",
    "Dominican Republic",
    "R√©union",
    "Rwanda",
    "Romania",
    "Russia",
    "Western Sahara",
    "Samoa",
    "American Samoa",
    "Saint Barth√©lemy",
    "Saint Kitts and Nevis",
    "San Marino",
    "Saint Martin (French part)",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Ascensi√≥n y Trist√°n de Acu√±a",
    "Saint Lucia",
    "Sao Tome and Principe",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Syria",
    "Somalia",
    "Sri Lanka",
    "South Africa",
    "Sudan",
    "Sweden",
    "Switzerland",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Tajikistan",
    "Thailand",
    "Taiwan",
    "Tanzania",
    "British Indian Ocean Territory",
    "French Southern Territories",
    "East Timor",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkmenistan",
    "Turkey",
    "Tuvalu",
    "Ukraine",
    "Uganda",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Wallis and Futuna",
    "Yemen",
    "Djibouti",
    "Zambia",
    "Zimbabwe",
]