import React,{Component} from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import classNames from 'classnames';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import PeopleIcon from '@material-ui/icons/People';
import LocationCity from '@material-ui/icons/LocationCity';
import SettingsIcon from '@material-ui/icons/Settings';
import PersonIcon from '@material-ui/icons/Person';
import {Link} from 'react-router-dom';
import { isAdmin } from '../../utils/controller';

export default class CustomDrawer extends Component{
    constructor(props){
        super(props);
        this.state={
            openCatalogues:false,
        }
    }
    render(){
        const { open } = this.props;
        return(
            <div>
                <Drawer
                    classes={{paper: classNames('drawerPaper')}}
                    open={open}
                    onClose={this.props.handleDrawerClose}
                >
                {
                    isAdmin()?
                    <List >
                        <Link to="/main" className={"link"}>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                        </Link>
                        <Link to="/empresas" className={"link"}>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Empresas" />
                            </ListItem>
                        </Link>
                        <Link to="/ciudades" className={"link"}>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <LocationCity />
                                </ListItemIcon>
                                <ListItemText primary="Ciudades" />
                            </ListItem>
                        </Link>
                        <Divider />
                    </List>
                    :
                    <List >
                        <Link to="/main" className={"link"}>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dashboard" />
                            </ListItem>
                        </Link>
                        <Link to="/cargos" className={"link"}>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <PeopleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Cargos" />
                            </ListItem>
                        </Link>
                        <Link to="/candidatos" className={"link"}>
                            <ListItem button onClick={this.props.handleDrawerClose}>
                                <ListItemIcon>
                                    <PersonIcon />
                                </ListItemIcon>
                                <ListItemText primary="Candidatos" />
                            </ListItem>
                        </Link>
                        <Divider />
                    </List>
                }
                </Drawer>
            </div>
        )
    }
}