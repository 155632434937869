import React,{Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import SaveIcon from '@material-ui/icons/Save';
import FileUploader from 'react-firebase-file-uploader';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import {storage} from '../../utils/controller';
export default class Uploader extends Component {
    constructor(props){
        super(props);
        this.state={
            file:null ,
            fotoUploaded:false,
            progress:0,
            isUploading:false,
        }
        this.handleUpload=this.handleUpload.bind(this);
    }
    handleUpload(e){
        let file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        this.props.upload(this.props.dirName,formData).then(value=>{
            this.props.urlUploaded(value.payload.response.Location);
        }).catch(error=>{
            console.log('error: ', error);
        });
    }
    uploaderIcon(){
        const {photo} = this.props;
        if(photo===true)
            return(<PhotoCameraIcon className="icon"/>)
        return(<SaveIcon className="icon"/>)
    }
    handleUploadStart = () => this.setState({isUploading: true, progress: 0});
    handleProgress = (progress) => this.setState({progress});
    handleUploadError = (error) => {
        this.setState({isUploading: false});
        console.error(error);
        alert(error);
    }
    handleUploadSuccess = (filename) => {
        const {dirName} = this.props;
        this.setState({fotoUploaded:true, progress: 100, isUploading: false});
        storage.ref(dirName).child(filename).getDownloadURL().then(url => this.props.urlUploaded(url));
    };
    render(){
        const {dirName} = this.props;
        return (
            <div>
                {
                    this.state.isUploading &&
                    <div>
                        <CircularProgress />
                        <p>Progreso: {this.state.progress}%</p>
                    </div>
                }
                <FileUploader
                    accept="file/*"
                    name="foto"
                    randomizeFilename
                    storageRef={storage.ref(dirName)}
                    onUploadStart={this.handleUploadStart}
                    onUploadError={this.handleUploadError}
                    onUploadSuccess={this.handleUploadSuccess}
                    onProgress={this.handleProgress}
                />
            </div>
        )
    }
}