import React from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}
export default class ConfirmationDialog extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
        };
    }
    handleOpen = () => {
        this.setState({open: true});
    };
    handleClose = () => {
        this.setState({ open: false });
    };
    handleSuccess = () => {
        this.props.confirmAction();
        this.setState({ open: false });
    };

  render() {
    const {buttonText,confirmationText,variant,isDisabled,icon, theme, className, color} = this.props;
    return (
      <div>
      {
        icon?
        <Tooltip title={buttonText}>
          <IconButton onClick={this.handleOpen} disabled={isDisabled} color={color || 'default'}>
            {icon}
          </IconButton>
        </Tooltip>
        :
        <Button
            type="button"
            variant={variant}
            className={className || "submit"}
            color={theme || 'primary'}
            disabled={isDisabled}
            onClick={this.handleOpen}
        >
            {buttonText}
        </Button>
      }
        
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
        >
          <DialogTitle>
            {"Confirmación"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {confirmationText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancelar
            </Button>
            <Button onClick={this.handleSuccess} color="secondary">
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}