import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import logo from '../assets/logo.png';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import {isEmail} from '../utils';
import {auth} from '../utils/controller';

export default class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            mail: '',
            password: '',
            isLoading:false,
        }
    }
    validate=()=>{
        if(this.state.mail!=='' && this.state.password!=='' && isEmail(this.state.mail)){
          return false;
        }
        return true;
    }
    onLoginClick=()=>{
        const {mail, password} = this.state;
        this.setState({isLoading:true})
        auth.signInWithEmailAndPassword(mail,password).then((data)=>{
            this.setState({isLoading:false});
            localStorage.setItem('mail',mail);
            localStorage.setItem('pass',password);
        })
        .catch((error) => {
            console.log('error: ', error);
            this.setState({isLoading:false,});
            alert('Datos Incorrectos');
        })
    }
    renderButton = () =>{
        const {isLoading} = this.state;
        if (!isLoading){
            return(
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={this.validate()}
                color="primary"
                onClick={this.onLoginClick}
                className={"submit"}
              >
                Iniciar Sesión
              </Button>
            )
        }
        return <CircularProgress className={"progress"}/>;
    }
    render(){
    return(
      <div className={"main"}>
            <Paper className={"paper"}>
                <img src={logo} alt="logo" className={"logo"} />
                <Typography component="h1" variant="h5">
                    Iniciar Sesión
                </Typography>
                <form className={"form"}>
                    <TextField 
                        required 
                        value={this.state.mail} 
                        autoFocus 
                        onChange={e=>this.setState({mail:e.target.value})} 
                        fullWidth 
                        label="Correo electrónico" 
                        className={"input"} 
                    />
                    <TextField 
                        required 
                        value={this.state.password} 
                        onChange={e=>this.setState({password:e.target.value})} 
                        fullWidth 
                        type="password" 
                        label="Contraseña"  
                        className={"input"} 
                    />
                    {this.renderButton()}
                </form>
            </Paper>
      </div>
    );
  }
}