import React, { Component } from 'react';
import Router from './utils/router';
import {theme} from './utils/theme';
import 'moment/locale/es'
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
moment.locale('es');
export default class App extends Component {
  render() {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={'es'} moment={moment}>
          <MuiThemeProvider theme={theme}>
            <Router />
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
  }
}