import firebase from "firebase/app";
import "firebase/database";
import "firebase/storage";
import "firebase/auth";

const configFirebase = {
  apiKey: "AIzaSyD00VvECIcxTji1JhjbuygKhdvelDB-N-A",
  authDomain: "sistema-ssat.firebaseapp.com",
  databaseURL: "https://sistema-ssat.firebaseio.com",
  projectId: "sistema-ssat",
  storageBucket: "sistema-ssat.appspot.com",
  messagingSenderId: "1061580808617",
};
export const firebaseApp = firebase.initializeApp(configFirebase);
export const db = firebaseApp.database();
export const storage = firebaseApp.storage();
export const storageTests = firebaseApp.storage().ref().child("Tests");
export const auth = firebaseApp.auth();
export const storageKey = "KEY_FOR_LOCAL_STORAGE";
export const isAuthenticated = () => {
  return !!auth.currentUser || !!localStorage.getItem(storageKey);
};
export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }
  return array;
};

export function isNumber(number) {
  if (number) {
    if (number === "") {
      return true;
    }

    const re = /^[0-9.]+$/;
    const test = re.test(String(number));
    return test;
  }

  return true;
}
export function logout() {
  localStorage.clear();
  localStorage.clear();
  return auth.signOut();
}
export const normalization = (sum, prom, desviation) => (sum - prom) / desviation; 

export const companies = db.ref("companies");
export const getCompanies = async () => {
  const snapshot = await companies.once('value');
  return snapshot.val();
}
export const preguntas = db.ref("preguntas");

export const preguntasEPF = db.ref("evaluacionpsicologicaforense");

export const getPreguntas = async () => {
  const snapshot = await preguntas.once('value');
  return snapshot.val();
}
export const newCandidate = db.ref("newCandidate");
export const newCandidateLink = db.ref("newCandidateLink");
export const newCompanyRef = db.ref("newCompany");
export const newUserRef = db.ref("newUser");
export const ciudadesRef = db.ref("ciudades");
export const getCiudades = async () => {
  const snapshot = await ciudadesRef.once('value');
  return snapshot.val();
}
export const users = db.ref("users");
export const getUsers = async () => {
  const snapshot = await users.once('value');
  return snapshot.val();
}
export const usersRef = db.ref("users");
export const valorEquivalente = db.ref("valorEquivalente");
export const getValorEquivalente = async () => {
  const snapshot = await valorEquivalente.once('value');
  return snapshot.val();
}
export const config = db.ref("config");
export const getConfig = async () => {
  const snapshot = await config.once('value');
  return snapshot.val();
}
export const privacypolicy = db.ref("privacypolicy");
export const privacypolicyEPF = db.ref("privacypolicyEPF");
export const isAdmin = () => {
  const uid = "lYfz9UWBnXdXkkvDDvvXfy9SFmz2";
  if (localStorage.uid === uid) {
    return true;
  }
  return false;
};

export const verdadMaxValue = 36;
export const rectitudMaxValue = 36;
export const pazMaxValue = 39;
export const amorMaxValue = 30;
export const noviolenciaMaxValue = 39;

/*
export const verdadMaxValue = 93;
export const rectitudMaxValue = 84;
export const pazMaxValue = 83;
export const amorMaxValue = 75;
export const noviolenciaMaxValue = 75;
*/
export const calculateReport = async(answers) => {
  let Paz = 0;
  let Verdad = 0;
  let Amor = 0;
  let Rectitud = 0;
  let Noviolencia = 0;
  let result = 0;
  await Object.values(answers).forEach(async (ans, index) => {
    switch (ans.valorDerecho) {
      case "Paz":
        Paz += ans.rightResult;
        break;
      case "Verdad":
        Verdad += ans.rightResult;
        break;
      case "Amor":
        Amor += ans.rightResult;
        break;
      case "Rectitud":
        Rectitud += ans.rightResult;
        break;
      case "No violencia":
        Noviolencia += ans.rightResult;
        break;
      default:
        break;
    }
    switch (ans.valorIzquierdo) {
      case "Paz":
        Paz += ans.leftResult;
        break;
      case "Verdad":
        Verdad += ans.leftResult;
        break;
      case "Amor":
        Amor += ans.leftResult;
        break;
      case "Rectitud":
        Rectitud += ans.leftResult;
        break;
      case "No violencia":
        Noviolencia += ans.leftResult;
        break;
      default:
        break;
    }
  });
  /*
  let pazNormalizado = 0;
  let verdadNormalizado = 0;
  let amorNormalizado = 0;
  let rectitudNormalizado = 0;
  let noviolenciaNormalizado = 0;
  */
  let pazTotal = 0;
  let verdadTotal = 0;
  let amorTotal = 0;
  let rectitudTotal = 0;
  let noviolenciaTotal = 0;
  let resultNoViolencia = 0;
  let resultRectitud = 0;
  let resultAmor = 0;
  let resultPaz = 0;
  let resultVerdad = 0;
  // const valorEquivalenteFinal = await getValorEquivalente();
  const configs = await getConfig();
  for (let i = 0; i < configs.length; i += 1) {
    const element = configs[i];
    switch (element[0]) {
      case "Paz":
        // let pazNormalizado = normalization(Paz, element[1].promedio, element[1].desviacion) * 10 + 50;
        pazTotal = Paz * 100 / pazMaxValue;
        /*if (paz < 40) {
          pazTotal = 0;
        } else if (paz >= 60) {
          pazTotal = 100;
        } else {
          pazTotal = (paz - 40) / valorEquivalenteFinal;
        }*/
        resultPaz = parseFloat(pazTotal).toFixed(0);
        break;
      case "Verdad":
        // let verdadNormalizado = normalization(Verdad, element[1].promedio,element[1].desviacion) * 10 + 50;
        verdadTotal = Verdad * 100 / verdadMaxValue;
        resultVerdad = parseFloat(verdadTotal).toFixed(0);
        break;
      case "Amor":
        // let amorNormalizado = normalization(Amor, element[1].promedio, element[1].desviacion) * 10 + 50;
        amorTotal = Amor * 100 / amorMaxValue;
        resultAmor = parseFloat(amorTotal).toFixed(0);
        break;
      case "Rectitud":
        // let rectitudNormalizado = normalization(Rectitud, element[1].promedio, element[1].desviacion) * 10 + 50;
        rectitudTotal = Rectitud * 100 / rectitudMaxValue;
        resultRectitud = parseFloat(rectitudTotal).toFixed(0);
        break;
      case "No violencia":
        // let noviolenciaNormalizado = normalization(Noviolencia,element[1].promedio,element[1].desviacion) * 10 + 50;
        noviolenciaTotal = Noviolencia * 100 / noviolenciaMaxValue;
        resultNoViolencia = parseFloat(noviolenciaTotal).toFixed(0);
        break;
      default:
        break;
    }
  }

  // result = (noviolenciaTotal+rectitudTotal+amorTotal+verdadTotal+pazTotal) / 5;
  result = (noviolenciaTotal + rectitudTotal + amorTotal + verdadTotal) / 4;
  let resultInt = parseFloat(result).toFixed(0);
  let resultColor = resultColorFunction(result);

  return {
    resultColor,
    resultInt,
    resultNoViolencia,
    resultRectitud,
    resultAmor,
    resultPaz,
    resultVerdad
  }
};

export const resultColorCustomFunction = (input, candidate) => {
  if (candidate) {
      const {cargo} = candidate;
      const valores = Object.values(cargo.valores).map(obj => obj.val);
      const valorMedio = valores.reduce( ( p, c ) => p + c, 0 ) / valores.length;
      const colorMedio = 48;
      const calificación = parseInt(input);
      const result = calificación * colorMedio / valorMedio;
      if(result >= 100){
          return '#63BE7B'
      } else if(result >= 80 && result<100){
          return '##B1D580'
      } else if(result >= 64 && result<80){
          return '#DDE182'
      } else if(result >= 48 && result<64){
          return '#FEE382'
      } else if(result >= 32 && result<48){
          return '#FCBF7B'
      } else if(result >= 16 && result<32){
          return '#FA9B74'
      } else if(result >= 0 && result < 16){
          return '#F8696B'
      } 
      else return '#000'
  }
  else return '#000'
};
export const resultColorFunction = (input, valor = '') => {
  const result = parseInt(input);
  switch (valor) {
    case 'Paz':
    case 'Noviolencia':
      if(result >= 100){
        return '#63BE7B'
      } else if(result >= 97 && result<100){
        return '#6CC17C'
      } else if(result >= 95 && result<97){
        return '#73C37C'
      } else if(result >= 92 && result<95){
        return '#7BC57D'
      } else if(result >= 90 && result<92){
        return '#84C87D'
      } else if(result >= 87 && result<90){
        return '#8BCA7E'
      } else if(result >= 85 && result<87){
        return '#93CC7E'
      } else if(result >= 82 && result<85){
        return '#9BCF7F'
      } else if(result >= 79 && result<82){
        return '#A3D17F'
      } else if(result >= 77 && result<79){
        return '#ACD380'
      } else if(result >= 74 && result < 77){
        return '#B3D680'
      } else if(result >= 72 && result < 74){
        return '#BBD881'
      } else if(result >= 69 && result < 72){
        return '#C4DA81'
      } else if(result >= 67 && result < 69){
        return '#CBDC81'
      } else if(result >= 64 && result < 67){
        return '#D4DF82'
      } else if(result >= 62 && result < 64){
        return '#DBE182'
      } else if(result >= 59 && result < 62){
        return '#E4E383'
      } else if(result >= 56 && result < 59){
        return '#EBE683'
      } else if(result >= 54 && result < 56){
        return '#F4E884'
      } else if(result >= 51 && result < 54){
        return '#FBEA84'
      } else if(result >= 49 && result < 51){
        return '#FEE783'
      } else if(result >= 46 && result < 49){
        return '#FEE082'
      } else if(result >= 44 && result < 46){
        return '#FEDA80'
      } else if(result >= 41 && result < 44){
        return '#FEE082'
      } else if(result >= 38 && result < 41){
        return '#FEDA80'
      } else if(result >= 36 && result < 38){
        return '#FDD37F'
      } else if(result >= 33 && result < 36){
        return '#FDCC7E'
      } else if(result >= 31 && result < 33){
        return '#FDC67C'
      } else if(result >= 28 && result < 31){
        return '#FCBF7B'
      } else if(result >= 26 && result < 28){
        return '#FCB97A'
      } else if(result >= 23 && result < 26){
        return '#FBA476'
      } else if(result >= 21 && result < 23){
        return '#FA9E75'
      } else if(result >= 18 && result < 21){
        return '#FA9773'
      } else if(result >= 15 && result < 18){
        return '#FA9172'
      } else if(result >= 13 && result < 15){
        return '#F98A71'
      } else if(result >= 10 && result < 13){
        return '#F98370'
      } else if(result >= 8 && result < 10){
        return '#F97D6E'
      } else if(result >= 5 && result < 8){
        return '#F8766D'
      } else if(result >= 3 && result < 5){
        return '#F86F6C'
      } else if(result >= 0 && result < 3){
        return '#F8696B'
      } 
      else return '#000';
    case 'Rectitud':
    case 'Verdad':
      if(result >= 100){
        return '#63BE7B'
      } else if(result >= 97 && result < 100){
        return '#6CC17C'
      } else if(result >= 95 && result < 97){
        return '#75C37C'
      } else if(result >= 92 && result < 95){
        return '#7DC67D'
      } else if(result >= 89 && result < 92){
        return '#86C97E'
      } else if(result >= 86 && result < 89){
        return '#8FCB7E'
      } else if(result >= 83 && result < 86){
        return '#98CE7F'
      } else if(result >= 81 && result < 83){
        return '#A0D07F'
      } else if(result >= 78 && result < 81){
        return '#A9D380'
      } else if(result >= 75 && result < 78){
        return '#B1D580'
      } else if(result >= 72 && result < 75){
        return '#BAD780'
      } else if(result >= 69 && result < 72){
        return '#C3DA81'
      } else if(result >= 67 && result < 69){
        return '#CBDC81'
      } else if(result >= 64 && result < 67){
        return '#D4DF82'
      } else if(result >= 61 && result < 64){
        return '#DDE182'
      } else if(result >= 58 && result < 61){
        return '#E5E483'
      } else if(result >= 56 && result < 58){
        return '#EEE683'
      } else if(result >= 53 && result < 56){
        return '#F7E984'
      } else if(result >= 50 && result < 53){
        return '#FFEB84'
      } else if(result >= 47 && result < 50){
        return '#FEE382'
      } else if(result >= 44 && result < 47){
        return '#FEDC81'
      } else if(result >= 42 && result < 44){
        return '#FDD57F'
      } else if(result >= 39 && result < 42){
        return '#FDCE7E'
      } else if(result >= 36 && result < 39){
        return '#FDC67D'
      } else if(result >= 33 && result < 36){
        return '#FCBF7B'
      } else if(result >= 31 && result < 33){
        return '#FCB87A'
      } else if(result >= 28 && result < 31){
        return '#FBB178'
      } else if(result >= 25 && result < 28){
        return '#FBAA77'
      } else if(result >= 22 && result < 25){
        return '#FBA276'
      } else if(result >= 19 && result < 22){
        return '#FA9B74'
      } else if(result >= 17 && result < 19){
        return '#FA9473'
      } else if(result >= 14 && result < 17){
        return '#F98D71'
      } else if(result >= 11 && result < 14){
        return '#F98570'
      } else if(result >= 8 && result < 11){
        return '#F97E6F'
      } else if(result >= 6 && result < 8){
        return '#F8776D'
      } else if(result >= 3 && result < 6){
        return '#F8706C'
      } else if(result >= 0 && result < 3){
        return '#F8696B'
      } 
      else return '#000';
    case 'Amor':
      if(result >= 100){
        return '#63BE7B'
      } else if(result >= 97 && result<100){
        return '#6EC17C'
      } else if(result >= 93 && result<97){
        return '#78C47D'
      } else if(result >= 90 && result<93){
        return '#83C77D'
      } else if(result >= 87 && result<90){
        return '#8DCA7E'
      } else if(result >= 83 && result<87){
        return '#98CE7F'
      } else if(result >= 80 && result<83){
        return '#A2D07F'
      } else if(result >= 77 && result<80){
        return '#ACD380'
      } else if(result >= 73 && result < 77){
        return '#B7D780'
      } else if(result >= 70 && result < 73){
        return '#C1DA81'
      } else if(result >= 67 && result < 70){
        return '#CBDC81'
      } else if(result >= 63 && result < 67){
        return '#D6E082'
      } else if(result >= 60 && result < 63){
        return '#E0E383'
      } else if(result >= 57 && result < 60){
        return '#EBE683'
      } else if(result >= 53 && result < 57){
        return '#F5E984'
      } else if(result >= 50 && result < 53){
        return '#FFEB84'
      } else if(result >= 47 && result < 50){
        return '#FEE282'
      } else if(result >= 43 && result < 47){
        return '#FED980'
      } else if(result >= 40 && result < 43){
        return '#FDD17F'
      } else if(result >= 37 && result < 40){
        return '#FDC87D'
      } else if(result >= 33 && result < 37){
        return '#FCBF7B'
      } else if(result >= 30 && result < 33){
        return '#FCB77A'
      } else if(result >= 27 && result < 30){
        return '#FBAE78'
      } else if(result >= 23 && result < 27){
        return '#FBA576'
      } else if(result >= 20 && result < 23){
        return '#FA9D75'
      } else if(result >= 17 && result < 20){
        return '#FA9473'
      } else if(result >= 13 && result < 17){
        return '#F98B71'
      } else if(result >= 10 && result < 13){
        return '#F98370'
      } else if(result >= 7 && result < 10){
        return '#F87A6E'
      } else if(result >= 3 && result < 7){
        return '#F8716C'
      } else if(result >= 0 && result < 3){
        return '#F8696B'
      } 
      else return '#000';
    default: 
    if(result >= 100){
      return '#63BE7B'
    } else if(result >= 97 && result<100){
      return '#6CC17C'
    } else if(result >= 95 && result<97){
      return '#73C37C'
    } else if(result >= 92 && result<95){
      return '#7BC57D'
    } else if(result >= 90 && result<92){
      return '#84C87D'
    } else if(result >= 87 && result<90){
      return '#8BCA7E'
    } else if(result >= 85 && result<87){
      return '#93CC7E'
    } else if(result >= 82 && result<85){
      return '#9BCF7F'
    } else if(result >= 79 && result<82){
      return '#A3D17F'
    } else if(result >= 77 && result<79){
      return '#ACD380'
    } else if(result >= 74 && result < 77){
      return '#B3D680'
    } else if(result >= 72 && result < 74){
      return '#BBD881'
    } else if(result >= 69 && result < 72){
      return '#C4DA81'
    } else if(result >= 67 && result < 69){
      return '#CBDC81'
    } else if(result >= 64 && result < 67){
      return '#D4DF82'
    } else if(result >= 62 && result < 64){
      return '#DBE182'
    } else if(result >= 59 && result < 62){
      return '#E4E383'
    } else if(result >= 56 && result < 59){
      return '#EBE683'
    } else if(result >= 54 && result < 56){
      return '#F4E884'
    } else if(result >= 51 && result < 54){
      return '#FBEA84'
    } else if(result >= 49 && result < 51){
      return '#FEE783'
    } else if(result >= 46 && result < 49){
      return '#FEE082'
    } else if(result >= 44 && result < 46){
      return '#FEDA80'
    } else if(result >= 41 && result < 44){
      return '#FDD37F'
    } else if(result >= 38 && result < 41){
      return '#FDCC7E'
    } else if(result >= 36 && result < 38){
      return '#FDD37F'
    } else if(result >= 33 && result < 36){
      return '#FCBF7B'
    } else if(result >= 31 && result < 33){
      return '#FDC67C'
    } else if(result >= 28 && result < 31){
      return '#FCB97A'
    } else if(result >= 26 && result < 28){
      return '#FBAB77'
    } else if(result >= 23 && result < 26){
      return '#FBA476'
    } else if(result >= 21 && result < 23){
      return '#FA9E75'
    } else if(result >= 18 && result < 21){
      return '#FA9773'
    } else if(result >= 15 && result < 18){
      return '#FA9172'
    } else if(result >= 13 && result < 15){
      return '#F98A71'
    } else if(result >= 10 && result < 13){
      return '#F98370'
    } else if(result >= 8 && result < 10){
      return '#F97D6E'
    } else if(result >= 5 && result < 8){
      return '#F8766D'
    } else if(result >= 3 && result < 5){
      return '#F86F6C'
    } else if(result >= 0 && result < 3){
      return '#F8696B'
    } 
    else return '#000';
  }
  
};


export const arrayOfColorValuesReverse = [
  100, 97, 95, 92, 90, 87, 85, 82, 79, 77, 74, 72, 69, 67, 64, 62, 59, 56, 54, 51, 49, 46, 44, 41, 38, 36, 33, 31, 28, 26, 23, 21, 18, 15, 13, 10, 8, 5, 3 ,0
];
export const arrayOfColorValues = [
  0, 3, 5, 8, 10, 13, 15, 18, 21, 23, 26, 28,31, 33, 36, 38, 41, 44, 46, 49, 51, 54, 56, 59, 62, 64, 67, 69, 72, 74, 77, 79, 82, 85, 87, 90, 92, 95, 97, 100
];
export const arrayFromZeroToHundred = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
  30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
  50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
  70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
  90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  100
];
export const arrayFromZeroToHundredCustom = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
  10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29,
  30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
  40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
  50, 51, 52, 53, 54, 55, 56, 57, 58, 59,
  60, 61, 62, 63, 64, 65, 66, 67, 68, 69,
  70, 71, 72, 73, 74, 75, 76, 77, 78, 79,
  80, 81, 82, 83, 84, 85, 86, 87, 88, 89,
  90, 91, 92, 93, 94, 95, 96, 97, 98, 99,
  100
];

export const arrayOfValors = ['Verdad', 'Rectitud', 'Paz', 'Amor', 'No violencia'];