import React, { Component } from 'react';
import './styles.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import PageTitle from '../components/common/pageTitle';
import Login from '../components/login';
import Main from '../components/main';
import Prueba from '../components/prueba';
import Profiles from '../components/profiles';
import Candidates from '../components/candidates';
import Report from '../components/report';
import Citys from '../components/citys';
import CustomDrawer from '../components/common/customDrawer';
import {auth} from './controller';
import { Route, BrowserRouter, Redirect, Switch } from 'react-router-dom';
import Companies from '../components/companies';

function StaticRoute ({component: Component, isAuthed,open,title,handleDrawerOpen,handleDrawerClose, ...rest}){
    return (
        <Route
            {...rest}
            render={
                (props) => isAuthed === true ?
                <div>
                    <PageTitle open={open} title={title} handleDrawerOpen={handleDrawerOpen}/>
                    <CustomDrawer handleDrawerClose={handleDrawerClose} open={open}/>
                    <div className={'content'}>
                        <Component {...props} />
                    </div>
                </div>
                :
                    <Redirect to={{pathname: '/login', state: {from: props.location}}} />
            }
        />
    )
}
function PrivateRoute ({component: Component, isAuthed,open,title,handleDrawerOpen,handleDrawerClose, ...rest}){
    return (
        <Route
            {...rest}
            render={
                (props) => isAuthed === true ? 
                <div>
                    <PageTitle open={open} title={title} handleDrawerOpen={handleDrawerOpen}/>
                    <CustomDrawer handleDrawerClose={handleDrawerClose} open={open}/>
                    <div className={'content'}>
                        <Component {...props} />
                    </div>
                </div>
                : 
                    <Redirect to={{pathname: '/login', state: {from: props.location}}} />
            }
        />
    )
}
function PublicRoute ({component: Component, isAuthed, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => 
          isAuthed === false? 
            <Component {...props} />
          :  
            <Redirect to='/empresas' />
        }
      />
    )
}
function ExamRoute ({component: Component, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => 
            <Component {...props} />
        }
      />
    )
}

export default class Routes extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoading:true,
            isAuthed:false,
            open:false,
        }
    }
    componentDidMount () {
        this.removeListener = auth.onAuthStateChanged((user) => {
            if (user) {
                localStorage.uid = user.uid;
                this.setState({
                    isAuthed: true,
                    isLoading: false,
                })
            } else {
                this.setState({
                    isAuthed: false,
                    isLoading: false,
                })
            }
        })
    }
    componentWillUnmount () {
        this.removeListener()
    }
    render() {
        const {isLoading,isAuthed,open} = this.state;
        return isLoading === true ? <CircularProgress  style={{display:'table', margin:'0 auto',marginTop:200}}/> : (
            <BrowserRouter>
                <Switch>
                    <PrivateRoute 
                        path='/' 
                        isAuthed={isAuthed}  
                        title="Dashboard"
                        exact 
                        component={Main} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <PublicRoute 
                        isAuthed={isAuthed} 
                        path='/login' 
                        component={Login} 
                    />
                    <ExamRoute 
                        path='/prueba/:id' 
                        exact
                        component={Prueba} 
                    />
                    <ExamRoute 
                        path='/prueba/:id/:email/:password' 
                        exact
                        component={Prueba} 
                    />
                    <PrivateRoute 
                        isAuthed={isAuthed} 
                        path='/main' 
                        title="Dashboard"
                        component={Main} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <StaticRoute 
                        isAuthed={isAuthed} 
                        path='/empresas' 
                        title="Empresas"
                        component={Companies} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <PrivateRoute 
                        isAuthed={isAuthed} 
                        path='/cargos' 
                        exact
                        title="Cargos"
                        component={Profiles} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <PrivateRoute 
                        isAuthed={isAuthed} 
                        path='/cargos/:uid' 
                        title="Cargos"
                        component={Profiles} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <PrivateRoute 
                        isAuthed={isAuthed} 
                        path='/candidatos' 
                        exact
                        title="Candidatos"
                        component={Candidates} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <PrivateRoute 
                        isAuthed={isAuthed} 
                        path='/candidatos/:uid' 
                        title="Candidatos"
                        component={Candidates} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <PrivateRoute 
                        isAuthed={isAuthed} 
                        path='/ciudades' 
                        title="Ciudades"
                        component={Citys} 
                        open={open} 
                        handleDrawerClose={()=>this.setState({open:false})} 
                        handleDrawerOpen={()=>this.setState({open:true})}
                    />
                    <ExamRoute 
                        path='/reporte/:companyId/:candidateId' 
                        component={Report} 
                    />
                    <Route render={() => <h6>Error 404 No Encontrado</h6>} />
                </Switch>
            </BrowserRouter>
        );
  }
}