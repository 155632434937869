import React, {Component} from 'react';
import { Toolbar, Paper, Typography, CircularProgress, TextField, Table,TableBody, TableRow, TableCell, TableHead, Button, } from '@material-ui/core';
import {isAdmin, users,companies, config, valorEquivalente, isNumber} from '../utils/controller';

export default class Main extends Component {
    constructor(props){
        super(props);
        this.state={
            companyName:'',
            company:null,
            isLoading:true,
            time:0,
            valorEquivalente:0,
            config:[],
            noPermission: false
        }
    }
    componentWillMount() {
        if(isAdmin()){
            config.on('value', async snapshot=>{
                let config = Object.values(snapshot.toJSON());
                this.setState({config})
            })
            valorEquivalente.on('value', async snapshot => {
                let valorEquivalente =(snapshot.toJSON());
                this.setState({valorEquivalente})
            })
            this.setState({companyName:"Administrador",isLoading:false})
        }
        else{

            let uid = localStorage.uid;
            users.on('value',async snapshot => {
                let company = Object.values(snapshot.toJSON().companies).filter(obj=>obj.uid===uid)[0];
                if(company) {
                    console.log('company: ', company);
                    if(company.profiles.admin === true) {
                        let companyName = company.companyName;
                        companies.child(company.companyId).on('value',async snapshotCompanies=>{
                            let currentCompany = snapshotCompanies.toJSON()
                            if(currentCompany){
                                this.setState({companyName, company:currentCompany, time: currentCompany.time?currentCompany.time:20, isLoading:false});
                            }
                        })
                    }
                    else {
                        this.setState({noPermission: true})
                    }
                }
            })
        }
    }

    changeTime = (e) => {
        const {company} = this.state;
        const time = e.target.value;
        console.log('time: ', time);
        if (time) {
            if (isNumber(time)) {
                companies.child(company.id+'/time').set(time).then(val=>{
                    this.setState({time});
                });
            }
        }
    }
    changeValue = (e,key,isDesviacion) => {
        console.log('key: ', key);
        let value = e.target.value;
        const {config} = this.state;
        console.log('config: ', config);
        if(isDesviacion){
            config[key][1].desviacion = value;
        }
        else {
            config[key][1].promedio = value;
        }
        this.setState({config});
    }
    updateValues = () => {
        config.set(this.state.config);
    }
    renderValores=()=>{
        const {config} = this.state;
        if(config.length>0){
            return config.map((element,index)=>{
                return(
                    <TableRow key={index}>
                        <TableCell className={"tableRowTitle"}><b>{element[0]}</b></TableCell>
                        <TableCell>
                            <TextField 
                                value={element[1].desviacion}
                                fullWidth
                                onChange = {e=>this.changeValue(e,index,true)}
                            />
                        </TableCell>
                        <TableCell>
                            <TextField 
                                value={element[1].promedio}
                                fullWidth
                                onChange = {e=>this.changeValue(e,index,false)}
                            />
                        </TableCell>
                    </TableRow>
                )
            })
        }
    }
    render(){
        const {isLoading, companyName,company, noPermission} = this.state;
        if(noPermission) {
            return <Typography variant="subtitle1">Usted no tiene permiso para acceder a este módulo</Typography>
        }
        return isLoading?<CircularProgress style={{margin:'0 auto',display:'table', marginTop: 100}}/>:(
            <div>
                <br />
                <Paper style={{width:'95%',margin:'0 auto'}}>
                    <Toolbar>
                        <Typography variant="h6">{"Bienvenido "+ companyName}</Typography>
                    </Toolbar>
                    {
                        isAdmin()?
                        <div>
                            <Table>
                                <TableBody>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Valor Equivalente</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.valorEquivalente}
                                            onChange={e=>this.setState({valorEquivalente:e.target.value})}
                                        />
                                    </TableCell>
                                </TableRow>
                                </TableBody>
                            </Table>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Valor</TableCell>
                                        <TableCell>Desviación Estandar</TableCell>
                                        <TableCell>Promedio</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.renderValores()}
                                </TableBody>
                            </Table>
                            <div className="submitContainer">
                                <br />
                                <Button
                                    onClick={this.updateValues}
                                    variant="contained"
                                >
                                    Actualizar valores
                                </Button>
                                <br />
                                <br />
                            </div>
                        </div>
                        :
                        <div>
                        {
                            company!==null&&
                            <div>
                                <Toolbar>
                                {
                                    company.examsLeft === 0 ? 
                                    <Typography variant="subtitle1" style={{color: 'red'}}>Usted ya no dispone de pruebas disponibles</Typography>
                                    :
                                    <Typography variant="subtitle1">{"Usted dispone de "+company.examsLeft+" pruebas de " + company.exams + " disponibles"}</Typography>
                                }
                                </Toolbar>
                                <Toolbar>
                                    <Typography variant="subtitle1">Tiempo por pregunta: &nbsp;</Typography>
                                    <TextField 
                                        value={this.state.time}
                                        style={{width: 50}}
                                        onChange={e => this.changeTime(e)}
                                    />
                                    <Typography variant="subtitle1"> &nbsp; segundos</Typography>
                                </Toolbar>
                            </div>
                        }
                        </div>
                    }
                </Paper>
            </div>
        )
    }
}