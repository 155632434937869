import React, {Component} from 'react';
import { Toolbar, Paper, Tooltip, IconButton, Typography, TableBody,Table,TableRow,TableCell,TableHead, TextField, CircularProgress, Tabs, Tab } from '@material-ui/core';
import { users,companies} from '../utils/controller';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import Slider from 'rc-slider';
import Snackbar from '@material-ui/core/Snackbar';
import ConfirmationDialog from './common/confirmationDialog';
import 'rc-slider/assets/index.css';
const Handle = Slider.Handle;

const valoresIniciales = {
    Verdad:{val:50}, 
    "No violencia":{val:50}, 
    Amor:{val:50}, 
    Paz:{val:50},
    Rectitud:{val: 50}
}
const plantillasIniciales = {
    "Acoso Sexual" : {val : 50},
    "Beneficios Ilícitos" : {val : 50},
    "Consumo de Bebidas Alcohólicas" : {val : 50},
    "Consumo de Drogas Ilegales" : {val : 50},
    "Delitos Graves" : {val : 50},
    "Robos" : {val : 50},
    "Trayectoria Laboral" : {val : 50},
    "Vínculos con Delincuentes" : {val : 50},
    "Ética y Valores" : {val : 50}
}
const handle = (props) => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <Tooltip
        prefixCls="rc-slider-tooltip"
        overlay={value}
        visible={dragging}
        placement="top"
        key={index}
        title={value}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  };

export default class Profiles extends Component {
    constructor(props){
        super(props);
        this.state={
            company:null,
            companyName:'',
            noPermission: false,
            isNew:false,
            profile:null,
            isLoading:true,
            id:'',
            name:'',
            description:'',
            valores: valoresIniciales,
            plantillas: plantillasIniciales,
            openSnackbar:false,
            message:'',
            tabIndex: 0
        }
    }
    componentWillMount() {
       this.handleMainFunction();
    }

    handleMainFunction = () => {
        this.setState({isLoading: true});
        let uid = localStorage.uid;
        if(this.props.match.params) {
            if(this.props.match.params.uid){
                uid = this.props.match.params.uid;            
            }
        }
        users.on('value',async snapshot => {
            let company = Object.values(snapshot.toJSON().companies).filter(obj=>obj.uid===uid)[0];
            if(company) {
                if (company.profiles) {
                    if(company.profiles.admin === true || company.profiles.cargos === true) {
                        let companyName = company.companyName;
                        companies.on('value',async snapshotCompanies=>{
                            let allCompanies = Object.values(snapshotCompanies.toJSON());
                            let currentCompany = allCompanies.filter(obj=>company.companyId===obj.id)[0];
                            this.setState({companyName, company:currentCompany,isLoading:false});
                        })
                    }
                    else {
                        this.setState({noPermission: true})
                    }
                }
                
            }
        })
    }

    handleCloseSnackbar = () => {this.setState({openSnackbar:false, message:''})}
    onAddButtonClick = () => {
        this.setState({isLoading:true})
        const {company,name,description,valores, plantillas} = this.state;
        let id = companies.child("profiles").push().key;
        let newProfile = {
            name,
            description,
            valores,
            plantillas,
            id
        };
        companies.child(company.id).child("profiles").child(id).set(newProfile);
        this.setState({isLoading:false, message:"Se ha agregado satisfactoriamente",openSnackbar:true})
        this.handleGoBack();
    }
    onEditButtonClick = () => {
        this.setState({isLoading:true})
        const {company,name,description,valores, plantillas,id} = this.state;
        let currentProfile = {
            name,
            description,
            valores,
            plantillas,
            id
        };
        companies.child(company.id).child("profiles").child(id).set(currentProfile);
        this.setState({isLoading:false,message:"Se ha modificado satisfactoriamente",openSnackbar:true})
        this.handleGoBack();
    }
    isInvalid(){
        const {name,description} = this.state;
        if(name!==''&&description!==''){
            return false;
        }
        return true;
    }
    handleDelete = () => {
        const {isNew,profile,company} = this.state;
        if(!isNew &&profile){
            this.setState({isLoading:true})
            let id = profile.id;
            companies.child(company.id).child("profiles").child(id).remove().then(value=>{
                this.setState({isLoading:false, message:"Se ha eliminado satisfactoriamente",openSnackbar:true})
                this.handleGoBack();
            }).catch(error=>{
                console.log('error: ', error);
            });            
        }
    }
    cellColor = (value) => {
        const result = parseInt(value);
        if(result >= 0 && result < 16){
            return (
                <TableCell className='muyBajo'>
                    <b style={{color: '#F72619'}}>{value}</b>
                </TableCell>
            )
        } else if(result >= 16 && result<32){
            return (
                <TableCell className='bajo'>
                    <b style={{color: '#F8433A'}}>{value}</b>
                </TableCell>
            )
        } else if(result >= 32 && result<48){
            return (
                <TableCell className='medioBajo'>
                    <b style={{color: '#F8751C'}}>{value}</b>
                </TableCell>
            )
        } else if(result >= 48 && result<64){
            return (
                <TableCell className='promedio'>
                    <b style={{color: '#F5FA18'}}>{value}</b>
                </TableCell>
            )
        } else if(result >= 64 && result<80){
            return (
                <TableCell className='medioAlto'>
                    <b style={{color: '#B4DD17'}}>{value}</b>
                </TableCell>
            )
        } else if(result >= 80 && result<100){
            return (
                <TableCell className='alto'>
                    <b style={{color: '#3AE646'}}>{value}</b>
                </TableCell>
            )
        }
        else if (result === 100) {
            return (
                <TableCell className='muyAlto'>
                    <b style={{color: '#1BD22A'}}>{value}</b>
                </TableCell>
            )
        }
        else return (
            <TableCell className='defaultScore'>
                {value}
            </TableCell>
        )
        
    }
    renderButton = () => {
        const {isLoading,isNew} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea "+(isNew?"crear":'modificar')+" este cargo?"}
                    buttonText={(isNew?"Agregar":"Actualizar") + " cargo"}
                    variant="contained"
                    isDisabled={this.isInvalid()}
                    confirmAction={isNew?this.onAddButtonClick:this.onEditButtonClick}
                    className="submitGreen"
                />
            );
        }
        return <CircularProgress />;
    }
    handleGoBack = () => {
        this.setState({
            isNew:false,
            profile:null,
            id:'',
            name:'',
            description:'',
            valores:valoresIniciales,
            plantillas: plantillasIniciales
        })
        this.handleMainFunction();
    }
    renderTitle = () => {
        const {profile, isNew} = this.state;
        if(profile===null){
            return "Administrar cargos"
        }
        else if(isNew === true){
            return "Agregar cargo"
        }
        else{
            return "Modificar cargo"
        }
    }
    handleChangeValues = (kind,value) => {
        /*if(this.state.openSnackbar === false) {
            if(value >60){
                let message = 'El puntaje máximo que obtiene una persona promedio no supera el valor de 60, se recomienda escoger un valor igual o menor a 60';
                this.setState({openSnackbar: true, message})
            }
            if(value < 40){
                let message = 'El puntaje mínimo que obtiene una persona promedio supera supera el valor de 40, se recomienda escoger un valor igual o mayor a 40';
                this.setState({openSnackbar: true, message})
            }
        }*/
        let valores = this.state.valores;
        valores[kind].val=parseInt(value);
        this.setState({valores});
    }
    handleChangePlantillas = (kind,value) => {
        let plantillas = this.state.plantillas;
        plantillas[kind].val=parseInt(value);
        this.setState({plantillas});
    }
    renderProfile = () => {
        const {isNew} = this.state;
        return(
            <div>
                <Toolbar>
                    <Tooltip title="Atras">
                        <IconButton onClick={this.handleGoBack}>
                            <ArrowBackIos />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h6" style={{width:400}}>{this.renderTitle()}</Typography>
                    {
                        !isNew &&
                            <div className="spacer" />
                    }
                    {
                        !isNew&&
                            <ConfirmationDialog
                                confirmationText={"¿Está seguro que desea eliminar este cargo?"}
                                buttonText={"Eliminar Candidato"}
                                variant="contained"
                                icon = {<Delete />}
                                isDisabled={false}
                                confirmAction={this.handleDelete}
                                color="secondary"
                            />
                    }
                </Toolbar>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Nombre</b></TableCell>
                            <TableCell colSpan={3}>
                                <TextField 
                                    fullWidth
                                    multiline
                                    autoFocus
                                    value={this.state.name}
                                    onChange={e=>this.setState({name:e.target.value})}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Descripción</b></TableCell>
                            <TableCell colSpan={3}>
                                <TextField 
                                    fullWidth
                                    multiline
                                    value={this.state.description}
                                    onChange={e=>this.setState({description:e.target.value})}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Tabs value={this.state.tabIndex} onChange={(e, tabIndex) => this.setState({tabIndex})}>
                    <Tab label="Valores" />
                    <Tab label="Plantillas" />
                </Tabs>
                {this.state.tabIndex === 0 && (
                    <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Intelectual</b></TableCell>
                            {this.cellColor(this.state.valores.Verdad.val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.valores.Verdad.val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangeValues('Verdad',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Física</b></TableCell>
                            {this.cellColor(this.state.valores.Rectitud.val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.valores.Rectitud.val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangeValues('Rectitud',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Emocional</b></TableCell>
                            {this.cellColor(this.state.valores.Paz.val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.valores.Paz.val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangeValues('Paz',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Afectiva</b></TableCell>
                            {this.cellColor(this.state.valores.Amor.val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.valores.Amor.val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangeValues('Amor',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Espiritual</b></TableCell>
                            {this.cellColor(this.state.valores["No violencia"].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.valores["No violencia"].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangeValues('No violencia',values)}
                                />
                            </TableCell>
                        </TableRow>
                    
                    </TableBody>
                </Table>
                )}
                {this.state.tabIndex === 1 && (
                    <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Consumo de Bebidas Alcohólicas</b></TableCell>
                            {this.cellColor(this.state.plantillas['Consumo de Bebidas Alcohólicas'].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas['Consumo de Bebidas Alcohólicas'].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Consumo de Bebidas Alcohólicas',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Consumo de Drogas Ilegales</b></TableCell>
                            {this.cellColor(this.state.plantillas['Consumo de Drogas Ilegales'].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas['Consumo de Drogas Ilegales'].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Consumo de Drogas Ilegales',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Beneficios Ilícitos</b></TableCell>
                            {this.cellColor(this.state.plantillas['Beneficios Ilícitos'].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas['Beneficios Ilícitos'].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Beneficios Ilícitos',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Vínculos con Delincuentes</b></TableCell>
                            {this.cellColor(this.state.plantillas['Vínculos con Delincuentes'].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas['Vínculos con Delincuentes'].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Vínculos con Delincuentes',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Delitos Graves</b></TableCell>
                            {this.cellColor(this.state.plantillas["Delitos Graves"].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas["Delitos Graves"].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Delitos Graves',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Trayectoria Laboral</b></TableCell>
                            {this.cellColor(this.state.plantillas["Trayectoria Laboral"].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas["Trayectoria Laboral"].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Trayectoria Laboral',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Robos</b></TableCell>
                            {this.cellColor(this.state.plantillas["Robos"].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas["Robos"].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Robos',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Acoso Sexual</b></TableCell>
                            {this.cellColor(this.state.plantillas["Acoso Sexual"].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas["Acoso Sexual"].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Acoso Sexual',values)}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Ética y Valores</b></TableCell>
                            {this.cellColor(this.state.plantillas["Ética y Valores"].val)}
                            <TableCell className={"tableRowSlider"}>
                                <Slider 
                                    min={0}
                                    max={100}
                                    step={1}
                                    handle={handle}
                                    marks = {{
                                        0 : { 
                                            style: {color: '#F72619', fontSize: 14}, 
                                            label: 'Muy Bajo' 
                                        },
                                        16 : { 
                                            style: {color: '#F8433A', fontSize: 14}, 
                                            label: 'Bajo' 
                                        },
                                        32 : { 
                                            style: {color: '#F8751C', fontSize: 14}, 
                                            label: 'Medio-Bajo' 
                                        },
                                        48 : { 
                                            style: {color: '#F5FA18', fontSize: 14}, 
                                            label: 'Promedio' 
                                        },
                                        64 : { 
                                            style: {color: '#B4DD17', fontSize: 14}, 
                                            label: 'Medio-Alto' 
                                        },
                                        80 : { 
                                            style: {color: '#3AE646', fontSize: 14}, 
                                            label: 'Alto' 
                                        },
                                        100 : { 
                                            style: {color: '#1BD22A', fontSize: 14}, 
                                            label: 'Muy Alto' 
                                        },
                                    }}
                                    defaultValue={this.state.plantillas["Ética y Valores"].val} 
                                    trackStyle={[{backgroundColor: '#9e9e9e'}]}
                                    handleStyle={{borderColor: '#9e9e9e'}}
                                    activeDotStyle={{borderColor: '#9e9e9e'}}
                                    onChange={values=>this.handleChangePlantillas('Ética y Valores',values)}
                                />
                            </TableCell>
                        </TableRow>
                    
                    </TableBody>
                </Table>
                )}
                
                <div className={"submitContainer"}>
                {this.renderButton()}
                </div>
            </div>
        )
    }
    renderExtras = () => {
        const {profile} = this.state;
        if(profile===null){
            return '';
        }
        else{
            return this.renderProfile()
        }
    }
    onEditClick = (element) =>{
        this.setState({
            profile:element,
            isNew:false,
            name:element.name?element.name:'',
            description:element.description?element.description:'',
            valores:element.valores?element.valores:valoresIniciales,
            plantillas:element.plantillas?element.plantillas:plantillasIniciales,
            id:element.id?element.id:'',
        })
    }

    renderLoader = () => {
        const {isLoading} = this.state;
        if (isLoading) {
            return (
                <div id="overlay">
                    <CircularProgress
                        color="secondary"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                </div>
            )
        }
        return null;
    }

    render(){
        const {company,profile,openSnackbar ,message, noPermission } = this.state;
        if(noPermission) {
            return <Typography variant="subtitle1">Usted no tiene permiso para acceder a este módulo</Typography>
        }
        const cargosRows = company!==null?company.profiles?Object.values(company.profiles).map((element,index)=>{
            return(
                <TableRow key={index}>
                    <TableCell>{element.name}</TableCell>
                    <TableCell>{element.description}</TableCell>
                    <TableCell>
                        <Tooltip title="Editar">
                            <IconButton onClick={e=>this.onEditClick(element)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                    </TableCell>
                </TableRow>
            )
        }):'':'';
        return (
            <div>
                 <Snackbar
                    onClose={this.handleCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    message={<span>{message}</span>}
                />
                {this.renderLoader()}
                <br />
                <Paper style={{width:'95%',margin:'0 auto'}}>
                    {
                        profile===null?
                        <div>
                            <Toolbar>
                                <Tooltip title="Atras">
                                    <IconButton onClick={() => window.history.back()}>
                                        <ArrowBackIos />
                                    </IconButton>
                                </Tooltip>
                                <Typography variant="h6" style={{width:400}}>{"Administrar cargos"}</Typography>
                                <div className="spacer" />
                                <Tooltip title="Agregar nuevo cargo">
                                    <IconButton onClick={e=>this.setState({profile:{},isNew:true,valores:valoresIniciales, plantillas: plantillasIniciales, description:'',name:'',id:''})}>
                                        <AddCircle style={{color: '#4caf50'}}/>
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Puesto</TableCell>
                                        <TableCell>Descripción</TableCell>
                                        <TableCell>Editar</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {cargosRows}
                                </TableBody>
                            </Table>
                        </div>
                        :
                        this.renderExtras()
                    }
                </Paper>
            </div>
        )
    }
}