import React, {Component} from 'react';
import { Toolbar, Divider,  Typography,CircularProgress, Table,TableBody, TableRow, TableCell,Tooltip, IconButton } from '@material-ui/core';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Chart from 'react-apexcharts'
import {companies, calculateReport, resultColorFunction, resultColorCustomFunction, arrayOfValors} from '../utils/controller';
import confievalLogo from '../assets/logo.png';
var Api2Pdf = require('api2pdf');   
var a2pClient = new Api2Pdf('0df40a0e-a00b-4f28-b514-5dc75413c307');

export default class Main extends Component {
    constructor(props){
        super(props);
        this.state={
            companyId: '',
            candidateId: '',
            company: null,
            candidate: null,
            isLoading: true,
            resultColor: '#000',
            noResult: false,
            result: 0,
            resultNoViolencia: 0,
            resultRectitud: 0,
            resultPaz: 0,
            resultAmor: 0,
            resultVerdad: 0
        }
    }
    componentWillMount() {
        this.handleStart();
    }

    handleStart = () => {
        const companyId = this.props.match.params.companyId;
        const candidateId = this.props.match.params.candidateId;
        if(companyId && candidateId){
            companies.child(companyId).on('value',async snapshotCompanies=>{
                companies.child(companyId+'/candidates/'+candidateId).on('value', async snapshot => {
                    const company = snapshotCompanies.toJSON();
                    const candidate =snapshot.toJSON();
                    const {answers} = candidate;
                    if (answers) {
                        const reportObject = await calculateReport(answers);
                        console.log('reportObject: ', reportObject);
                        this.setState({
                            isLoading: false,
                            companyId,
                            candidateId,
                            company,
                            candidate,
                            resultColor: reportObject.resultColor,
                            result: reportObject.resultInt,
                            resultNoViolencia: reportObject.resultNoViolencia,
                            resultRectitud: reportObject.resultRectitud,
                            resultAmor: reportObject.resultAmor,
                            resultPaz: reportObject.resultPaz,
                            resultVerdad: reportObject.resultVerdad
                        });
                    }         
                    else {
                        this.setState({noResult: true, isLoading:false})
                    }       
                })
            })
        }
    }

    handleReport = () => {
        this.setState({isLoading:true})
        const url = 'https://sistema-ssat.firebaseapp.com/reporte/'+this.state.companyId+'/'+ this.state.candidateId;
        a2pClient.headlessChromeFromUrl(url).then(function(result) {
            console.log(result);
            window.open(result.pdf, '_blank');
            this.setState({isLoading:false})
        });
    }
    handleReportPrint=()=>{
        window.print();
    }
    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;
    
        return [year, month, day].join('-');
    }
    formatHour = (date) => {
        var d = new Date(date),
            hour = '' + d.getHours(),
            minutes = '' + d.getMinutes(),
            seconds = d.getSeconds();
        return [hour, minutes, seconds].join(':');
    }
    
    renderResultVerdad=()=>{
        const {candidate, resultVerdad} = this.state;
        const name = candidate.nombre+' '+candidate.segundoNombre+' '+candidate.primerApellido+' '+candidate.segundoApellido;
        if(resultVerdad === 0  || resultVerdad === '0') {
            return (
                <Typography variant="body1" className="justify"><b>Muy Bajo: </b>{name+" obtiene un puntaje muy por debajo del promedio general de la población, esto indica que le otorga una muy baja importancia a la Verdad. La parte intelectual de sus valores es ignorada por completo, no le importan cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{". Una probabilidad muy alta de que "+name+" actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }   
        if(resultVerdad > 0 && resultVerdad <16) {
            return (
                <Typography variant="body1" className="justify"><b>Bajo: </b>{name + " obtiene un puntaje por debajo del promedio general de la población, esto indica que le otorga una baja importancia a la Verdad. La parte intelectual de sus valores es ignorada, le importan poco cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{". Existe una probabilidad alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }
        if(resultVerdad >= 16 && resultVerdad <32) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Bajo: </b>{name + " obtiene un puntaje un poco más bajo que el promedio general de la población, esto indica que la Verdad no es fundamental en su vida. La parte intelectual de sus valores es puesta en segundo plano, le da un poco menos de importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{".  Existe una probabilidad media-alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }
        if(resultVerdad >= 32 && resultVerdad <48) {
            return (
                <Typography variant="body1" className="justify"><b>Promedio: </b>{name + " obtiene un puntaje como el promedio general de la población, esto indica que la Verdad le impacta como al promedio de la población en general. La parte intelectual de sus valores está equilibrada, dándole adecuada importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{". Existe una probabilidad media de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }
        if(resultVerdad >= 48 && resultVerdad <64) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Alto: </b>{name + " obtiene un puntaje un poco más alto que el promedio general de la población, esto indica que valora la Verdad un poco más que el resto de la población. Resalta la parte intelectual de sus valores, dándole una considerable prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{". Existe una probabilidad media-baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }
        if(resultVerdad >= 64 && resultVerdad <80) {
            return (
                <Typography variant="body1" className="justify"><b>Alto: </b>{name + " obtiene un puntaje por encima promedio general de la población, esto indica que valora la Verdad mucho más que el resto de la población. Resalta la parte intelectual de sus valores, dándole mucha prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{". Existe una probabilidad baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }
        if(resultVerdad >= 80 && resultVerdad <=100) {
            return (
                <Typography variant="body1" className="justify"><b>Muy Alto: </b>{name + " obtiene un puntaje muy por encima del promedio general de la población, esto indica que valora la Verdad de manera extraordinaria en comparación al resto de la población. Resalta fuertemente la parte intelectual de sus valores, dándole total importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Integridad, Igualdad, Veracidad y Discernimiento</span>{". Existe una probabilidad muy baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Contradicciones, Desconfianza, Desinterés, Engaño, Falsedad, Fingimiento, Hipocresía, Ilegalidad, Cobardía, Insinceridad, Mentira</span>{"."}</Typography>
            )
        }
    }
    renderResultRectitud=()=>{
        const {candidate, resultRectitud} = this.state;
        const name = candidate.nombre+' '+candidate.segundoNombre+' '+candidate.primerApellido+' '+candidate.segundoApellido;
        if(resultRectitud === 0 || resultRectitud === '0') {
            return (
                <Typography variant="body1" className="justify"><b>Muy Bajo: </b>{name + " obtiene un puntaje muy por debajo del promedio general de la población, esto indica que le otorga una muy baja importancia a la Rectitud. La parte intelectual de sus valores es ignorada por completo, no le importan cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad muy alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }   
        if(resultRectitud > 0 && resultRectitud <16) {
            return (
                <Typography variant="body1" className="justify"><b>Bajo: </b>{name + " obtiene un puntaje por debajo del promedio general de la población, esto indica que le otorga una baja importancia a la Rectitud. La parte intelectual de sus valores es ignorada, le importan poco cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }
        if(resultRectitud >= 16 && resultRectitud <32) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Bajo: </b>{name + " obtiene un puntaje un poco más bajo que el promedio general de la población, esto indica que la Rectitud no es fundamental en su vida. La parte intelectual de sus valores es puesta en segundo plano, le da un poco menos de importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad media-alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }
        if(resultRectitud >= 32 && resultRectitud <48) {
            return (
                <Typography variant="body1" className="justify"><b>Promedio: </b>{name + " obtiene un puntaje como el promedio general de la población, esto indica que la Rectitud le impacta como al promedio de la población en general. La parte intelectual de sus valores está equilibrada, dándole adecuada importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad media de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }
        if(resultRectitud >= 48 && resultRectitud <64) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Alto: </b>{name + " obtiene un puntaje un poco más alto que el promedio general de la población, esto indica que valora la Rectitud un poco más que el resto de la población. Resalta la parte intelectual de sus valores, dándole una considerable prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad media-baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }
        if(resultRectitud >= 64 && resultRectitud <80) {
            return (
                <Typography variant="body1" className="justify"><b>Alto: </b>{name + " obtiene un puntaje por encima promedio general de la población, esto indica que valora la Rectitud mucho más que el resto de la población. Resalta la parte intelectual de sus valores, dándole mucha prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }
        if(resultRectitud >= 80 && resultRectitud <=100) {
            return (
                <Typography variant="body1" className="justify"><b>Muy Alto: </b>{name + " obtiene un puntaje muy por encima del promedio general de la población, esto indica que valora la Rectitud de manera extraordinaria en comparación al resto de la población. Resalta fuertemente la parte intelectual de sus valores, dándole total importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Compromiso, Ética, Gratitud, Honestidad, Liderazgo, Moralidad, Respeto, Responsabilidad</span>{". Existe una probabilidad muy baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Deshonestidad, Deslealtad, Ilegalidad, Inmoralidad, Insatisfacción, Irresponsabilidad, Irrespeto, Corrupción, Violencia</span>{"."}</Typography>
            )
        }
    }
    renderResultPaz=()=>{
        const {candidate, resultPaz} = this.state;
        const name = candidate.nombre+' '+candidate.segundoNombre+' '+candidate.primerApellido+' '+candidate.segundoApellido;
        if(resultPaz === 0  || resultPaz === '0') {
            return (
                <Typography variant="body1" className="justify"><b>Muy Bajo: </b>{name + " obtiene un puntaje muy por debajo del promedio general de la población, esto indica que le otorga una muy baja importancia a la Paz. La parte intelectual de sus valores es ignorada por completo, no le importan cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad muy alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }   
        if(resultPaz > 0 && resultPaz <16) {
            return (
                <Typography variant="body1" className="justify"><b>Bajo: </b>{name + " obtiene un puntaje por debajo del promedio general de la población, esto indica que le otorga una baja importancia a la Paz. La parte intelectual de sus valores es ignorada, le importan poco cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }
        if(resultPaz >= 16 && resultPaz <32) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Bajo: </b>{name + " obtiene un puntaje un poco más bajo que el promedio general de la población, esto indica que la Paz no es fundamental en su vida. La parte intelectual de sus valores es puesta en segundo plano, le da un poco menos de importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad media-alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }
        if(resultPaz >= 32 && resultPaz <48) {
            return (
                <Typography variant="body1" className="justify"><b>Promedio: </b>{name + " obtiene un puntaje como el promedio general de la población, esto indica que la Paz le impacta como al promedio de la población en general. La parte intelectual de sus valores está equilibrada, dándole adecuada importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad media de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }
        if(resultPaz >= 48 && resultPaz <64) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Alto: </b>{name + " obtiene un puntaje un poco más alto que el promedio general de la población, esto indica que valora la Paz un poco más que el resto de la población. Resalta la parte intelectual de sus valores, dándole una considerable prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad media-baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }
        if(resultPaz >= 64 && resultPaz <80) {
            return (
                <Typography variant="body1" className="justify"><b>Alto: </b>{name + " obtiene un puntaje por encima promedio general de la población, esto indica que valora la Paz mucho más que el resto de la población. Resalta la parte intelectual de sus valores, dándole mucha prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }
        if(resultPaz >= 80 && resultPaz <=100) {
            return (
                <Typography variant="body1" className="justify"><b>Muy Alto: </b>{name + " obtiene un puntaje muy por encima del promedio general de la población, esto indica que valora la Paz de manera extraordinaria en comparación al resto de la población. Resalta fuertemente la parte intelectual de sus valores, dándole total importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Concentración, Disciplina, Felicidad, Humildad, Paciencia, Calma</span>{". Existe una probabilidad muy baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Arrogancia, Desorden, Hostilidad, Impaciencia, Intolerancia, Intranquilidad</span>{"."}</Typography>
            )
        }
    }
    renderResultAmor=()=>{
        const {candidate, resultAmor} = this.state;
        const name = candidate.nombre+' '+candidate.segundoNombre+' '+candidate.primerApellido+' '+candidate.segundoApellido;
        if(resultAmor === 0 || resultAmor === '0') {
            return (
                <Typography variant="body1" className="justify"><b>Muy Bajo: </b>{name + " obtiene un puntaje muy por debajo del promedio general de la población, esto indica que le otorga una muy baja importancia al Amor. La parte intelectual de sus valores es ignorada por completo, no le importan cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad muy alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }   
        if(resultAmor > 0 && resultAmor <16) {
            return (
                <Typography variant="body1" className="justify"><b>Bajo: </b>{name + " obtiene un puntaje por debajo del promedio general de la población, esto indica que le otorga una baja importancia al Amor. La parte intelectual de sus valores es ignorada, le importan poco cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }
        if(resultAmor >= 16 && resultAmor <32) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Bajo: </b>{name + " obtiene un puntaje un poco más bajo que el promedio general de la población, esto indica que el Amor no es fundamental en su vida. La parte intelectual de sus valores es puesta en segundo plano, le da un poco menos de importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad media-alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }
        if(resultAmor >= 32 && resultAmor <48) {
            return (
                <Typography variant="body1" className="justify"><b>Promedio: </b>{name + " obtiene un puntaje como el promedio general de la población, esto indica que el Amor le impacta como al promedio de la población en general. La parte intelectual de sus valores está equilibrada, dándole adecuada importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad media de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }
        if(resultAmor >= 48 && resultAmor <64) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Alto: </b>{name + " obtiene un puntaje un poco más alto que el promedio general de la población, esto indica que valora el Amor un poco más que el resto de la población. Resalta la parte intelectual de sus valores, dándole una considerable prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad media-baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }
        if(resultAmor >= 64 && resultAmor <80) {
            return (
                <Typography variant="body1" className="justify"><b>Alto: </b>{name + " obtiene un puntaje por encima promedio general de la población, esto indica que valora el Amor mucho más que el resto de la población. Resalta la parte intelectual de sus valores, dándole mucha prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }
        if(resultAmor >= 80 && resultAmor <=100) {
            return (
                <Typography variant="body1" className="justify"><b>Muy Alto: </b>{name + " obtiene un puntaje muy por encima del promedio general de la población, esto indica que valora el Amor de manera extraordinaria en comparación al resto de la población. Resalta fuertemente la parte intelectual de sus valores, dándole total importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Amabilidad, Ayuda, Bondad, Compasión, Dedicación, Generosidad, Sinceridad, Tolerancia. </span>{"Existe una probabilidad muy baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Antipatía, Crueldad, Desinterés, Despreocupación, Egoísmo, Hipocresía</span>{"."}</Typography>
            )
        }
    }
    renderResultNoViolencia=()=>{
        const {candidate, resultNoViolencia} = this.state;
        const name = candidate.nombre+' '+candidate.segundoNombre+' '+candidate.primerApellido+' '+candidate.segundoApellido;
        if(resultNoViolencia === 0 || resultNoViolencia === '0') {
            return (
                <Typography variant="body1" className="justify"><b>Muy Bajo: </b>{name + " obtiene un puntaje muy por debajo del promedio general de la población, esto indica que le otorga una muy baja importancia a la No Violencia. La parte intelectual de sus valores es ignorada por completo, no le importan cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad muy alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }   
        if(resultNoViolencia > 0 && resultNoViolencia <16) {
            return (
                <Typography variant="body1" className="justify"><b>Bajo: </b>{name + " obtiene un puntaje por debajo del promedio general de la población, esto indica que le otorga una baja importancia a la No Violencia. La parte intelectual de sus valores es ignorada, le importan poco cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }
        if(resultNoViolencia >= 16 && resultNoViolencia <32) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Bajo: </b>{name + " obtiene un puntaje un poco más bajo que el promedio general de la población, esto indica que la No Violencia no es fundamental en su vida. La parte intelectual de sus valores es puesta en segundo plano, le da un poco menos de importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad media-alta de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }
        if(resultNoViolencia >= 32 && resultNoViolencia <48) {
            return (
                <Typography variant="body1" className="justify"><b>Promedio: </b>{name + " obtiene un puntaje como el promedio general de la población, esto indica que la No Violencia le impacta como al promedio de la población en general. La parte intelectual de sus valores está equilibrada, dándole adecuada importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad media de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }
        if(resultNoViolencia >= 48 && resultNoViolencia <64) {
            return (
                <Typography variant="body1" className="justify"><b>Medio-Alto: </b>{name + " obtiene un puntaje un poco más alto que el promedio general de la población, esto indica que valora la No Violencia un poco más que el resto de la población. Resalta la parte intelectual de sus valores, dándole una considerable prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad media-baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }
        if(resultNoViolencia >= 64 && resultNoViolencia <80) {
            return (
                <Typography variant="body1" className="justify"><b>Alto: </b>{name + " obtiene un puntaje por encima promedio general de la población, esto indica que valora la No Violencia mucho más que el resto de la población. Resalta la parte intelectual de sus valores, dándole mucha prioridad a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }
        if(resultNoViolencia >= 80 && resultNoViolencia <=100) {
            return (
                <Typography variant="body1" className="justify"><b>Muy Alto: </b>{name + " obtiene un puntaje muy por encima del promedio general de la población, esto indica que valora la No Violencia de manera extraordinaria en comparación al resto de la población. Resalta fuertemente la parte intelectual de sus valores, dándole total importancia a cualidades como: "}<span style={{color: '#2d5d71', fontStyle: 'italic'}}>Justicia, Igualdad, Respeto por la vida, Lealtad</span>{". Existe una probabilidad muy baja de que " + name + " actúe con: "}<span style={{color: '#884c19', fontStyle: 'italic'}}>Agresividad, Irrespeto, Injusticia, Desacato, Violencia</span>{"."}</Typography>
            )
        }
    }
    
    renderTextIsApprobed = () => {
        const {candidate, resultNoViolencia, resultRectitud, resultAmor, resultPaz, resultVerdad} = this.state;
        const valores = candidate.cargo.valores;
        let approbed = false;
        let sumEvaluado = 0;
        sumEvaluado = parseFloat(resultNoViolencia) + parseFloat(resultRectitud) + parseFloat(resultAmor) + parseFloat(resultPaz) + parseFloat(resultVerdad);
        let promedioEvaluado = sumEvaluado / 5; 
        let sum = 0;
        Object.values(valores).forEach(valor => {
            sum += valor.val;
        })
        let promedioRequerido = sum/5;
        if(promedioEvaluado >= promedioRequerido) {
            approbed = true;
        }
        return (
            <Typography variant="subtitle1" style={{color:'#000'}}>{approbed ? 'Si ' : 'No ' }cumple con el porcentaje mínimo requerido para el cargo</Typography>                
        )
    }
    renderBottomPage =(page)=>{
        const {candidate} = this.state;
        return (
            <div style={{width: '100%', paddingLeft: 20, paddingRight: 20, marginTop: 170, marginBottom: 20}}>
                <Table>
                    <TableRow style={{paddingTop: 0, paddingBottom: 0}}>
                        <TableCell style={{borderColor: '#fff', paddingTop: 0, paddingBottom: 0}}>
                            <Typography variant="subtitle1" style={{fontSize: 12}}><b>Nombre del evaluado: </b>{candidate.nombre+' '+candidate.segundoNombre+' '+candidate.primerApellido+' '+candidate.segundoApellido}</Typography>
                        </TableCell>
                        <TableCell style={{borderColor: '#fff', paddingTop: 0, paddingBottom: 0}}>
                            <Typography variant="subtitle1" style={{fontSize: 12}}><b>Cargo al que aplica: </b>{candidate.cargo.name}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{paddingTop: 0, paddingBottom: 0}}>
                        <TableCell style={{borderColor: '#fff', paddingTop: 0, paddingBottom: 0}}>
                            <Typography variant="subtitle1" style={{fontSize: 12}}><b>Fecha de la evaluación: </b>{this.formatDate(candidate.dateFinished)}</Typography>
                        </TableCell>
                        <TableCell style={{borderColor: '#fff', paddingTop: 0, paddingBottom: 0}}>
                            <Typography variant="subtitle1" style={{fontSize: 12}}><b>Fecha de generación del reporte: </b>{this.formatDate(Date.now())}</Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow style={{paddingTop: 0, paddingBottom: 0}}>
                        <TableCell colSpan={2} style={{borderColor: '#fff', textAlign: 'center', paddingTop: 0, paddingBottom: 0}}>
                            <Typography variant="subtitle1" style={{fontSize: 12, color: 'red'}}><b>CONFIDENCIAL</b></Typography>
                        </TableCell>
                    </TableRow>
                </Table>
                <Typography variant="subtitle1" style={{fontSize: 12, textAlign: 'right', marginRight:20}}>{page} de 6</Typography>
            </div>
        )
    }
    renderHonestyShield = () => {
        const {candidate} = this.state;
        if (candidate) {
            if (candidate.honestyShieldPictures) {
                const honestyShieldPictures = Object.values(candidate.honestyShieldPictures).map((element, index)=>{
                    return(<img key={index} src={element} alt='Honesty shield' style={{width: 350, height: 200, display: 'inlineBlock', margin: 10}} />);
                });
                return (
                    <div>
                        <Typography variant="h6">
                        Honesty shield
                        </Typography>
                        <br />
                        {honestyShieldPictures}
                    </div>
                )
            }
        }
        return null;
    }
   
    render(){
        const {candidate,company, isLoading, result, resultAmor, resultNoViolencia, resultPaz, resultRectitud, resultVerdad, noResult} = this.state;
        const valores = candidate !== null ? candidate.cargo.valores : null;
        
        return isLoading? <CircularProgress style={{margin:'0 auto',display:'table', marginTop: 100}}/>: noResult ? <Typography variant ="subtitle2">El usuario no ha rendido su examen</Typography> :(
          <div style={{minWidth: 820, width:820, maxWidth: 820,margin:'0 auto'}}>
          {
              candidate !== null &&
              <div>
                <div style={{minHeight: 1200,height: 1200}}>
                    <Toolbar>
                        <Typography variant="h5" style={{width: 600}}>Reporte de evaluación</Typography>
                        <div className="spacer" />
                        <Tooltip title="Descargar">
                            <IconButton onClick={()=>this.handleReportPrint()}>
                                <CloudDownload />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <Table style={{maxWidth: 820, width: 820, minWidth: 820}}>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{borderBottomColor:'#fff'}}>
                                    <img src={confievalLogo} style={{width: 200, alignSelf:'center',  margin: '0 auto', display: 'table'}} alt="Confieval" />
                                </TableCell>
                                <TableCell style={{borderBottomColor:'#fff'}}>
                                    <img src={company.logo} style={{width: 200, alignSelf: 'center', margin: '0 auto', display: 'table'}} alt="Confieval" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br />
                    <br />
                    <Toolbar>
                        <Typography variant="subtitle2">Información del candidato</Typography>
                    </Toolbar>
                    <Table style={{maxWidth: 820, width: 820, minWidth: 820}}>
                        <TableBody>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Cédula</b></TableCell>
                                <TableCell>{candidate.ci}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Nombre</b></TableCell>
                                <TableCell>{candidate.nombre}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Segundo Nombre</b></TableCell>
                                <TableCell>{candidate.segundoNombre}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Primer Apellido</b></TableCell>
                                <TableCell>{candidate.primerApellido}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Segundo Apellido</b></TableCell>
                                <TableCell>{candidate.segundoApellido}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Fecha de nacimiento</b></TableCell>
                                <TableCell>{candidate.birthDate}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Fecha de evaluación</b></TableCell>
                                <TableCell>{this.formatDate(candidate.dateFinished)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Hora de inicio</b></TableCell>
                                <TableCell>{this.formatHour(candidate.dateStarted)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Hora de finalización</b></TableCell>
                                <TableCell>{this.formatHour(candidate.dateFinished)}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>Puesto</b></TableCell>
                                <TableCell>{candidate.cargo.name}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={"tableRowTitle"}><b>País</b></TableCell>
                                <TableCell>{candidate.pais}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <br />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <br />
                    <Toolbar>
                        <Typography variant="h5">Resultado general de valores</Typography>
                    </Toolbar>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div 
                        style={{
                            margin:'0 auto', 
                            borderStyle: 'solid',
                            borderColor: resultColorCustomFunction(result, candidate), 
                            borderWidth: 5,
                            borderRadius: '50%',
                            width: 75
                        }}
                    >
                        <span style={{ fontSize: 50, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                            {result}
                        </span>
                    </div>
                    <div style={{ margin:'0 auto', display: 'table', textAlign: 'center'}}>
                        {this.renderTextIsApprobed()}
                    </div>
                </div>
                <Divider />
                {this.renderBottomPage(1)}
                <Divider />
                <div style={{minHeight: 1200,height: 1200, width: 820, minWidth: 820, maxWidth: 820}}>
                        <br />
                        <br />
                        <Typography variant="h6" style={{textAlign:'center'}}>Análisis de Puntajes</Typography>
                        <br />
                        <div>
                            <Typography variant="body1" className="justify">
                            En esta sección usted encontrará un resumen de la información cuantitativa, además de una representación gráfica del puntaje obtenido por el evaluado. A la izquierda encontrará el Resultado de General de Honestidad que muestra comparativamente el puntaje obtenido por el evaluado vs el perfil para el cargo que está aplicando. A continuación, en la parte derecha podrá encontrar las puntuaciones individuales de cada una de las dimensiones y el puntaje obtenido por el evaluado en relación a estas. Finalmente, en la parte inferior encontramos las zonas de incidencia que muestran un resumen general del evaluado con respecto al puesto, al cargo y al país, cabe recalcar que esto no afecta al Resultado general de valores.
                            </Typography>
                        </div>
                        <br />
                        <br />
                        <br />
                        <Divider />
                        <br />
                        <Table style={{width: 820, minWidth: 820, maxWidth: 820}}>
                        <TableBody>
                        <TableRow style={{padding: 0}}>
                            <TableCell style={{borderBottomColor:'#fff', width: 220, padding: 0}}>
                                <Typography variant="h6" >Significado de colores</Typography>
                                <br />
                                <div>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall7" /></TableCell>
                                                <TableCell>Riesgo perceptible muy bajo</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall6" /></TableCell>
                                                <TableCell>Riesgo perceptible bajo</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall5" /></TableCell>
                                                <TableCell>Riesgo perceptible medio-bajo</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall4" /></TableCell>
                                                <TableCell>Riesgo perceptible promedio</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall3" /></TableCell>
                                                <TableCell>Riesgo perceptible medio-alto</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall2" /></TableCell>
                                                <TableCell>Riesgo perceptible alto</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{paddingLeft: 5, paddingRight: 5}}><div className="colorBall1" /></TableCell>
                                                <TableCell>Riesgo perceptible muy alto</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </TableCell>
                            <TableCell style={{width: 600, borderBottomColor:'#fff'}} id="chart">
                            {
                                valores !== null && (
                                    <Chart
                                        type="bar"
                                        width="600"
                                        height="400"
                                        series={[
                                            {
                                                name: 'Resultado del Valor',
                                                data: [
                                                    parseFloat(resultVerdad),
                                                    parseFloat(resultRectitud),
                                                    parseFloat(resultPaz),
                                                    parseFloat(resultAmor),
                                                    parseFloat(resultNoViolencia),
                                                ]
                                            },
                                            {
                                                name: 'Cargo',
                                                data: [
                                                    valores.Verdad ? valores.Verdad.val : 0,
                                                    valores.Rectitud ? valores.Rectitud.val : 0,
                                                    valores.Paz ? valores.Paz.val : 0,
                                                    valores.Amor ? valores.Amor.val : 0,
                                                    valores['No violencia'] ? valores['No violencia'].val : 0
                                                ]
                                            }
                                        ]}
                                        options={{
                                            dataLabels: {
                                                enabled: false
                                            },
                                            xaxis: {
                                                categories: ['Intelectual', 'Física', 'Emocional', 'Afectiva', 'Espiritual'],
                                                position: 'bottom',
                                                axisBorder: {show: true},
                                                axisTicks: {show: true}
                                            },
                                            yaxis: {
                                                min: 0,
                                                max: 100,
                                                axisBorder: {show: false},
                                                labels: {show: true},
                                                tickAmount: 6
                                            },
                                            tooltip: {
                                                enabled: true,
                                                marker: {
                                                    show: false,
                                                },
                                            },
                                            legend: {
                                                show: true
                                            },
                                            title: {
                                                text: 'Dimensiones'
                                            },
                                            colors: [({ value, seriesIndex, w }) => resultColorFunction(value+10, arrayOfValors[seriesIndex]),'#3366cc'],
                                            fill: {
                                                type: ['gradient', 'solid'],
                                                colors: [({ value, seriesIndex, w }) => resultColorFunction(value+10, arrayOfValors[seriesIndex]), '#3366cc'],
                                                gradient: {
                                                    shade: 'light',
                                                    type: 'vertical',
                                                    shadeIntensity: 1,
                                                    opacityFrom: 1,
                                                    opacityTo: 1,
                                                    gradientToColors: [resultColorFunction(5), '#3366cc'],
                                                    inverseColors: false,
                                                    stops: [15, 90, 100]
                                                }
                                            }
                                        }}
                                    />
                                )
                            }
                                {/*<ChartN
                                    width={600}
                                    height={400}
                                    chartType="ColumnChart"
                                    loader={<div>Cargando</div>}
                                    data={[
                                        ['Valores', 'Resultado de honestidad', { role: 'style' }],
                                        ['Verdad', parseFloat(resultVerdad), (resultColorFunction(resultVerdad, 'Verdad'))],
                                        ['',  candidate.cargo.valores.Verdad.val, '#3366cc'],
                                        ['Rectitud', parseFloat(resultRectitud),  resultColorFunction(resultRectitud, 'Rectitud')],
                                        ['', candidate.cargo.valores.Rectitud.val, '#3366cc'],
                                        ['Paz', parseFloat(resultPaz), resultColorFunction(resultPaz, 'Paz')],
                                        ['',  candidate.cargo.valores.Paz.val,  '#3366cc'],
                                        ['Amor', parseFloat(resultAmor), resultColorFunction(resultAmor, 'Amor')],
                                        ['', candidate.cargo.valores.Amor.val, '#3366cc'],
                                        ['No violencia', parseFloat(resultNoViolencia), resultColorFunction(resultNoViolencia, 'Noviolencia')],
                                        ['', candidate.cargo.valores['No violencia'].val, '#3366cc'],
                                    ]}
                                    options={{
                                        title: 'Dimensiones',
                                        isStacked: true,
                                        legend: 'none',
                                        hAxis: {
                                            title: 'Valores',
                                            minValue: 0,
                                            maxValue: 100,
                                        },
                                        vAxis: {
                                            title: 'Resultados',
                                            minValue: 0,
                                            ticks: [0, 16, 32, 48, 64, 80, 100]
                                        },
                                    }}
                                />*/}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={2} style={{borderBottomColor:'#fff'}}>
                                <Toolbar>
                                    <Typography variant="h5">Resultado general de valores</Typography>
                                </Toolbar>
                                <div 
                                    style={{
                                        margin:'0 auto', 
                                        borderStyle: 'solid',
                                        borderColor: resultColorCustomFunction(result, candidate), 
                                        borderWidth: 5,
                                        borderRadius: '50%',
                                        width: 75
                                    }}
                                >
                                    <span style={{ fontSize: 50, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                        {result}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                        </Table>
                        <br />
                        <Table style={{maxWidth: 820, width: 820, minWidth: 820}}>
                        <TableBody>
                        <TableRow>
                            <TableCell style={{borderBottomColor:'#fff'}}>
                                <Typography variant="subtitle1" style={{display:'table', margin:'0 auto' }}>Intelectual</Typography>
                                <div 
                                    style={{
                                        margin:'0 auto', 
                                        borderStyle: 'solid',
                                        borderColor: resultColorFunction(resultVerdad, 'Verdad'), 
                                        borderWidth: 4,
                                        borderRadius: '50%',
                                        width: 45
                                    }}
                                >
                                    <span style={{ fontSize: 30, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                        {resultVerdad}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell style={{borderBottomColor:'#fff'}}>
                                <Typography variant="subtitle1" style={{display:'table', margin:'0 auto' }}>Física</Typography>
                                <div 
                                    style={{
                                        margin:'0 auto', 
                                        borderStyle: 'solid',
                                        borderColor: resultColorFunction(resultRectitud, 'Rectitud'), 
                                        borderWidth: 4,
                                        borderRadius: '50%',
                                        width: 45
                                    }}
                                >
                                    <span style={{ fontSize: 30, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                        {resultRectitud}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell style={{borderBottomColor:'#fff'}}>
                                <Typography variant="subtitle1" style={{display:'table', margin:'0 auto' }}>Emocional</Typography>
                                <div 
                                    style={{
                                        margin:'0 auto', 
                                        borderStyle: 'solid',
                                        borderColor: resultColorFunction(resultPaz, 'Paz'), 
                                        borderWidth: 4,
                                        borderRadius: '50%',
                                        width: 45
                                    }}
                                >
                                    <span style={{ fontSize: 30, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                        {resultPaz}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell style={{borderBottomColor:'#fff'}}>
                                <Typography variant="subtitle1" style={{display:'table', margin:'0 auto' }}>Afectiva</Typography>
                                <div 
                                    style={{
                                        margin:'0 auto', 
                                        borderStyle: 'solid',
                                        borderColor: resultColorFunction(resultAmor, 'Amor'), 
                                        borderWidth: 4,
                                        borderRadius: '50%',
                                        width: 45
                                    }}
                                >
                                    <span style={{ fontSize: 30, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                        {resultAmor}
                                    </span>
                                </div>
                            </TableCell>
                            <TableCell style={{borderBottomColor:'#fff'}}>
                                <Typography variant="subtitle1" style={{display:'table', margin:'0 auto' }} >Espiritual</Typography>
                                <div 
                                    style={{
                                        margin:'0 auto', 
                                        borderStyle: 'solid',
                                        borderColor: resultColorFunction(resultNoViolencia, 'Noviolencia'), 
                                        borderWidth: 4,
                                        borderRadius: '50%',
                                        width: 45
                                    }}
                                >
                                    <span style={{ fontSize: 30, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                        {resultNoViolencia}
                                    </span>
                                </div>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                        </Table>
                        <br />
                </div>
                <Divider />
                {this.renderBottomPage(2)}
                <Divider />
                <div style={{height: 1200, minHeight: 1200, maxHeight: 1200}}>
                        <Typography variant="h6" style={{textAlign:'center'}}>Notas Interpretativas</Typography>
                        <br />
                        <div>
                            <Typography variant="body1" className="justify">
                            Los resultados expuestos en ambos sentidos, no garantizan que el evaluado se encuentre exento de involucrarse en actividades relacionadas con el área de incidencia, esto debido a que el comportamiento humano no puede ser predicho con una efectividad del 100%. La evaluación compara la tendencia conductual del evaluado contra perfiles conductuales de personas en igualdad de circunstancias al momento de rendir la evaluación.
                            </Typography>
                        </div>
                        <br />
                        <br />
                        <br />
                        <Typography variant="h6" style={{textAlign:'center'}}>Dimensiones de honestidad</Typography>
                        <br />
                        <div>
                            <Typography variant="body1" className="justify">
                                A continuación, se muestran las 5 dimensiones de honestidad, en ellas se detallan los valores más relevantes, además de la tendencia conductual del evaluado hacia cada una de ellos:
                            </Typography>
                        </div>
                        <br />
                        <br />
                        <Divider />
                        <Typography variant="h6" >Interpretaciones por cada dimensión según puntaje obtenido</Typography>
                        <br />
                        <Typography variant="h6" style={{textAlign: 'center'}}>Dimensión Intelectual</Typography>
                        <div>
                            <Typography variant="body1" className="justify">
                            Al nivel intelectual se le asocia la función Pensar y el valor intelectual, esto debido a una cuestión básica de los seres humanos, cada individuo trata de encontrar el porqué de las cosas mediante el uso de su intelecto, además, busca una razón por la cual suceden las cosas, también busca la verdad ya que este es el propósito del intelecto. Al desarrollar el intelecto en toda su plenitud, el individuo logra el valor conocido como verdad.
                            </Typography>
                        </div>
                        <br />
                        <Typography variant="h6" >Resultado obtenido</Typography>
                        {this.renderResultVerdad()}
                        <br />
                        <Typography variant="h6" style={{textAlign: 'center'}}>Dimensión Física</Typography>
                        <div>
                            <Typography variant="body1" className="justify">
                            Al nivel físico se le asocia la función Actuar y el valor físico, esto se debe a que siempre que un individuo actúe, dichas acciones tendrán lugar en un plano físico. El individuo alcanza su máximo nivel en cuanto se refiere al actuar, cuando realiza sus acciones en base a lo que se considera bueno y correcto. De esta forma, el individuo alcanza el valor Rectitud. Se dice que un individuo puede alcanzar la perfección máxima al lograr el valor Rectitud.
                            </Typography>
                        </div>
                        <br />
                        <Typography variant="h6" >Resultado obtenido</Typography>
                        {this.renderResultRectitud()}
                        <br />
                </div>
                <Divider />
                {this.renderBottomPage(3)}
                <Divider />
                <div style={{minHeight: 1200,height: 1200}}>
                        <Typography variant="h6" style={{textAlign: 'center'}}>Dimensión Emocional</Typography>
                        <div>
                            <Typography variant="body1" className="justify">
                            Al nivel emocional se le asocia la función Sentir y el valor Emocional, esto debido a que en este nivel el individuo alcanza un estado de perfección gracias al valor Paz, además, se alcanza la ecuanimidad por medio de la satisfacción con paz y felicidad.
                            </Typography>
                        </div>
                        <br />
                        <Typography variant="h6" >Resultado obtenido</Typography>
                        {this.renderResultPaz()}
                        <br />
                        <Typography variant="h6" style={{textAlign: 'center'}}>Dimensión Afectiva</Typography>
                        <div>
                            <Typography variant="body1" className="justify">
                            Al nivel físico se le asocia la función Intuir y el valor afectivo, esto debido a que se asocia la obtención del valor del amor por medio de una acción que tiene su origen en el centro psíquico del individuo, además, la emoción es indistinta del amor y viceversa. El amor puede manifestarse de diversas formas por medio del carácter siendo algunas, por ejemplo, la simpatía, bondad, amistad, devoción, patriotismo, entre otros. El centro psíquico del individuo es el gestor de esta energía conocida como amor.
                            </Typography>
                        </div>
                        <br />
                        <Typography variant="h6" >Resultado obtenido</Typography>
                        {this.renderResultAmor()}                        
                        <br />
                        <Typography variant="h6" style={{textAlign: 'center'}}>Dimensión Espiritual</Typography>
                        <div>
                            <Typography variant="body1" className="justify">
                            Al nivel espiritual se le asocia la función Ser y el valor espiritual, esto se debe a la obtención del nivel espiritual por parte del individuo cuando este adopta la comprensión universal y el amor, respeta, cuida y ama a todos los seres que tienen vida y que lo rodean, a las cosas existentes en el universo, y en conjunto los hace uno con su personalidad. El individuo que se siente identificado con el universo de personas y cosas que lo rodean, muy a pesar de no conocerlas, permiten adquirir cualidades o mejor llamados valores como el respeto por la vida, la incapacidad de producir daños a los seres vivos, el amor universal, entre otros, se hallan intrínsecos en la No Violencia.
                            </Typography>
                        </div>
                        <br />
                        <Typography variant="h6" >Resultado obtenido</Typography>
                        {this.renderResultNoViolencia()}                        
                </div>
                <Divider />
                {this.renderBottomPage(4)}
                <Divider />
                <div style={{height: 1200, minHeight: 1200, maxHeight: 1200}}>
                            <Typography variant="h6">
                                Información adicional del candidato
                            </Typography>
                            <br />
                            <Table style={{maxWidth: 820, width: 820, minWidth: 820}}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Estado civil</b></TableCell>
                                        <TableCell>
                                            {candidate.estadoCivil}
                                        </TableCell>
                                    </TableRow>    
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Hijos</b></TableCell>
                                        <TableCell>
                                            {candidate.hijos}
                                        </TableCell>
                                    </TableRow>    
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Antecedentes penales</b></TableCell>
                                        <TableCell>
                                            {candidate.antecedentesPenales}
                                        </TableCell>
                                    </TableRow>    
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Antecedentes Médicos</b></TableCell>
                                        <TableCell>
                                            {candidate.antecedentesMedicos}
                                        </TableCell>
                                    </TableRow>    
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Medicación / Dosis</b></TableCell>
                                        <TableCell>
                                            {candidate.medicacion}
                                        </TableCell>
                                    </TableRow>   
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Polígrafos Anteriores</b></TableCell>
                                        <TableCell>
                                            {candidate.poligrafosAnteriores}
                                        </TableCell>
                                    </TableRow>    
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Información Adicional</b></TableCell>
                                        <TableCell>
                                            {candidate.informacionAdicional}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                </div>
                <Divider />
                {this.renderBottomPage(5)}
                <Divider />
                <div style={{height: 1200, minHeight: 1200, maxHeight: 1200}}>
                        <div style={{padding: 20}}>
                            {this.renderHonestyShield()}
                        </div>
                </div>
                <Divider />
                {this.renderBottomPage(6)}
                <Divider />
                <br />
              </div>
          }
          </div>
        )
    }
}