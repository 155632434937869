import React, {Component} from 'react';
import { Toolbar, Paper, Tooltip, IconButton, Typography, TableBody,Table,TableRow,TableCell, TextField, CircularProgress } from '@material-ui/core';
import { ciudadesRef} from '../utils/controller';
import 'react-table/react-table.css';
import ReactTable from "react-table";
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import AddCircle from '@material-ui/icons/AddCircle';
import Snackbar from '@material-ui/core/Snackbar';
import ConfirmationDialog from './common/confirmationDialog';

export default class Citys extends Component {
    constructor(props){
        super(props);
        this.state={
            ciudades: [],
            isNew:false,
            ciudad:null,
            isLoading:true,
            city:'',
            openSnackbar:false,
            message:''
        }
    }
    componentWillMount() {
       this.handleMainFunction();
    }

    handleMainFunction = () => {
        this.setState({isLoading: true});
        ciudadesRef.on('value', snapshot => {
            let ciudades = Object.values(snapshot.toJSON());
            this.setState({ciudades,isLoading:false});
        })
    }

    handleCloseSnackbar = () => {this.setState({openSnackbar:false, message:''})}
    onAddButtonClick = () => {
        this.setState({isLoading:true})
        const {city} = this.state;
        let id = ciudadesRef.push().key;
        let newCity = {
            city,
            id
        };
        ciudadesRef.child(id).set(newCity);
        this.setState({isLoading:false, message:"Se ha agregado satisfactoriamente",openSnackbar:true})
        this.handleGoBack();
    }
    onEditButtonClick = () => {
        this.setState({isLoading:true})
        const {city, id} = this.state;
        let currentCity = {
            city,
            id
        };
        ciudadesRef.child(id).set(currentCity);
        this.setState({isLoading:false,message:"Se ha modificado satisfactoriamente",openSnackbar:true})
        this.handleGoBack();
    }
    isInvalid(){
        const {city} = this.state;
        if(city!==''){
            return false;
        }
        return true;
    }
    handleDelete = () => {
        const {isNew,ciudad} = this.state;
        if(!isNew && ciudad){
            this.setState({isLoading:true})
            let id = ciudad.id;
            ciudadesRef.child(id).remove().then(value=>{
                this.setState({isLoading:false, message:"Se ha eliminado satisfactoriamente",openSnackbar:true})
                this.handleGoBack();
            }).catch(error=>{
                console.log('error: ', error);
            });            
        }
    }
   
    renderButton = () => {
        const {isLoading,isNew} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea "+(isNew?"crear":'modificar')+" esta ciudad?"}
                    buttonText={(isNew?"Agregar":"Actualizar") + " ciudad"}
                    variant="contained"
                    isDisabled={this.isInvalid()}
                    className="submitGreen"
                    confirmAction={isNew?this.onAddButtonClick:this.onEditButtonClick}
                />
            );
        }
        return <CircularProgress />;
    }
    handleGoBack = () => {
        this.setState({
            isNew:false,
            ciudad:null,
            id:'',
            city:''
        })
        this.handleMainFunction();
    }
    renderTitle = () => {
        const {ciudad, isNew} = this.state;
        if(ciudad===null){
            return "Administrar ciudades"
        }
        else if(isNew === true){
            return "Agregar ciudad"
        }
        else{
            return "Modificar ciudad"
        }
    }
    renderCiudad = () => {
        const {isNew} = this.state;
        return(
            <div>
                <Toolbar>
                    <Tooltip title="Atras">
                        <IconButton onClick={this.handleGoBack}>
                            <ArrowBackIos />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h6" style={{width:400}}>{this.renderTitle()}</Typography>
                    {
                        !isNew &&
                            <div className="spacer" />
                    }
                    {
                        !isNew&&
                            <ConfirmationDialog
                                confirmationText={"¿Está seguro que desea eliminar esta ciudad?"}
                                buttonText={"Eliminar Ciudad"}
                                variant="contained"
                                icon = {<Delete />}
                                isDisabled={false}
                                confirmAction={this.handleDelete}
                                color="secondary"
                            />
                    }
                </Toolbar>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell className={"tableRowTitle"}><b>Nombre</b></TableCell>
                            <TableCell colSpan={3}>
                                <TextField 
                                    fullWidth
                                    multiline
                                    autoFocus
                                    value={this.state.city}
                                    onChange={e=>this.setState({city:e.target.value})}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className={"submitContainer"}>
                {this.renderButton()}
                </div>
            </div>
        )
    }
    renderExtras = () => {
        const {ciudad} = this.state;
        if(ciudad!==null){
            return this.renderCiudad()
        }
        return null;
    }
    onEditClick = (element) =>{
        this.setState({
            ciudad:element,
            isNew:false,
            city:element.city?element.city:'',
            id:element.id?element.id: ''
        })
    }

    renderLoader = () => {
        const {isLoading} = this.state;
        if (isLoading) {
            return (
                <div id="overlay">
                    <CircularProgress
                        color="secondary"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                </div>
            )
        }
        return null;
    }

    render(){
        const {ciudad,openSnackbar ,message, ciudades } = this.state;
        return (
            <div>
                {this.renderLoader()}
                 <Snackbar
                    onClose={this.handleCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    message={<span>{message}</span>}
                />
                <br />
                <Paper style={{width:'95%',margin:'0 auto'}}>
                    {
                        ciudad===null?
                        <div>
                            <Toolbar>
                                <Typography variant="h6" style={{width:400}}>{"Administrar ciudades"}</Typography>
                                <div className="spacer" />
                                <Tooltip title="Agregar nueva ciudad">
                                    <IconButton onClick={e=>this.setState({ciudad:{},isNew:true,city:'',id:''})}>
                                        <AddCircle style={{color: '#4caf50'}}/>
                                    </IconButton>
                                </Tooltip>
                            </Toolbar>
                            <ReactTable 
                                data={ciudades}
                                defaultPageSize={10}
                                previousText= 'Anterior'
                                nextText= 'Siguiente'
                                loadingText= 'Cargando...'
                                noDataText= 'No hay datos'
                                pageText= 'Página'
                                ofText= 'de'
                                rowsText= 'Filas'
                                pageJumpText= 'Ir a página'
                                rowsSelectorText= 'filas por página'
                                filterable 
                                defaultFilterMethod={(filter, row) =>String(row[filter.id]).toLowerCase().includes( filter.value.toLowerCase())}
                                columns={[
                                {
                                    columns: [
                                    {
                                        Header: "Nombre",
                                        accessor: "city",
                                    },
                                    {
                                        Header:'Detalle',
                                        accessor:'city',
                                        width:65,
                                        Cell: row => (
                                            <Tooltip title={"Detalle de "+row.value}>
                                                <IconButton onClick={()=>this.onEditClick(row.original)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    }
                                ]
                                }
                                ]}
                                className={"-striped -highlight table"}

                            />
                        </div>
                        :
                        this.renderExtras()
                    }
                </Paper>
            </div>
        )
    }
}