import React, {Component} from 'react';
import 'react-table/react-table.css';
import ReactTable from "react-table";
import Select from 'react-select';
import {companies, isAdmin, auth, usersRef, newCompanyRef, ciudadesRef, newUserRef} from '../utils/controller';
import ConfirmationDialog from './common/confirmationDialog';
import Uploader from './common/uploader';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import People from '@material-ui/icons/People';
import Person from '@material-ui/icons/Person';
import AccountBox from '@material-ui/icons/AccountBox';
import Paper from '@material-ui/core/Paper';
import {Link} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isPhone,isEmail } from '../utils';
export default class Companies extends Component {
    constructor(props){
        super(props);
        this.state={
            isLoading:true,
            message:'',
            openSnackbar:false,
            companies:[],
            ciudades:[],
            company:null,
            candidates:null,
            profiles:null,
            isNew:false,
            isNewUser:false,
            title:'Empresas',
            active:true,
            name:'',
            username:'',
            lastname:'',
            address:'',
            city:'',
            dateCreated:'',
            exams:0,
            examsLeft:0,
            time:30,
            tests:0,
            testsLeft:0,
            testsTime:30,
            logo:'',
            mail:'',
            id: '',
            uid: '',
            phone:'',
            password: '',
            passwordUser: '',
            users:null,
            user:null,
            handleUsers:false,
            companyUserName: '',
            companyUserLastname: '',
            companyUserMail: '',
            userDateCreated: '',
            companyUserPhone:  '',
            companyUserPassword:  '',
            companyUserUid:  '',
            companyUserProfiles: {admin: false,candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: '',
            companyUserActive:false
        }
    }
    componentDidMount(){
        this.handleMainFunction();
    }
    componentWillMount(){
        if(!isAdmin()){
            window.location.href = "/";
        }
    }

    handleMainFunction = () => {
        this.setState({isLoading: true})
        ciudadesRef.on('value', snapshot => {
            let ciudades = Object.values(snapshot.toJSON());
            this.setState({ciudades});
        });
        companies.on('value', snapshot => {
            let companies = Object.values(snapshot.toJSON());
            this.setState({companies, isLoading: false})
        })
    }
    generatePassword = (length) => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        
        return text;
    }
    handleGoBack = () => {
        this.setState({
            company:null,
            active:true,
            name:'',
            username: '',
            lastname: '',
            address:'',
            city:'',
            dateCreated:'',
            password: '',
            id: '',
            passwordUser: '',
            candidates: null,
            profiles: null,
            exams:0,
            examsLeft:0,
            time: 30,
            tests:0,
            testsLeft:0,
            testsTime:30,
            logo:'',
            mail:'',
            phone:'',
            uid:'',
            users:null,
            handleUsers:false,
            user:null,
            companyUserName: '',
            companyUserLastname: '',
            companyUserMail: '',
            userDateCreated: '',
            companyUserPhone:  '',
            companyUserPassword:  '',
            companyUserUid:  '',
            companyUserProfiles: {admin:false, candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: '',
            companyUserActive:false
        });
        this.handleMainFunction();
    }
    handleAddNew = () =>{
        this.setState({
            isNew:true,
            company:{},
            active:false,
            name:'',
            username: '',
            lastname: '',
            address:'',
            city:'',
            dateCreated:Date.now(),
            exams:0,
            examsLeft:0,
            time: 30,
            tests:0,
            testsLeft:0,
            testsTime:30,
            logo:'',
            mail:'',
            phone:'',
            users:[],
            handleUsers:false,
            companyUserName: '',
            companyUserLastname: '',
            companyUserMail: '',
            userDateCreated: '',
            companyUserPhone:  '',
            companyUserPassword:  '',
            companyUserUid:  '',
            companyUserProfiles: {admin:false, candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: '',
            companyUserActive:false
        });
    }
    handleDetail = (company,handleUsers) => {
        this.setState({
            isNew:false,
            company,
            active:company.active?company.active:false,
            name:company.name?company.name:'',
            username: company.username?company.username:'',
            lastname: company.lastname?company.lastname:'',
            address:company.address?company.address:'',
            candidates: company.candidates ? company.candidates : null,
            city: company.city?{value: company.city, label: company.city}:'',
            dateCreated:company.dateCreated?company.dateCreated:Date.now(),
            exams:company.exams?company.exams:0,
            tests: company.tests ? company.tests:0,
            testsLeft: company.testsLeft ? company.testsLeft:0,
            testsTime: company.testsTime ? company.testsTime:30,
            id: company.id ? company.id : '',
            examsLeft:company.examsLeft?company.examsLeft:0,
            time:company.time?company.time:30,
            logo:company.logo?company.logo:'',
            mail:company.mail?company.mail:'',
            phone:company.phone?company.phone:'',
            users:company.users?company.users:null,
            profiles:company.profiles?company.profiles:null,
            password: company.password ? company.password: '',
            uid: company.uid ? company.uid: '',
            handleUsers, 
            companyUserName: '',
            companyUserLastname: '',
            companyUserMail: '',
            userDateCreated: '',
            companyUserPhone:  '',
            companyUserPassword:  '',
            companyUserUid:  '',
            companyUserProfiles: {admin:false, candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: '',
            companyUserActive:false
        })
    }
    handleUrlUploaded = (logo) =>{
        this.setState({logo:logo===false?'':logo});
    }
    handleUrlUsersUploaded = (photo) =>{
        this.setState({companyUserPhoto:photo===false?'':photo});
    }
    isInvalid =() =>{
        const {name,mail,address,city,dateCreated,exams,examsLeft,logo,phone, username, lastname, tests, testsLeft} = this.state;
        if(name!==''&&address!==''&&mail!=='' && username!=='' && lastname !=='' &&city!=='' &&dateCreated!==''&&exams!=='' && examsLeft!=='' &&tests!=='' && testsLeft!=='' && logo!=='' && phone!=='' ){
            if(isPhone(phone) && isPhone(exams) && isPhone(examsLeft) && isPhone(tests) && isPhone(testsLeft) && isEmail(mail)  && parseInt(examsLeft)<=parseInt(exams)){
                return false;
            }
            return true;
        }
        return true;
    }
    isUserInvalid =() =>{
        const {companyUserName, companyUserMail, companyUserPhoto ,companyUserPhone ,companyUserLastname} = this.state;
        if(companyUserName!==''&&companyUserMail!==''&&companyUserPhoto!=='' && companyUserPhone!=='' && companyUserLastname !=='' ){
            if(isPhone(companyUserPhone)&& isEmail(companyUserMail) ){
                return false;
            }
            return true;
        }
        return true;
    }
    renderButton = () => {
        const {isLoading,isNew} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea "+(isNew?"crear":'modificar')+" esta empresa?"}
                    buttonText={(isNew?"Agregar":"Actualizar") + " Empresa"}
                    variant="contained"
                    isDisabled={this.isInvalid()}
                    confirmAction={isNew?this.onAddButtonClick:this.onEditButtonClick}
                    className="submitGreen"
                />
            );
        }
        return <CircularProgress />;
    }
    renderUsersButton = () => {
        const {isLoading,isNewUser} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea "+(isNewUser?"crear":'modificar')+" este usuario?"}
                    buttonText={(isNewUser?"Agregar":"Actualizar") + " Usuario"}
                    variant="contained"
                    isDisabled={this.isUserInvalid()}
                    confirmAction={isNewUser?this.onAddUserButtonClick:this.onEditUserButtonClick}
                    className="submitGreen"
                />
            );
        }
        return <CircularProgress />;
    }
    onAddButtonClick = () => {
        this.setState({isLoading:true});
        const {name, username, lastname, mail,address,active,city,exams,examsLeft, tests, testsLeft,time, testsTime,logo,phone,users} = this.state;
        let id = companies.push().key;
        const password =  this.generatePassword(6);
        let newCompany = {
            active,
            id,
            name,
            username,
            lastname,
            mail,
            address,
            city: city.value,
            dateCreated:Date.now(),
            exams,
            time,
            examsLeft,
            tests,
            testsTime,
            testsLeft,
            logo,
            phone,
            users,
            password
        };
        auth.createUserWithEmailAndPassword(mail,password).then(val=>{
            console.log('val: ', val);
            const uid = val.user.uid;
            newCompany.uid = uid;
            companies.child(id).set(newCompany).then(value=>{
                console.log('value: ', value);
                const companyUser = {
                    companyId: id,
                    companyName: name,
                    name: username,
                    lastname,
                    mail,
                    password, 
                    uid,
                    profiles:{admin: true, candidatos: true, cargos: true, reportes: true}
                }
                const sendMail = {
                    companyId: id,
                    companyName: name,
                    name: username,
                    lastname,
                    mail,
                    password, 
                    uid,
                };
                newCompanyRef.push(sendMail).then(v=>{
                    console.log('v: ', v);
                    console.log('sendMail: ', sendMail);
                });
                usersRef.child('companies/'+uid).set(companyUser).then(v => {
                    auth.signOut().then(va=>{
                        auth.signInWithEmailAndPassword(localStorage.getItem('mail'), localStorage.getItem('pass')).then((data)=>{
                            this.setState({isLoading:false,message:"Se ha agregado satisfactoriamente",openSnackbar:true});
                            this.handleGoBack();
                        }).catch(erro=> {
                            console.log('erro: ', erro);
                            this.setState({isLoading:false,message:erro.toString(),openSnackbar:true});    
                        });
                    }).catch(err=> {
                        console.log('err: ', err);
                        this.setState({isLoading:false,message:err.toString(),openSnackbar:true});    
                    });
                }).catch (e=>{
                    console.log('e: ', e);
                    this.setState({isLoading:false,message:e.toString(),openSnackbar:true});    
                });
            }).catch(error=>{
                console.log('error: ', error);
                this.setState({isLoading:false,message:error.toString(),openSnackbar:true});
            });
        }).catch(er=>{
            console.log('er: ', er);
            this.setState({isLoading:false,message:er.toString(),openSnackbar:true});
        });
    }
    onAddUserButtonClick = () => {
        this.setState({isLoading:true});
        const {companyUserActive, companyUserName, companyUserLastname, companyUserMail,companyUserPhone,companyUserPhoto,companyUserProfiles, company} = this.state;
        const password =  this.generatePassword(6);
        const newUser = {
            active: companyUserActive,
            name: companyUserName,
            lastname: companyUserLastname,
            mail: companyUserMail,
            dateCreated:Date.now(),
            photo: companyUserPhoto,
            phone: companyUserPhone,
            profiles: companyUserProfiles,
            password
        }
        auth.createUserWithEmailAndPassword(companyUserMail, password).then(val=>{
            const uid = val.user.uid;
            newUser.uid = uid;
            companies.child(company.id+'/users/'+uid).set(newUser);
            newUser.companyId = company.id;
            newUser.companyName = company.name;
            newUserRef.push(newUser);
            usersRef.child('companies/'+uid).set(newUser);
            auth.signOut().then(va=>{
                auth.signInWithEmailAndPassword(localStorage.getItem('mail'), localStorage.getItem('pass')).then((data)=>{
                    this.setState({isLoading:false,message:"Se ha agregado el usuario satisfactoriamente",openSnackbar:true});
                    this.handleGoBack();
                }).catch(erro=> {
                    console.log('erro: ', erro);
                    this.setState({isLoading:false,message:erro.toString(),openSnackbar:true});    
                })
            }).catch(err=> {
                console.log('err: ', err);
                this.setState({isLoading:false,message:err.toString(),openSnackbar:true});    
            })
        }).catch(er=>{
            console.log('er: ', er);
            this.setState({isLoading:false,message:er.toString(),openSnackbar:true});
        })
    }
    handleCloseSnackbar = () => {
        this.setState({openSnackbar:false,message:''})
    }
    onEditButtonClick = () => {
        this.setState({isLoading:true});
        const {company,name, username, lastname,mail, active,address,city,exams,time,examsLeft, tests, testsTime, testsLeft, logo,phone,users} = this.state;
        let id = company.id;
        companies.child(id+'/active').set(active);
        companies.child(id+'/name').set(name);
        companies.child(id+'/username').set(username);
        companies.child(id+'/lastname').set(lastname);
        companies.child(id+'/mail').set(mail);
        companies.child(id+'/address').set(address);
        companies.child(id+'/city').set(city.value);
        companies.child(id+'/exams').set(exams);
        companies.child(id+'/tests').set(tests);
        companies.child(id+'/testsLeft').set(testsLeft);
        companies.child(id+'/time').set(time);
        companies.child(id+'/testsTime').set(testsTime);
        companies.child(id+'/examsLeft').set(examsLeft);
        companies.child(id+'/logo').set(logo);
        companies.child(id+'/phone').set(phone);
        companies.child(id+'/users').set(users);
        this.setState({isLoading:false,message:"Se ha actualizado satisfactoriamente",openSnackbar:true});
        this.handleGoBack();
    }
    sendPassword = () => {
        this.setState({isLoading:true});
        const {company,name, username, password, lastname,mail, uid, phone} = this.state;
        let id = company.id;
        const sendMail = {
            companyId: id,
            companyName: name,
            name: username,
            lastname,
            mail,
            password, 
            uid,
            phone
        };
        newCompanyRef.push(sendMail).then(v=>{
            console.log('v: ', v);
            console.log('sendMail: ', sendMail);
            this.setState({isLoading:false,message:"Se ha enviado satisfactoriamente",openSnackbar:true});
        });
    }
    sendPasswordWhatsapp = () => {
        const {company,name, username, password, lastname,mail, uid, phone} = this.state;
        let id = company.id;
        const sendMail = {
            companyId: id,
            companyName: name,
            name: username,
            lastname,
            mail,
            password, 
            uid,
            phone
        };

        const textMessage = "Hola "+sendMail.name+",\nEl nuevo usuario para la empresa "+sendMail.companyName+" para usar la plataforma de Confieval es:\nusuario: "+sendMail.mail+"\ncontraseña: "+sendMail.password+"\nPuedes ingresar a la plataforma desde: https://confieval.com";
        const url = 'https://api.whatsapp.com/send?phone=' + sendMail.phone + '&text=' + encodeURIComponent(textMessage);
        var win = window.open(url, '_blank');
        win.focus();
    }

    sendPasswordUserWhatsapp = () => {
        const {companyUserActive, companyUserName, companyUserLastname, companyUserMail,companyUserPhone,companyUserPhoto,companyUserProfiles, passwordUser, company} = this.state;
        const sendMail = {
            active: companyUserActive,
            company: company,
            name: companyUserName,
            lastname: companyUserLastname,
            mail: companyUserMail,
            dateCreated:Date.now(),
            photo: companyUserPhoto,
            phone: companyUserPhone,
            profiles: companyUserProfiles,
            password: passwordUser
        }
        const textMessage = "Hola "+sendMail.name+",\nEl nuevo usuario para usar la plataforma de Confieval es:\nusuario: "+sendMail.mail+"\ncontraseña: "+sendMail.password+"\nPuedes ingresar a la plataforma desde: https://confieval.com";
        const url = 'https://api.whatsapp.com/send?phone=' + sendMail.phone + '&text=' + encodeURIComponent(textMessage);
        var win = window.open(url, '_blank');
        win.focus();
    }
    sendPasswordUser = () => {
        this.setState({isLoading:true});
        const {companyUserActive, companyUserName, companyUserLastname, companyUserMail,companyUserPhone,companyUserPhoto,companyUserProfiles, passwordUser} = this.state;
        const sendMail = {
            active: companyUserActive,
            name: companyUserName,
            lastname: companyUserLastname,
            mail: companyUserMail,
            dateCreated:Date.now(),
            photo: companyUserPhoto,
            phone: companyUserPhone,
            profiles: companyUserProfiles,
            password: passwordUser
        }
        newUserRef.push(sendMail).then(v=>{
            console.log('v: ', v);
            console.log('sendMail: ', sendMail);
            this.setState({isLoading:false,message:"Se ha enviado satisfactoriamente",openSnackbar:true});
        });
    }
    onEditUserButtonClick = () => {
        this.setState({isLoading:true});
        const {company,userDateCreated, companyUserActive, companyUserLastname, companyUserMail, companyUserName, companyUserPassword, companyUserPhone, companyUserPhoto, companyUserProfiles, companyUserUid} = this.state;
        let id = company.id;
        let currentUser = {
            active: companyUserActive,
            uid: companyUserUid,
            name: companyUserName,
            lastname: companyUserLastname,
            mail: companyUserMail,
            password: companyUserPassword,
            dateCreated: userDateCreated,
            phone: companyUserPhone,
            photo: companyUserPhoto,
            profiles: companyUserProfiles,
            companyId: id,
            companyName: company.name
        }
        companies.child(id+'/users/'+companyUserUid).set(currentUser).then(value=>{
            usersRef.child('companies/'+companyUserUid).set(currentUser).then(v=>{
                this.setState({isLoading:false,message:"Se ha actualizado el usuario satisfactoriamente",openSnackbar:true});     
                this.handleGoBack();       
            }).catch(error=>{
                console.log('error: ', error);
                this.setState({isLoading:false,message:error.toString(),openSnackbar:true});
            })
        }).catch(error=>{
            console.log('error: ', error);
            this.setState({isLoading:false,message:error.toString(),openSnackbar:true});
        })
    }
    handleAddNewUser = () => {
        this.setState({
            user: {}, 
            isNewUser: true, 
            companyUserName: '',
            companyUserLastname: '',
            companyUserMail: '',
            userDateCreated: '',
            companyUserPhone:  '',
            companyUserPassword:  '',
            companyUserUid:  '',
            companyUserProfiles: {admin:false, candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: '',
            companyUserActive:false,
        })
    }
    handleDelete = () => {
        const {isNew,company} = this.state;
        if(!isNew &&company){
            this.setState({isLoading:true})
            let id = company.id;
            companies.child(id).remove().then(value=>{
                this.setState({isLoading:false})
                this.handleGoBack();
            }).catch(error=>{
                console.log('error: ', error);
            });            
        }
    }
    handleDeleteUser = () => {
        const {isNewUser,user, company} = this.state;
        if(!isNewUser &&user){
            this.setState({isLoading:true})
            const uid = user.uid;
            const id = company.id;
            companies.child(id+'/users/'+uid).remove().then(value=>{
                usersRef.child('companies/'+uid).remove().then(val=>{
                    this.setState({isLoading:false})
                    this.handleGoBackUser();
                }).catch(e=>{
                    console.log('e: ', e);
                });   
            }).catch(error=>{
                console.log('error: ', error);
            });            
        }
    }
    handleGoBackUser=(e)=>{
        this.setState({
            companyUserName: '',
            companyUserLastname: '',
            companyUserMail: '',
            userDateCreated: '',
            companyUserPhone:  '',
            companyUserPassword:  '',
            companyUserUid:  '',
            companyUserProfiles: {admin:false, candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: '',
            companyUserActive:false,
            user: null,
        })
    }
    onChangeReportesPermission = (e) => {
        const {companyUserProfiles} = this.state;
        companyUserProfiles.reportes = e.target.checked;
        this.setState({companyUserProfiles});
    }
    onChangeCandidatosPermission = (e) => {
        const {companyUserProfiles} = this.state;
        companyUserProfiles.candidatos = e.target.checked;
        this.setState({companyUserProfiles});
    }
    onChangeCargosPermission = (e) => {
        const {companyUserProfiles} = this.state;
        companyUserProfiles.cargos = e.target.checked;
        this.setState({companyUserProfiles});
    }
    onChangeAdminPermission = (e) => {
        const {companyUserProfiles} = this.state;
        companyUserProfiles.admin = e.target.checked;
        this.setState({companyUserProfiles});
    }
    renderUsers = () => {
        const {name,users,user, isNewUser} = this.state;
        if(user!==null){
            return(
                    <div>
                        <Toolbar>
                            <Tooltip title="Atras">
                                <IconButton onClick={this.handleGoBackUser}>
                                    <ArrowBackIos />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" style={{width: 400}}>
                                {isNewUser === true ? 'Nuevo ': 'Editar '} Usuario
                            </Typography>
                            <div className="spacer" />
                            <ConfirmationDialog
                                confirmationText={"¿Está seguro que desea eliminar este usuario?"}
                                buttonText={"Eliminar Usuario"}
                                variant="contained"
                                icon = {<Delete />}
                                isDisabled={false}
                                confirmAction={this.handleDeleteUser}
                                color="secondary"
                            />
                        </Toolbar>
                        <br />
                        <Table style={{width:'100%'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Activo</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.companyUserActive}
                                                    onChange={e=>this.setState({companyUserActive:e.target.checked})}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.companyUserActive===true?'Activo':'Inactivo'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Nombre</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            autoFocus
                                            value={this.state.companyUserName}
                                            onChange={e=>this.setState({companyUserName:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Apellido</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.companyUserLastname}
                                            onChange={e=>this.setState({companyUserLastname:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>      
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Telefono</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.companyUserPhone}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({companyUserPhone:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>  
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Email</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.companyUserMail}
                                            error={this.state.companyUserMail!==''&&!isEmail(this.state.companyUserMail)}
                                            onChange={e=>this.setState({companyUserMail:e.target.value})}
                                            fullWidth
                                            multiline
                                            disabled={this.state.isNewUser === false}
                                        />
                                    </TableCell>
                                </TableRow>                 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Foto</b></TableCell>
                                    <TableCell>
                                            {
                                                this.state.companyUserPhoto &&
                                                    <a href={this.state.companyUserPhoto} rel="noopener noreferrer" target="_blank"><img src={this.state.companyUserPhoto} alt="Foto" style={{width:100, height: 100}}/></a>
                                            }
                                            <Uploader 
                                                dirName={"Usuarios"} 
                                                urlUploaded={this.handleUrlUsersUploaded}
                                            />
                                        </TableCell>
                                </TableRow>     
                                {
                                    !this.state.isNewUser &&
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Reenviar Correo electrónico con información de inicio de sesión</b></TableCell>
                                        <TableCell>
                                            <Button onClick={()=>this.sendPasswordUser()} variant="contained">
                                                Reenviar Correo Electrónico
                                            </Button>
                                        </TableCell>
                                    </TableRow> 
                                }
                                {
                                    !this.state.isNewUser &&
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Enviar mensaje de whatsapp con información de inicio de sesión</b></TableCell>
                                        <TableCell>
                                            <Button onClick={()=>this.sendPasswordUserWhatsapp()} variant="contained">
                                                Enviar Whatsapp
                                            </Button>
                                        </TableCell>
                                    </TableRow> 
                                }
                            </TableBody>
                        </Table>
                        <br />
                        <Toolbar>
                            <Typography variant="h6" style={{width:400}}>
                                Permisos de usuario
                            </Typography>
                        </Toolbar>
                        <Table style={{width:'100%'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Administrador de Empresa</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.companyUserProfiles.admin}
                                                    onChange={this.onChangeAdminPermission}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.companyUserProfiles.cargos===true?'Activo':'Inactivo'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Administración de cargos</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.companyUserProfiles.cargos}
                                                    onChange={this.onChangeCargosPermission}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.companyUserProfiles.cargos===true?'Activo':'Inactivo'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Administración de candidatos</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.companyUserProfiles.candidatos}
                                                    onChange={this.onChangeCandidatosPermission}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.companyUserProfiles.candidatos===true?'Activo':'Inactivo'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Generación de reportes</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.companyUserProfiles.reportes}
                                                    onChange={this.onChangeReportesPermission}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.companyUserProfiles.reportes===true?'Activo':'Inactivo'}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                        <div className={"submitContainer"}>
                            {this.renderUsersButton()}
                        </div>
                    </div>
            )
        }
        return(
            <div>
                <Toolbar>
                    <Tooltip title="Atras">
                        <IconButton onClick={this.handleGoBack}>
                            <ArrowBackIos />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="h6" style={{width:400}}>
                        {"Usuarios " + name}
                    </Typography>
                    <div className="spacer" />
                    <Tooltip title="Nuevo usuario">
                        <IconButton onClick={this.handleAddNewUser}>
                            <AddCircle style={{color: '#4caf50'}}/>
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                {
                    users&&
                    <ReactTable 
                        data={Object.values(users)}
                        defaultPageSize={10}
                        previousText= 'Anterior'
                        nextText= 'Siguiente'
                        loadingText= 'Cargando...'
                        noDataText= 'No hay datos'
                        pageText= 'Página'
                        ofText= 'de'
                        rowsText= 'Filas'
                        pageJumpText= 'Ir a página'
                        rowsSelectorText= 'filas por página'
                        filterable 
                        defaultFilterMethod={(filter, row) =>String(row[filter.id]).toLowerCase().includes( filter.value.toLowerCase())}
                        columns={[
                        {
                            columns: [
                            {
                                Header:'Foto',
                                accessor:'photo',
                                Cell: row => (
                                    <a href={row.value} target="_blank" rel="noopener noreferrer"><img src={row.value} alt="logo" style={{width:50, height: 50}}/></a>
                                ),
                                width:70,
                            },
                            {
                                Header: "Nombre",
                                accessor: "name",
                                width:150,
                            },
                            {
                                Header: "Apellido",
                                accessor: "lastname",
                                width:150,
                            },
                            {
                                Header:"Email",
                                accessor:"mail",
                                width:350,
                                Cell: row=>(
                                    <a href={"mailto:"+row.value}>{row.value}</a>
                                )
                            },
                            {
                                Header: "Telefono",
                                accessor: "phone",
                                width:130,
                            },
                            {
                                Header:'Permisos',
                                accessor:'permissions',
                                width:85,
                                Cell: row => (
                                    <Tooltip title={"Permisos de "+row.original.name}>
                                        <IconButton onClick={()=>this.handleDetailPermissions(row.original)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        ]
                        }
                        ]}
                        className={"-striped -highlight table"}
                    />
                }   
            </div>
        )
    }
    handleDetailPermissions = (user) => {
        this.setState({
            user,
            passwordUser : user.password ? user.password : '',
            companyUserName: user.name?user.name:'',
            companyUserLastname: user.lastname?user.lastname:'',
            companyUserMail: user.mail?user.mail:'',
            userDateCreated: user.dateCreated?user.dateCreated:Date.now(),
            companyUserPhone: user.phone?user.phone: '',
            companyUserPassword: user.password? user.password: '',
            companyUserUid: user.uid? user.uid: '',
            companyUserProfiles: user.profiles?user.profiles : {admin:false, candidatos: false, cargos: false, reportes: false},
            companyUserPhoto: user.photo?user.photo:'',
            companyUserActive: user.active?user.active:false
        })
    }
    renderCompany = () =>{
        const {isNew,name,openSnackbar,message,handleUsers, ciudades} = this.state;
        const ciudadesMap = ciudades.length>0 && ciudades.map((item,index)=> {
            return {
                value: item.city,
                label: item.city
            }
        });
        return(
            <Paper style={{width:'100%'}}>
                {
                    handleUsers? this.renderUsers()
                    :
                    <div>
                        <Toolbar>
                            <Tooltip title="Atras">
                                <IconButton onClick={this.handleGoBack}>
                                    <ArrowBackIos />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" style={{width:400}}>
                                {isNew?'Nueva empresa':name}
                            </Typography>
                            {
                                !isNew &&
                                    <div className="spacer" />
                            }
                            {
                                !isNew&&
                                    <ConfirmationDialog
                                        confirmationText={"¿Está seguro que desea eliminar esta empresa?"}
                                        buttonText={"Eliminar Empresa"}
                                        variant="contained"
                                        icon = {<Delete />}
                                        isDisabled={false}
                                        confirmAction={this.handleDelete}
                                        color="secondary"
                                    />
                            }
                        </Toolbar>
                        <Table style={{width:'100%'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Activa</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.active}
                                                    onChange={e=>this.setState({active:e.target.checked})}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.active===true?'Activo':'Inactivo'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Nombre</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            autoFocus
                                            value={this.state.name}
                                            onChange={e=>this.setState({name:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Nombre Representante</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.username}
                                            onChange={e=>this.setState({username:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Apellido Representante</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.lastname}
                                            onChange={e=>this.setState({lastname:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                {
                                    !isNew&&
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Usuarios</b></TableCell>
                                        <TableCell>
                                            <Button
                                                type="button"
                                                className={"submit"}
                                                variant="contained"
                                                disabled={isNew}
                                                onClick={()=>this.setState({handleUsers:true})}
                                            >
                                                Administrar Usuarios
                                            </Button>
                                        </TableCell>
                                    </TableRow>     
                                }            
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Telefono</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.phone}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({phone:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>  
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Email</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.mail}
                                            error={this.state.mail!==''&&!isEmail(this.state.mail)}
                                            onChange={e=>this.setState({mail:e.target.value})}
                                            fullWidth
                                            disabled={this.state.isNew === false}
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>                 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Dirección</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.address}
                                            onChange={e=>this.setState({address:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Ciudad</b></TableCell>
                                    <TableCell>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={this.state.city}
                                            defaultValue={this.state.city}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="Ciudad"
                                            options={ciudadesMap}
                                            onChange={e=>this.setState({city: e})}
                                            fullWidth
                                            placeholder="Ciudad..."
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Pruebas</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.exams}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({exams:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Pruebas restantes</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.examsLeft}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({examsLeft:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Evaluaciones Psicologicas Forenses</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.tests}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({tests:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Evaluaciones Psicologicas Forenses restantes</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.testsLeft}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({testsLeft:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Logo</b></TableCell>
                                    <TableCell>
                                            {
                                                this.state.logo &&
                                                    <a href={this.state.logo} rel="noopener noreferrer" target="_blank"><img src={this.state.logo} alt="Logo" style={{width:100, height:100}}/></a>
                                            }
                                            <Uploader 
                                                dirName={"Empresas"} 
                                                urlUploaded={this.handleUrlUploaded}
                                            />
                                        </TableCell>
                                </TableRow>  
                                {
                                    !this.state.isNew &&
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Reenviar Correo electrónico con información de inicio de sesión</b></TableCell>
                                        <TableCell>
                                            <Button onClick={()=>this.sendPassword()} variant="contained">
                                                Reenviar Correo Electrónico
                                            </Button>
                                        </TableCell>
                                    </TableRow> 
                                }
                                {
                                    !this.state.isNew &&
                                    <TableRow>
                                        <TableCell className={"tableRowTitle"}><b>Enviar información de inicio de sesión por Whatsapp</b></TableCell>
                                        <TableCell>
                                            <Button onClick={()=>this.sendPasswordWhatsapp()} variant="contained">
                                                Enviar Whatsapp
                                            </Button>
                                        </TableCell>
                                    </TableRow> 
                                }
                            </TableBody>
                        </Table>
                        <div className={"submitContainer"}>
                            {this.renderButton()}
                        </div>
                    </div>
                }
                <Snackbar
                    onClose={this.handleCloseSnackbar}
                    open={openSnackbar}
                    autoHideDuration={5000}
                    message={<span>{message}</span>}
                />
            </Paper>
        )
    }

    renderLoader = () => {
        const {isLoading} = this.state;
        if (isLoading) {
            return (
                <div id="overlay">
                    <CircularProgress
                        color="secondary"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                </div>
            )
        }
        return null;
    }

    render(){
        const {company,companies,title} = this.state;
        return (
            <div className={"tableContainer"}>
            {this.renderLoader()}
            {
                company===null?
                <Paper>
                    <Toolbar>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                        <div className={"spacer"} />
                        <Tooltip title="Nueva empresa">
                            <IconButton onClick={this.handleAddNew}>
                                <AddCircle style={{color: '#4caf50'}}/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    <ReactTable 
                        data={companies}
                        defaultPageSize={10}
                        previousText= 'Anterior'
                        nextText= 'Siguiente'
                        loadingText= 'Cargando...'
                        noDataText= 'No hay datos'
                        pageText= 'Página'
                        ofText= 'de'
                        rowsText= 'Filas'
                        pageJumpText= 'Ir a página'
                        rowsSelectorText= 'filas por página'
                        filterable 
                        defaultFilterMethod={(filter, row) =>String(row[filter.id]).toLowerCase().includes( filter.value.toLowerCase())}
                        columns={[
                        {
                            columns: [
                            {
                                Header:'Logo',
                                accessor:'logo',
                                Cell: row => (
                                    <a href={row.value} target="_blank" rel="noopener noreferrer"><img src={row.value} alt="logo" style={{width:50}}/></a>
                                ),
                                width:70,
                            },
                            {
                                Header: "Nombre",
                                accessor: "name",
                                width:150,
                            },
                            {
                                Header: "Pruebas",
                                accessor: "exams",
                                width:75,
                            },
                            {
                                Header: "P. Restantes",
                                accessor: "examsLeft",
                                width:80,
                            },
                            {
                                Header: "Evaluaciones",
                                accessor: "tests",
                                width:75,
                            },
                            {
                                Header: "E. Restantes",
                                accessor: "testsLeft",
                                width:80,
                            },
                            {
                                Header: "Ciudad",
                                accessor: "city",
                                width:90
                            },
                            {
                                Header:"Email",
                                accessor:"mail",
                                Cell: row=>(
                                    <a href={"mailto:"+row.value}>{row.value}</a>
                                )
                            },
                            {
                                Header: "Telefono",
                                accessor: "phone",
                                width:140,
                            },
                            {
                                Header: "Dirección",
                                accessor: "address",
                            },
                            {
                                Header:'Cargos',
                                accessor:'uid',
                                width:75,
                                Cell: row => (
                                    <Tooltip title={"Cargos de "+row.original.name}>
                                    <Link to={'/cargos/'+row.value}>
                                        <IconButton >
                                            <Person />
                                        </IconButton>
                                    </Link>
                                    </Tooltip>
                                )
                            },
                            {
                                Header:'Candidatos',
                                accessor:'uid',
                                width:95,
                                Cell: row => (
                                    <Tooltip title={"Candidatos de "+row.original.name}>
                                    <Link to={'/candidatos/'+row.value}>
                                        <IconButton >
                                            <AccountBox />
                                        </IconButton>
                                    </Link>
                                    </Tooltip>
                                )
                            },
                            {
                                Header:'Usuarios',
                                accessor:'users',
                                width:75,
                                Cell: row => (
                                    <Tooltip title={"Usuarios de "+row.original.name}>
                                    <IconButton onClick={()=>this.handleDetail(row.original,true)}>
                                            <People />
                                        </IconButton>
                                    </Tooltip>
                                )
                            },
                            {
                                Header:'Detalle',
                                accessor:'name',
                                width:65,
                                Cell: row => (
                                    <Tooltip title={"Detalle de "+row.value}>
                                        <IconButton onClick={()=>this.handleDetail(row.original,false)}>
                                            <Edit />
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        ]
                        }
                        ]}
                        className={"-striped -highlight table"}

                    />
                </Paper>
            :
                this.renderCompany()
            }
        </div>
        )
    }
}