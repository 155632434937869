import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
  palette: {
    primary: {
      main:'#ffffff'
    },
    secondary: {
      main: '#db4a4a',
    },
    success: {
      main: '#4caf50',
    },
  },
  typography: {
    useNextVariants: true,
    fontFamily: [
      'Poppins',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#db4a4a',
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#db4a4a !important',
      },
      "&$selected": {
        backgroundColor: '#db4a4a !important',
      },
      isSelected: {
        backgroundColor: '#db4a4a !important',
      }
    },
    MuiPickersYear: {
      yearSelected: {
        color: '#db4a4a !important',
      },
      "&$selected": {
        color: '#db4a4a !important',
      }
    },
    MuiPickersMonth: {
      monthSelected: {
        color: '#db4a4a !important',
      },
      "&$selected": {
        color: '#db4a4a !important',
      }
    },
  },
});