import React,{Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import ExitIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import logo from '../../assets/logo.png';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import { logout } from '../../utils/controller';

export default class PageTitle extends Component {
    constructor(props){
        super(props);
        this.state={}
    }

    logoutFunction = () => {
        logout();
        console.log('logged out');
        window.location.href = "/login";
    }

    render(){
        return(
            <AppBar
                position="absolute"
                className={classNames('appBar', this.props.open && 'appBarShift')}
            >
                <Toolbar disableGutters={!this.props.open} className={'toolbar'}>
                    <IconButton
                        color="inherit"
                        onClick={this.props.handleDrawerOpen}
                        className={classNames(
                            'menuButton',
                            this.props.open && 'menuButtonHidden',
                        )}
                    >
                        <MenuIcon />    
                    </IconButton>
                    <Link to="/" style={{color: '#fff', textDecoration: 'none'}}>
                        <img src={logo} alt="logo" className={"logoAppBar"}/>
                    </Link>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={"title"}
                    >
                        {this.props.title}
                    </Typography>
                    {
                        localStorage.uid &&
                        
                            <IconButton
                                color="inherit"
                                onClick={() => this.logoutFunction()}
                            >
                                <ExitIcon />
                            </IconButton>
                        
                    }
                </Toolbar>
            </AppBar>
        )
    }
}