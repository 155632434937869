import React, {Component} from 'react';
import 'react-table/react-table.css';
import Select from 'react-select';
import ReactTable from "react-table";
import {isAdmin, getCompanies,getUsers, newCandidate, newCandidateLink, getCiudades, calculateReport, companies, resultColorCustomFunction} from '../utils/controller';
import ReactExport from "react-data-export";
import ConfirmationDialog from './common/confirmationDialog';
import Uploader from './common/uploader';
import IconButton from '@material-ui/core/IconButton';
import {InlineDatePicker} from "material-ui-pickers";
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import CircularProgress from '@material-ui/core/CircularProgress';
import Table from '@material-ui/core/Table';
import TextField from '@material-ui/core/TextField';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import Mail from '@material-ui/icons/Mail';
import Chat from '@material-ui/icons/Chat';
import Done from '@material-ui/icons/Done';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Cancel from '@material-ui/icons/Cancel';
import CloudDownload from '@material-ui/icons/CloudDownload';
import CloudUpload from '@material-ui/icons/CloudUpload';
import ListAltIcon from '@material-ui/icons/ListAlt';
import AddCircle from '@material-ui/icons/AddCircle';
import Delete from '@material-ui/icons/Delete';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import {Link} from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { isPhone,isEmail,idiomasList,paisesList } from '../utils';
import { MenuItem, Select as SelectM } from '@material-ui/core';
import moment from 'moment';
import {ExcelRenderer} from 'react-excel-renderer';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


export default class Candidates extends Component {
    constructor(props){
        super(props);
        this.state={
            isAdmin:false,
            isLoading:true,
            message:'',
            noPermission: false,
            candidatePermission: false,
            reportsPermission: false,
            openSnackbar:false,
            companyName:'',
            currentUser:null,
            company:null,
            isNew:false,
            title:'Candidatos',
            candidate:null,
            active:true,
            activeEPF:true,
            ciudades:[],
            cargos:[],
            ci:'',
            id:'',
            personaRegistrante:'',
            idioma:'Español (Ecuador)',
            cargo:'',
            nombre:'',
            segundoNombre:'',
            primerApellido:'',
            segundoApellido:'',
            pais:'Ecuador',
            birthDate:'1990-01-01',
            sexo:'F',
            honestyShield:true,
            testHonestyShield:true,
            address:'',
            city:'',
            foto:'',
            mail:'',
            phone:'593',
            estadoCivil:'',
            hijos:0,
            antecedentesPenales:'',
            antecedentesPenalesURL:'',
            antecedentesPoliciales:'',
            antecedentesPolicialesURL:'',
            antecedentesMedicos:'',
            antecedentesMedicosURL:'',
            medicacion:'',
            dosis:'',
            poligrafosAnteriores:false,
            poligrafosAnterioresURL:'',
            informacionAdicional:'',
            answers : [],
            dateFinished: 0,
            dateStarted: 0,
            started: false,
            finished: false,
            testStarted: false,
            testFinished: false,
            answersTest : [],
            dateTestFinished: 0,
            dateTestStarted: 0,
            password: '',
            honestyShieldPictures: [],
            testHonestyShieldPictures: [],
        }
    }
    async componentDidMount(){
        await this.handleMainFunction();
    }

    handleMainFunction = async() => {
        this.setState({isLoading: true});
        let uid = localStorage.uid;
        if(this.props.match.params) {
            if(this.props.match.params.uid){
                uid = this.props.match.params.uid;            
            }
        }
        const ciudadesObject = await getCiudades();
        const ciudades = Object.values(ciudadesObject);
        this.setState({ciudades});
        const usersObject = await getUsers();
        const companiesFromUsers = Object.values(usersObject.companies);
        const companiesObject = await getCompanies();
        const allCompanies = Object.values(companiesObject);
        let company = companiesFromUsers.filter(obj=> obj.uid === uid)[0];
        if (company) {
            if (
                company.profiles.admin === true || 
                company.profiles.candidatos === true || 
                company.profiles.reportes === true
            ) {
                let companyName = company.companyName;
                let personaRegistrante = company.name + " "+ company.lastname;
                let currentCompany = allCompanies.filter(obj=>company.companyId===obj.id)[0];
                let cargos = currentCompany.profiles?Object.values(currentCompany.profiles):[];
                let candidatesPre = currentCompany.candidates?Object.values(currentCompany.candidates):[];
                const candidates = [];
                for (let i = 0; i < candidatesPre.length; i+= 1) {
                    const candidate = candidatesPre[i];
                    if (candidate.answers) {
                        const reportObject = await calculateReport(candidate.answers);
                        candidate.reportObject = reportObject;
                    }
                    candidates.push(candidate);
                }
                this.setState({
                    companyName, 
                    company:currentCompany,
                    isLoading:false,
                    isAdmin: company.profiles ? company.profiles.admin : false,
                    personaRegistrante,
                    cargos,
                    candidates,
                    currentUser: company, 
                    candidatePermission: company.profiles.candidatos, 
                    reportsPermission: company.profiles.reportes
                });
            } else {
                this.setState({noPermission: true})
            }
        } else {
            this.setState({noPermission: true})
        }
    }

    handleGoBack = () => {
        this.setState({
            isNew:false,
            title:'Candidatos',
            candidate:null,
            active:true,
            activeEPF:true,
            ci:'',
            idioma:'Español (Ecuador)',
            cargo:'',
            nombre:'',
            segundoNombre:'',
            personaRegistrante:this.state.currentUser.name + " " + this.state.currentUser.lastname,
            primerApellido:'',
            segundoApellido:'',
            pais:'Ecuador',
            birthDate:'1990-01-01',
            sexo:'F',
            honestyShield:true,
            testHonestyShield:true,
            address:'',
            city:'',
            foto:'',
            id:'',
            mail:'',
            phone:'593',
            estadoCivil:'',
            hijos:0,
            antecedentesPenales:'',
            antecedentesPenalesURL:'',
            antecedentesPoliciales:'',
            antecedentesPolicialesURL:'',
            antecedentesMedicos:'',
            antecedentesMedicosURL:'',
            medicacion:'',
            dosis:'',
            poligrafosAnteriores:false,
            poligrafosAnterioresURL:'',
            informacionAdicional:'',
            answers : [],
            dateFinished: 0,
            dateStarted: 0,
            started: false,
            finished: false,
            testStarted: false,
            testFinished: false,
            answersTest : [],
            dateTestFinished: 0,
            dateTestStarted: 0,
            password: '',
            honestyShieldPictures: [],
            testHonestyShieldPictures: []
        });
        this.handleMainFunction();
    }
    handleAddNew = () =>{
        this.setState({
            isNew:true,
            candidate:{},
            title:'Candidatos',
            active:true,
            activeEPF:true,
            ci:'',
            idioma:'Español (Ecuador)',
            cargo:'',
            personaRegistrante:this.state.currentUser ? this.state.currentUser.name + " " + this.state.currentUser.lastname : '',
            nombre:'',
            segundoNombre:'',
            primerApellido:'',
            segundoApellido:'',
            pais:'Ecuador',
            birthDate:'1990-01-01',
            sexo:'F',
            id:'',
            honestyShield:true,
            testHonestyShield:true,
            address:'',
            city:'',
            foto:'',
            mail:'',
            phone:'593',
            estadoCivil:'',
            hijos:0,
            antecedentesPenales:'',
            antecedentesPenalesURL:'',
            antecedentesPoliciales:'',
            antecedentesPolicialesURL:'',
            antecedentesMedicos:'',
            antecedentesMedicosURL:'',
            medicacion:'',
            dosis:'',
            poligrafosAnteriores:false,
            poligrafosAnterioresURL:'',
            informacionAdicional:'',
            password: '',
            answers : [],
            dateFinished: 0,
            dateStarted: 0,
            started: false,
            finished: false,
            testFinished: false,
            testStarted: false,
            answersTest : [],
            dateTestFinished: 0,
            dateTestStarted: 0,
            honestyShieldPictures: [],
            testHonestyShieldPictures: []
        });
    }
    handleDetail = (candidate) => {
        this.setState({
            isNew:false,
            candidate,
            active:candidate.active?candidate.active:false,
            activeEPF:candidate.activeEPF?candidate.activeEPF:false,
            nombre:candidate.nombre?candidate.nombre:'',
            segundoNombre:candidate.segundoNombre?candidate.segundoNombre:'',
            address:candidate.address?candidate.address:'',
            city: candidate.city?{value: candidate.city, label: candidate.city} :'',
            personaRegistrante:candidate.personaRegistrante?candidate.personaRegistrante:this.state.currentUser.name + " " + this.state.currentUser.lastname,
            foto:candidate.foto?candidate.foto:'',
            mail:candidate.mail?candidate.mail:'',
            phone:candidate.phone?candidate.phone:'593',
            ci:candidate.ci?candidate.ci:'',
            id:candidate.id?candidate.id:'',
            idioma:candidate.idioma?candidate.idioma:'Español (Ecuador)',
            cargo:candidate.cargo?candidate.cargo.name ? candidate.cargo.name : candidate.cargo :'',
            primerApellido:candidate.primerApellido?candidate.primerApellido:'',
            segundoApellido:candidate.segundoApellido?candidate.segundoApellido:'',
            pais:candidate.pais?candidate.pais:'Ecuador',
            birthDate:candidate.birthDate?candidate.birthDate:'1990-01-01',
            sexo:candidate.sexo?candidate.sexo:'F',
            honestyShield:candidate.honestyShield?candidate.honestyShield:true,
            testHonestyShield:candidate.testHonestyShield?candidate.testHonestyShield:true,
            estadoCivil:candidate.estadoCivil?candidate.estadoCivil:'',
            hijos:candidate.hijos?candidate.hijos:0,
            antecedentesPenales:candidate.antecedentesPenales?candidate.antecedentesPenales:'',
            antecedentesPenalesURL:candidate.antecedentesPenalesURL?candidate.antecedentesPenalesURL:'',
            antecedentesPoliciales:candidate.antecedentesPoliciales?candidate.antecedentesPoliciales:'',
            antecedentesPolicialesURL:candidate.antecedentesPolicialesURL?candidate.antecedentesPolicialesURL:'',
            antecedentesMedicos:candidate.antecedentesMedicos?candidate.antecedentesMedicos:'',
            antecedentesMedicosURL:candidate.antecedentesMedicosURL?candidate.antecedentesMedicosURL:'',
            medicacion:candidate.medicacion?candidate.medicacion:'',
            dosis:candidate.dosis?candidate.dosis:'',
            poligrafosAnteriores:candidate.poligrafosAnteriores?candidate.poligrafosAnteriores:false,
            poligrafosAnterioresURL:candidate.poligrafosAnterioresURL?candidate.poligrafosAnterioresURL:'',
            informacionAdicional:candidate.informacionAdicional?candidate.informacionAdicional:'',
            password : candidate.password ? candidate.password: '',
            answers : candidate.answers ? candidate.answers: {},
            dateFinished: candidate.dateFinished? candidate.dateFinished: 0,
            dateStarted: candidate.dateStarted ? candidate.dateStarted : 0,
            started: candidate.started ? candidate.started : false,
            testStarted: candidate.testStarted ? candidate.testStarted : false,
            finished: candidate.finished ? candidate.finished : false,
            testFinished: candidate.testFinished ? candidate.testFinished : false,
            answersTest : candidate.answersTest ? candidate.answersTest : [],
            dateTestFinished: candidate.dateTestFinished ? candidate.dateTestFinished : 0,
            dateTestStarted: candidate.dateTestStarted ? candidate.dateTestStarted : 0,
            honestyShieldPictures: candidate.honestyShieldPictures? candidate.honestyShieldPictures : [],
            testHonestyShieldPictures: candidate.testHonestyShieldPictures? candidate.testHonestyShieldPictures : []
        })
    }
    handleUrlUploaded = (foto) =>{
        this.setState({foto:foto===false?'':foto});
    }
    handleUrlUploadedAntecedentesPenalesURL = (antecedentesPenalesURL) =>{
        this.setState({antecedentesPenalesURL:antecedentesPenalesURL===false?'':antecedentesPenalesURL});
    }
    handleUrlUploadedAntecedentesMedicosURL = (antecedentesMedicosURL) =>{
        this.setState({antecedentesMedicosURL:antecedentesMedicosURL===false?'':antecedentesMedicosURL});
    }
    isInvalid =() =>{
        const {nombre, mail, cargo, primerApellido, ci} = this.state;
        if (nombre !== '' && mail !== '' && cargo !== '' && primerApellido !== '' && ci !== '') {
            if (isEmail(mail)) {
                return false;
            }
            /* if (phone !== '593') {
                if(isPhone(phone) && isEmail(mail)){
                    return false;
                }
            }*/
        }
        return true;
    }

    handleOnPressSubmitButton = () => {
        const {isNew, mail} = this.state;
        if (isNew) {
            const isValidEmail = this.handleValidateMail(mail);
            if (isValidEmail === false) {
                alert('El correo electrónico ingresado ya está siendo usado por otro candidato de esta empresa');
                return null;
            }
            return this.onAddButtonClick();
        } else {
            return this.onEditButtonClick();
        }

    }
    renderButton = () => {
        const {isLoading, isNew} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea "+(isNew?"crear":'modificar')+" este candidato?"}
                    buttonText={(isNew?"Agregar":"Actualizar") + " Candidato"}
                    variant="contained"
                    isDisabled={this.isInvalid()}
                    confirmAction={this.handleOnPressSubmitButton}
                    className="submitGreen"
                />
            );
        }
        return <CircularProgress />;
    }
    renderButtonEmptyTest = () => {
        const {isLoading} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea resetear la Evaluación de Perfilamiento Psicológico de este candidato?"}
                    buttonText={"Vaciar prueba EPP"}
                    variant="text"
                    isDisabled={false}
                    confirmAction={this.handleResetTest}
                    theme="secondary"
                />
            );
        }
        return <CircularProgress />;
    }
    renderButtonEmptyTest_ = () => {
        const {isLoading} = this.state;
        if (!isLoading){
            return(
                <ConfirmationDialog 
                    confirmationText={"¿Está seguro que desea resetear la Evaluación Psicológica Forense de este candidato?"}
                    buttonText={"Vaciar prueba EPF"}
                    variant="text"
                    isDisabled={false}
                    confirmAction={this.handleResetTestEvaluation}
                    theme="secondary"
                />
            );
        }
        return <CircularProgress />;
    }

    handleValidateMail = (mail) => {
        const {candidates} = this.state;
        const exists = candidates.filter(obj => obj.mail === mail);
        if (exists[0]) {
            return false;
        }
        return true;
    }
    onAddButtonClick = () => {
        this.setState({isLoading:true});
        const {
            nombre,
            segundoNombre,
            mail,
            address,
            active,
            activeEPF,
            city,
            foto,
            phone,
            company,
            personaRegistrante,
            ci,
            cargo,
            cargos,
            idioma,
            primerApellido,
            segundoApellido,
            honestyShield,
            testHonestyShield,
            honestyShieldPictures,
            testHonestyShieldPictures,
            birthDate,
            sexo,
            pais,
            estadoCivil,
            hijos,
            antecedentesPenales,
            antecedentesPenalesURL,
            antecedentesMedicos,
            medicacion,
            antecedentesMedicosURL,
            poligrafosAnteriores,
            poligrafosAnterioresURL,
            informacionAdicional
        } = this.state;
        let id =  companies.child(company.id).child("candidates").push().key;
        const cargoObject = cargos.filter(obj => obj.name === cargo)[0];
        let password = this.generatePassword(6);
        let newCandidateToAdd = {
            active,
            activeEPF,
            id,
            nombre,
            segundoNombre,
            mail,
            address,
            city: city ? city.value || '' : '',
            foto,
            phone,
            personaRegistrante,
            ci,
            cargo: cargoObject,
            idioma,
            primerApellido,
            segundoApellido,
            birthDate,
            sexo,
            pais,
            estadoCivil,
            honestyShield,
            testHonestyShield,
            honestyShieldPictures,
            testHonestyShieldPictures,
            hijos,
            antecedentesPenales,
            antecedentesPenalesURL,
            medicacion,
            antecedentesMedicos,
            antecedentesMedicosURL,
            poligrafosAnteriores,
            poligrafosAnterioresURL,
            informacionAdicional,
            password
        };
        companies.child(company.id).child("candidates").child(id).set(newCandidateToAdd).then(value=>{
            const sendEmailToCandidate = {
                candidateUser: mail,
                candidatePassword: password,
                companyName: company.name,
                companyId: company.id
            }
            newCandidate.push(sendEmailToCandidate);
            this.setState({isLoading:false,message:"Se ha agregado satisfactoriamente",openSnackbar:true});
            this.handleGoBack();
        }).catch(error=>{
            console.log('error: ', error);
            this.setState({isLoading:false,message:error.toString(),openSnackbar:true});
        })
    }
    generatePassword = (length) => {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        
        for (var i = 0; i < length; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        
        return text;
    }
    handleCloseSnackbar = () => {
        this.setState({openSnackbar:false,message:''})
    }
    onEditButtonClick = () => {
        this.setState({isLoading:true});
        const {
            nombre,
            segundoNombre,
            mail,
            address,
            active,
            activeEPF,
            city,
            foto,
            phone,
            company,
            personaRegistrante,
            ci,
            id,
            cargo,
            cargos,
            idioma,
            primerApellido,
            segundoApellido,
            birthDate,
            sexo,
            pais,
            estadoCivil,
            hijos,
            password,
            dateFinished,
            dateStarted,
            started,
            testStarted,
            finished,
            testFinished,
            answersTest,
            dateTestFinished,
            dateTestStarted,
            honestyShield,
            testHonestyShield,
            honestyShieldPictures,
            testHonestyShieldPictures,
            answers,
            antecedentesPenales,
            antecedentesPenalesURL,
            medicacion,
            antecedentesMedicos,
            antecedentesMedicosURL,
            poligrafosAnteriores,
            poligrafosAnterioresURL,
            informacionAdicional
        } = this.state;
        let place = company.id+"/candidates/"+id;
        const cargoObject = cargos.filter(obj => obj.name === cargo)[0];
        companies.child(place+'/active').set(active);
        companies.child(place+'/activeEPF').set(activeEPF);
        companies.child(place+'/nombre').set(nombre);
        companies.child(place+'/segundoNombre').set(segundoNombre);
        companies.child(place+'/mail').set(mail);
        companies.child(place+'/address').set(address);
        companies.child(place+'/city').set(city.value);
        companies.child(place+'/foto').set(foto);
        companies.child(place+'/personaRegistrante').set(personaRegistrante);
        companies.child(place+'/ci').set(ci);
        companies.child(place+'/cargo').set(cargoObject);
        companies.child(place+'/idioma').set(idioma);
        companies.child(place+'/primerApellido').set(primerApellido);
        companies.child(place+'/segundoApellido').set(segundoApellido);
        companies.child(place+'/birthDate').set(birthDate);
        companies.child(place+'/sexo').set(sexo);
        companies.child(place+'/pais').set(pais);
        companies.child(place+'/estadoCivil').set(estadoCivil);
        companies.child(place+'/hijos').set(hijos);
        companies.child(place+'/dateFinished').set(dateFinished);
        companies.child(place+'/dateStarted').set(dateStarted);
        companies.child(place+'/honestyShield').set(honestyShield);
        companies.child(place+'/testHonestyShield').set(testHonestyShield);
        companies.child(place+'/honestyShieldPictures').set(honestyShieldPictures);
        companies.child(place+'/testHonestyShieldPictures').set(testHonestyShieldPictures);
        companies.child(place+'/password').set(password);
        companies.child(place+'/answers').set(answers);
        companies.child(place+'/antecedentesPenales').set(antecedentesPenales);
        companies.child(place+'/antecedentesPenalesURL').set(antecedentesPenalesURL);
        companies.child(place+'/antecedentesMedicos').set(antecedentesMedicos);
        companies.child(place+'/medicacion').set(medicacion);
        companies.child(place+'/antecedentesMedicosURL').set(antecedentesMedicosURL);
        companies.child(place+'/poligrafosAnteriores').set(poligrafosAnteriores);
        companies.child(place+'/poligrafosAnterioresURL').set(poligrafosAnterioresURL);
        companies.child(place+'/informacionAdicional').set(informacionAdicional);
        companies.child(place+'/phone').set(phone);
        companies.child(place+'/started').set(started);
        companies.child(place+'/testStarted').set(testStarted);
        companies.child(place+'/finished').set(finished);
        companies.child(place+'/testFinished').set(testFinished);
        companies.child(place+'/answersTest').set(answersTest);
        companies.child(place+'/dateTestFinished').set(dateTestFinished);
        companies.child(place+'/dateTestStarted').set(dateTestStarted);

        this.setState({isLoading:false});
        this.handleGoBack();
    }
    handleChangeBirthDate = (birthDate) => {
        this.setState({birthDate:moment(birthDate).format('YYYY-MM-DD')});
    }
    handleDelete = () => {
        const {isNew,company,id} = this.state;
        if(!isNew &&company){
            this.setState({isLoading:true})
            companies.child(company.id+'/candidates/'+id).remove().then(value=>{
                this.setState({isLoading:false})
                this.handleGoBack();
            }).catch(error=>{
                console.log('error: ', error);
            });            
        }
    }
    handleListSendEmail = (candidate, isLink = false) => {
        this.sendPassword(candidate.mail, candidate.password, isLink);
    }
    handleListSendWhatsapp = (candidate, isLink = false) => {
        this.sendPasswordWhatsapp(candidate.mail, candidate.password, candidate.phone, isLink);
    }
    handleCalculateCargoAverage = (cargo) => {
        const {cargos} = this.state;
        const cargoCopy = cargos.map(obj => {
            return JSON.parse(JSON.stringify(obj));
        })
        const cargoObject = cargoCopy.filter(obj => {
            if (cargo) {
                if (cargo.name) {
                    if (obj.name === cargo.name) {
                        return true;
                    }
                }
            }
            return false;
        })[0];
        if (cargoObject) {
            const {valores} = cargoObject;
            delete valores.Paz;
            const valoresValues = Object.values(valores).map(obj => obj.val);
            const average = valoresValues.reduce(function (avg, value, _, { length }) {
                return avg + value / length;
            }, 0);
            return parseInt(average);
        }
        return 0;
    }
    handleAddCargoObjectToCandidate = (candidate) => {
        const {cargos} = this.state;
        const cargo = candidate.cargo;
        const cargoObject = cargos.filter(obj => obj.name === cargo)[0];
        if (cargoObject) {
            candidate.cargo = cargoObject;
        }
        return candidate;
    }
    sendPassword = (mail = '', password = '', isLink = false) => {
        if (mail === '' && password === '') {
            this.setState({isLoading:true});
            let {
                password,
                mail,
                company,
            } = this.state;
            const sendEmailToCandidate = {
                candidateUser: mail,
                candidatePassword: password,
                companyName: company.name,
                companyId: company.id
            }
            if (isLink) {
                newCandidateLink.push(sendEmailToCandidate).then(() => {
                    this.setState({isLoading:false,message:"Se ha enviado satisfactoriamente",openSnackbar:true});
                });
            } else {
                newCandidate.push(sendEmailToCandidate).then(() => {
                    this.setState({isLoading:false,message:"Se ha enviado satisfactoriamente",openSnackbar:true});
                });
            }
        } else {
            this.setState({isLoading:true});
            let {
                company,
            } = this.state;
            const sendEmailToCandidate = {
                candidateUser: mail,
                candidatePassword: password,
                companyName: company.name,
                companyId: company.id
            }
            if (isLink) {
                newCandidateLink.push(sendEmailToCandidate).then(() => {
                    this.setState({isLoading:false,message:"Se ha enviado satisfactoriamente",openSnackbar:true});
                });
            } else {
                newCandidate.push(sendEmailToCandidate).then(() => {
                    this.setState({isLoading:false,message:"Se ha enviado satisfactoriamente",openSnackbar:true});
                });
            }
        }
    }
    sendPasswordWhatsapp = (mail = '', password = '', phone = '', isLink = false) => {
        if (password === '' && mail === '' && phone === '') {
            let {
                password,
                mail,
                company,
                phone
            } = this.state;
            const sendEmailToCandidate = {
                candidateUser: mail,
                candidatePassword: password,
                companyName: company.name,
                companyId: company.id,
                phone
            }
            if (isLink) {
                const textMessage = "Estimado Evaluado \nReciba un cordial saludo, como parte del proceso de selección de "+sendEmailToCandidate.companyName+", es necesario que responda la siguiente evaluación. Para poderla llevar a cabo te pedimos que sigas las siguientes instrucciones:\nVaya a la página: https://confieval.com/prueba/"+sendEmailToCandidate.companyId+"/"+sendEmailToCandidate.candidateUser+"/"+sendEmailToCandidate.candidatePassword+"\nSiga las instrucciones que se muestran en la pantalla.\nSi tiene algún problema durante el uso del sistema, puede contactarse con nuestro equipo de soporte Técnico al correo: ayuda@confieval.com.\nNOTA: Confieval®, no solicita información de los evaluados, son las empresas contratantes del servicio, quienes solicitan dicha información y determinan el uso que le dan a la misma.\nTampoco es responsable de los resultados de las evaluaciones ni del uso o las decisiones que las organizaciones que la usan hagan de ella. \nSi tiene cualquier duda o necesita más información, por favor solicítela directamente a la empresa que le asignó la evaluación. Si no desea revelar su información, o llevar a cabo la evaluación, ignore las instrucciones arriba mencionadas y deseche este correo."
                const url = 'https://api.whatsapp.com/send?phone=' + sendEmailToCandidate.phone + '&text=' + encodeURIComponent(textMessage);
                const win = window.open(url, '_blank');
                win.focus();
            } else {
                const textMessage = "Estimado Evaluado \nReciba un cordial saludo, como parte del proceso de selección de "+sendEmailToCandidate.companyName+", es necesario que responda la siguiente evaluación. Para poderla llevar a cabo te pedimos que sigas las siguientes instrucciones:\n\nusuario: "+sendEmailToCandidate.candidateUser+"\ncontraseña: "+sendEmailToCandidate.candidatePassword+"\nAPLICACIÓN DE LA EVALUACIÓN\n\nPara poder rendir la evaluación, siga las siguientes instrucciones: \n\nVaya a la página: https://confieval.com/prueba/"+sendEmailToCandidate.companyId+"\nIngrese el Usuario y la Contraseña que vienen en la parte superior de este correo (Si no aparecen, solicítelas a la persona que le asignó la evaluación)\nSiga las instrucciones que se muestran en la pantalla.\nSi tiene algún problema durante el uso del sistema, puede contactarse con nuestro equipo de soporte Técnico al correo: ayuda@confieval.com.\nNOTA: Confieval®, no solicita información de los evaluados, son las empresas contratantes del servicio, quienes solicitan dicha información y determinan el uso que le dan a la misma.\nTampoco es responsable de los resultados de las evaluaciones ni del uso o las decisiones que las organizaciones que la usan hagan de ella. \nSi tiene cualquier duda o necesita más información, por favor solicítela directamente a la empresa que le asignó la evaluación. Si no desea revelar su información, o llevar a cabo la evaluación, ignore las instrucciones arriba mencionadas y deseche este correo."
                const url = 'https://api.whatsapp.com/send?phone=' + sendEmailToCandidate.phone + '&text=' + encodeURIComponent(textMessage);
                const win = window.open(url, '_blank');
                win.focus();
            }
        } else {
            let {
                company,
            } = this.state;
            const sendEmailToCandidate = {
                candidateUser: mail,
                candidatePassword: password,
                companyName: company.name,
                companyId: company.id,
                phone
            }
            if (isLink) {
                const textMessage = "Estimado Evaluado \nReciba un cordial saludo, como parte del proceso de selección de "+sendEmailToCandidate.companyName+", es necesario que responda la siguiente evaluación. Para poderla llevar a cabo te pedimos que sigas las siguientes instrucciones:\nVaya a la página: https://confieval.com/prueba/"+sendEmailToCandidate.companyId+"/"+sendEmailToCandidate.candidateUser+"/"+sendEmailToCandidate.candidatePassword+"\nSiga las instrucciones que se muestran en la pantalla.\nSi tiene algún problema durante el uso del sistema, puede contactarse con nuestro equipo de soporte Técnico al correo: ayuda@confieval.com.\nNOTA: Confieval®, no solicita información de los evaluados, son las empresas contratantes del servicio, quienes solicitan dicha información y determinan el uso que le dan a la misma.\nTampoco es responsable de los resultados de las evaluaciones ni del uso o las decisiones que las organizaciones que la usan hagan de ella. \nSi tiene cualquier duda o necesita más información, por favor solicítela directamente a la empresa que le asignó la evaluación. Si no desea revelar su información, o llevar a cabo la evaluación, ignore las instrucciones arriba mencionadas y deseche este correo."
                const url = 'https://api.whatsapp.com/send?phone=' + sendEmailToCandidate.phone + '&text=' + encodeURIComponent(textMessage);
                const win = window.open(url, '_blank');
                win.focus();
            } else {
                const textMessage = "Estimado Evaluado \nReciba un cordial saludo, como parte del proceso de selección de "+sendEmailToCandidate.companyName+", es necesario que responda la siguiente evaluación. Para poderla llevar a cabo te pedimos que sigas las siguientes instrucciones:\n\nusuario: "+sendEmailToCandidate.candidateUser+"\ncontraseña: "+sendEmailToCandidate.candidatePassword+"\nAPLICACIÓN DE LA EVALUACIÓN\n\nPara poder rendir la evaluación, siga las siguientes instrucciones: \n\nVaya a la página: https://confieval.com/prueba/"+sendEmailToCandidate.companyId+"\nIngrese el Usuario y la Contraseña que vienen en la parte superior de este correo (Si no aparecen, solicítelas a la persona que le asignó la evaluación)\nSiga las instrucciones que se muestran en la pantalla.\nSi tiene algún problema durante el uso del sistema, puede contactarse con nuestro equipo de soporte Técnico al correo: ayuda@confieval.com.\nNOTA: Confieval®, no solicita información de los evaluados, son las empresas contratantes del servicio, quienes solicitan dicha información y determinan el uso que le dan a la misma.\nTampoco es responsable de los resultados de las evaluaciones ni del uso o las decisiones que las organizaciones que la usan hagan de ella. \nSi tiene cualquier duda o necesita más información, por favor solicítela directamente a la empresa que le asignó la evaluación. Si no desea revelar su información, o llevar a cabo la evaluación, ignore las instrucciones arriba mencionadas y deseche este correo."
                const url = 'https://api.whatsapp.com/send?phone=' + sendEmailToCandidate.phone + '&text=' + encodeURIComponent(textMessage);
                const win = window.open(url, '_blank');
                win.focus();
            } 
        }
    }

    handleReplace = (value) => value ? value : '';

    handleDownloadExcel = () => {
        const {company} = this.state;
        let multiDataSet = [
            {
                columns: [
                  { title: " - "},
                ],
                data: [
                  ["No hay candidatos para mostrar"]
                ],
              },
        ];
        if (this.state.candidates) {
            if (this.state.candidates.length > 0) {
                const items = JSON.parse(JSON.stringify(this.state.candidates)).map(obj => {
                    delete obj.answers;
                    delete obj.active;
                    delete obj.activeEPF;
                    if (obj.cargo) {
                        if (obj.cargo.name) {
                            obj.cargo = obj.cargo.name;
                        }
                    }
                    if (obj.honestyShieldPictures) {
                        obj.honestyShieldPictures = obj.honestyShieldPictures[0];
                    }
                    if (obj.testHonestyShieldPictures) {
                        obj.testHonestyShieldPictures = obj.testHonestyShieldPictures[0];
                    }
                    if (obj.dateStarted) {
                        obj.dateStarted = moment(obj.dateStarted).format('YYYY-MM-DD hh:mm:ss');
                    }
                    if (obj.dateFinished) {
                        obj.dateFinished = moment(obj.dateFinished).format('YYYY-MM-DD hh:mm:ss');
                    }
                    if (obj.dateTestStarted) {
                        obj.dateTestStarted = moment(obj.dateTestStarted).format('YYYY-MM-DD hh:mm:ss');
                    }
                    if (obj.dateTestFinished) {
                        obj.dateTestFinished = moment(obj.dateTestFinished).format('YYYY-MM-DD hh:mm:ss');
                    }
                    obj.smartLink = "https://confieval.com/prueba/"+company.id+"/"+obj.mail+"/"+obj.password 
                    return obj;
                });
                let header = [];
                for (let i = 0; i < items.length; i += 1) {
                    let obj = items[i];
                    const currentHeader = Object.keys(obj);
                    if (currentHeader.length > header.length ) {
                        header = currentHeader;
                    }
                }
                multiDataSet = [
                    {
                        columns: header.map(obj => {
                            return {
                                title: obj
                            }
                        }),
                        data: items.map(row => {
                            const returnValue = [];
                            header.forEach(fieldName => {
                                returnValue.push(this.handleReplace(row[fieldName]));
                            });
                            return returnValue;
                        })
                    }
                ];
            }
        }
        return multiDataSet;
    }

    handleUploadExcel = (e) => {
      const {company, cargos, candidates} = this.state;
      this.setState({isLoading: true});
      let fileObj = e.target.files[0];
      ExcelRenderer(fileObj, async (err, resp) => {
        if (err) {
          console.log('err: ', err);
          this.setState({isLoading: false});
        } else {
          try {
            let {rows} = resp;
            const params = rows.shift();
            const rowsCleared = await rows.filter((obj) => {
                if (candidates.filter(ob => obj[16] === ob.mail)[0]) { // mail is in column 16 from excel
                    this.setState({message:"Se han encontrado correos repetidos en esta importación",openSnackbar:true});
                    return false;
                }
                if (obj.length === 0) {
                    return false;
                }
                return true;
            })
            for (let i = 0; i < rowsCleared.length; i+= 1) {
                const row = rowsCleared[i];
                let candidate = {};
                for (let j = 0; j < params.length; j += 1) {
                    const param = params[j];
                    if (param) {
                        if (param === 'cargo') {
                            const currentCargoName = row[j];
                            const cargo = cargos.filter(obj => obj.name === currentCargoName)[0];
                            if (cargo) {
                                candidate[param] = cargo;
                            }
                        } else {
                            if (row[j]) {
                                candidate[param] = row[j];
                            } else {
                                candidate[param] = '';
                            }
                        }
                    } else {
                        candidate[param] = '';
                    }
                }
                const id =  companies.child(company.id).child("candidates").push().key;
                const password = this.generatePassword(6);
                candidate.id = id;
                candidate.password = password;
                    
                companies.child(company.id).child("candidates").child(id).set(candidate).then(()=>{
                    const sendEmailToCandidate = {
                        candidateUser: candidate.mail,
                        candidatePassword: candidate.password,
                        companyName: company.name,
                        companyId: company.id
                    }
                    newCandidate.push(sendEmailToCandidate);
                }).catch(e => {
                    console.log('e: ', e);

                });
            }
            this.setState({isLoading:false,message:"Se han importado sus "+rowsCleared.length+" candidatos satisfactoriamente",openSnackbar:true});
            this.handleMainFunction();
          } catch (e) {
            console.log('e: ', e);
            this.setState({isLoading: false});
          }
        }
      });               
    }

    handleValidateIsAdmin = () => {
        if (isAdmin()) {
            return true;
        }
        if (this.state.isAdmin) {
            return true;
        }
        return false;
    }

    handleResetTest = () => {
        this.setState({isLoading:true});
        const {
            company,
            id
        } = this.state;
        console.log('company: ', company);
        let place = company.id+"/candidates/"+id;
        companies.child(place+'/started').set(null);
        companies.child(place+'/answers').set(null);
        companies.child(place+'/honestyShieldPictures').set(null);
        companies.child(place+'/finished').set(null);
        companies.child(place+'/dateStarted').set(null);
        companies.child(place+'/dateFinished').set(null);
        // companies.child(company.id+'/examsLeft').set(parseInt(company.examsLeft) + 1);
        this.setState({isLoading:false,message:"Se han eliminado los resultados de la prueba del usuario",openSnackbar:true});
    }

    handleResetTestEvaluation = () => {
        this.setState({isLoading:true});
        const {
            company,
            id
        } = this.state;
        console.log('company: ', company);
        let place = company.id+"/candidates/"+id;
        companies.child(place+'/answersTest').set(null);
        companies.child(place+'/testHonestyShieldPictures').set(null);
        companies.child(place+'/testStarted').set(null);
        companies.child(place+'/testFinished').set(null);
        companies.child(place+'/dateTestStarted').set(null);
        companies.child(place+'/dateTestFinished').set(null);
        // companies.child(company.id+'/examsLeft').set(parseInt(company.examsLeft) + 1);
        this.setState({isLoading:false,message:"Se han eliminado los resultados de la prueba del usuario",openSnackbar:true});
    }
    renderCandidate = () =>{
        const {isNew,nombre, ciudades} = this.state;
        const cargos = this.state.cargos.length>0?this.state.cargos.map((element,index)=>{
            return(
                <MenuItem value={element.name} key={index}>{element.name}</MenuItem>
            )
        }):[];
        const idiomas = idiomasList.length>0?idiomasList.map((element,index)=>{
            return(
                <MenuItem  value={element} key={index}>{element}</MenuItem>
            )
        }):[];
        const paises = paisesList.length>0?paisesList.map((element,index)=>{
            return(
                <MenuItem  value={element} key={index}>{element}</MenuItem>
            )
        }):[];
        const ciudadesMap = ciudades.length>0 && ciudades.map((item,index)=> {
            return {
                value: item.city,
                label: item.city
            }
        });
        return(
            <Paper style={{width:'100%'}}>
                <div>
                        <Toolbar>
                            <Tooltip title="Atras">
                                <IconButton onClick={this.handleGoBack}>
                                    <ArrowBackIos />
                                </IconButton>
                            </Tooltip>
                            <Typography variant="h6" style={{width:400}}>
                                {isNew?'Nuevo candidato':"Modificar "+nombre}
                            </Typography>
                            {
                                !isNew &&
                                    <div className="spacer" />
                            }
                            {
                                !isNew&&
                                    <ConfirmationDialog
                                        confirmationText={"¿Está seguro que desea eliminar este candidato?"}
                                        buttonText={"Eliminar Candidato"}
                                        variant="contained"
                                        icon = {<Delete />}
                                        isDisabled={false}
                                        confirmAction={this.handleDelete}
                                        color="secondary"
                                    />
                            }
                        </Toolbar>
                        <Table style={{width:'100%'}}>
                            <TableBody>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Persona registrante</b></TableCell>
                                    <TableCell>{this.state.personaRegistrante}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Habilitado Para Evaluación de Perfilamiento Psicológico</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.active}
                                                    onChange={e=>this.setState({active:e.target.checked})}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.active===true?'Habilitado':'No Habilitao'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Habilitado para Evaluación Psicológica Forense</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.activeEPF}
                                                    onChange={e=>this.setState({activeEPF:e.target.checked})}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.activeEPF===true?'Habilitado':'No Habilitado'}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Cédula/DNI/Pasaporte/TI</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            autoFocus
                                            value={this.state.ci}
                                            onChange={e=>this.setState({ci:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Cargo</b></TableCell>
                                    <TableCell>
                                        <SelectM
                                            value={this.state.cargo}
                                            onChange={e=>this.setState({cargo:e.target.value})}
                                            fullWidth
                                        >
                                            {cargos}
                                        </SelectM>
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Idioma</b></TableCell>
                                    <TableCell>
                                        <SelectM
                                            value={this.state.idioma}
                                            onChange={e=>this.setState({idioma:e.target.value})}
                                            fullWidth
                                        >
                                            {idiomas}
                                        </SelectM>
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Primer Nombre</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.nombre}
                                            onChange={e=>this.setState({nombre:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Segundo Nombre</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.segundoNombre}
                                            onChange={e=>this.setState({segundoNombre:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Primer Apellido</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.primerApellido}
                                            onChange={e=>this.setState({primerApellido:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Segundo Apellido</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.segundoApellido}
                                            onChange={e=>this.setState({segundoApellido:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Telefono</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.phone}
                                            onChange={e=>isPhone(e.target.value)&&this.setState({phone:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>  
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Email</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.mail}
                                            error={this.state.mail!==''&&!isEmail(this.state.mail)}
                                            onChange={e=>this.setState({mail:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>                 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Contraseña</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.password}
                                            disabled
                                            fullWidth
                                        />
                                    </TableCell>
                                </TableRow>                 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Link de Prueba</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={'https://confieval.com/prueba/'+this.state.company.id}
                                            disabled
                                            fullWidth
                                        />
                                    </TableCell>
                                </TableRow>                 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Dirección</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.address}
                                            onChange={e=>this.setState({address:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Fecha de nacimiento</b></TableCell>
                                    <TableCell>
                                        <InlineDatePicker 
                                            value={this.state.birthDate} 
                                            fullWidth
                                            onChange={this.handleChangeBirthDate} 
                                            disableFuture
                                            clearable
                                            views={['year', 'month','day']}
                                            openToYearSelection
                                            format={'YYYY-MM-DD'}
                                            autoOk
                                        />
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Género</b></TableCell>
                                    <TableCell>
                                        <SelectM 
                                            value={this.state.sexo}
                                            onChange={e=>this.setState({sexo:e.target.value})}
                                            fullWidth
                                        >
                                            <MenuItem value="Femenino">Femenino</MenuItem>
                                            <MenuItem value="Masculino">Masculino</MenuItem>
                                            <MenuItem value="Otro">Otro</MenuItem>
                                        </SelectM>
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Ciudad</b></TableCell>
                                    <TableCell>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            value={this.state.city}
                                            defaultValue={this.state.city}
                                            isClearable={true}
                                            isSearchable={true}
                                            name="Ciudad"
                                            options={ciudadesMap}
                                            onChange={e=>this.setState({city: e})}
                                            fullWidth
                                            placeholder="Ciudad..."
                                        />
                                    </TableCell>
                                </TableRow>   
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Pais</b></TableCell>
                                    <TableCell>
                                        <SelectM
                                            value={this.state.pais}
                                            onChange={e=>this.setState({pais:e.target.value})}
                                            fullWidth
                                        >
                                            {paises}
                                        </SelectM>
                                    </TableCell>
                                </TableRow>     
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Foto</b></TableCell>
                                    <TableCell>
                                            {
                                                this.state.foto &&
                                                    <a href={this.state.foto} rel="noopener noreferrer" target="_blank"><img src={this.state.foto} alt="Logo" style={{width:100, height: 100}}/></a>
                                            }
                                            <Uploader 
                                                dirName={"Empresas"} 
                                                urlUploaded={this.handleUrlUploaded}
                                            />
                                        </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Honesty Shield</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.honestyShield}
                                                    onChange={e=>this.setState({honestyShield:e.target.checked})}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.honestyShield===true?'Honesty Shield Activado':'Honesty Shield Desactivado'}
                                        />
                                    </TableCell>
                                </TableRow> 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Honesty Shield E.P.F</b></TableCell>
                                    <TableCell>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={this.state.testHonestyShield}
                                                    onChange={e=>this.setState({testHonestyShield:e.target.checked})}
                                                    color="secondary"
                                                />
                                            }
                                            label={this.state.testHonestyShield===true?'Honesty Shield para E.P.F Activado':'Honesty Shield para E.P.F. Desactivado'}
                                        />
                                    </TableCell>
                                </TableRow> 
                                {
                                    !this.state.isNew &&
                                        <TableRow>
                                            <TableCell className={"tableRowTitle"}><b>Reenviar Correo electrónico con información de inicio de sesión</b></TableCell>
                                            <TableCell>
                                                <Button onClick={()=>this.sendPassword()} variant="contained">
                                                    Reenviar Correo Electrónico
                                                </Button>
                                                <br />
                                                <br />
                                                <Button onClick={()=>this.sendPassword('','',true)} variant="contained">
                                                    Reenviar Correo Electrónico con Link
                                                </Button>
                                            </TableCell>
                                        </TableRow> 
                                }
                                {
                                    !this.state.isNew &&
                                        <TableRow>
                                            <TableCell className={"tableRowTitle"}><b>Enviar información de inicio de sesión por whatsapp</b></TableCell>
                                            <TableCell>
                                                <Button onClick={()=>this.sendPasswordWhatsapp()} variant="contained">
                                                    Enviar Whatsapp
                                                </Button>
                                                <br />
                                                <br />
                                                <Button onClick={()=>this.sendPasswordWhatsapp('','','',true)} variant="contained">
                                                    Enviar Whatsapp con Link
                                                </Button>
                                            </TableCell>
                                        </TableRow> 
                                }
                                <TableRow>
                                    <TableCell style={{textAlign:'center'}} colSpan={2}><b>Antecedentes Personales</b></TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Estado civil</b></TableCell>
                                    <TableCell>
                                        <SelectM 
                                            value={this.state.estadoCivil}
                                            onChange={e=>this.setState({estadoCivil:e.target.value})}
                                            fullWidth
                                        >
                                            <MenuItem value={"Soltero/a"}>Soltero/a</MenuItem>
                                            <MenuItem value={"Casado/a"}>Casado/a</MenuItem>
                                            <MenuItem value={"Viudo/a"}>Viudo/a</MenuItem>
                                            <MenuItem value={"Divorciado/a"}>Divorciado/a</MenuItem>
                                            <MenuItem value={"Unión de hecho"}>Unión de hecho</MenuItem>
                                        </SelectM>
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Hijos</b></TableCell>
                                    <TableCell>
                                        <SelectM 
                                            value={this.state.hijos}
                                            onChange={e=>this.setState({hijos:e.target.value})}
                                            fullWidth
                                        >
                                            <MenuItem value={0}>0</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={2}>2</MenuItem>
                                            <MenuItem value={3}>3</MenuItem>
                                            <MenuItem value={4}>4</MenuItem>
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={"Más de 5"}>Más de 5</MenuItem>
                                        </SelectM>
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Antecedentes penales</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.antecedentesPenales}
                                            onChange={e=>this.setState({antecedentesPenales:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Antecedentes penales</b></TableCell>
                                    <TableCell>
                                        {
                                            this.state.antecedentesPenalesURL &&
                                                <a href={this.state.antecedentesPenalesURL} rel="noopener noreferrer" target="_blank"><Button variant="contained">Descargar</Button></a>
                                        }
                                        <Uploader 
                                            dirName={"AntecedentesPenales"} 
                                            urlUploaded={this.handleUrlUploadedAntecedentesPenalesURL}
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Antecedentes Médicos</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.antecedentesMedicos}
                                            onChange={e=>this.setState({antecedentesMedicos:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Antecedentes Médicos</b></TableCell>
                                    <TableCell>
                                        {
                                            this.state.antecedentesMedicosURL &&
                                                <a href={this.state.antecedentesMedicosURL} rel="noopener noreferrer" target="_blank"><Button variant="contained">Descargar</Button></a>
                                        }
                                        <Uploader 
                                            dirName={"AntecedentesMedicos"} 
                                            urlUploaded={this.handleUrlUploadedAntecedentesMedicosURL}
                                        />
                                    </TableCell>
                                </TableRow> 
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Medicación / Dosis</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.medicacion}
                                            onChange={e=>this.setState({medicacion:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>   
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Polígrafos Anteriores</b></TableCell>
                                    <TableCell>
                                        <SelectM 
                                            value={this.state.poligrafosAnteriores}
                                            onChange={e=>this.setState({poligrafosAnteriores:e.target.value})}
                                            fullWidth
                                        >
                                            <MenuItem value={false}>No</MenuItem>
                                            <MenuItem value={true}>Si</MenuItem>
                                        </SelectM>
                                    </TableCell>
                                </TableRow>    
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Polígrafos Anteriores</b></TableCell>
                                    <TableCell>
                                        {
                                            this.state.poligrafosAnterioresURL &&
                                                <a href={this.state.poligrafosAnterioresURL} rel="noopener noreferrer" target="_blank"><Button variant="contained">Descargar</Button></a>
                                        }
                                        <Uploader 
                                            dirName={"PoligrafosAnteriores"} 
                                            urlUploaded={this.handleUrlUploadedPoligrafosAnterioresURL}
                                        />
                                    </TableCell>
                                </TableRow>  
                                <TableRow>
                                    <TableCell className={"tableRowTitle"}><b>Información Adicional</b></TableCell>
                                    <TableCell>
                                        <TextField 
                                            value={this.state.informacionAdicional}
                                            onChange={e=>this.setState({informacionAdicional:e.target.value})}
                                            fullWidth
                                            multiline
                                        />
                                    </TableCell>
                                </TableRow>
                                {
                                    !this.state.isNew &&
                                        <TableRow>
                                            <TableCell className={"tableRowTitle"}><b>Vaciar prueba EPP</b></TableCell>
                                            <TableCell>
                                                {this.renderButtonEmptyTest()}
                                            </TableCell>
                                        </TableRow> 
                                }
                                {
                                    !this.state.isNew &&
                                        <TableRow>
                                            <TableCell className={"tableRowTitle"}><b>Vaciar pruebaEPF</b></TableCell>
                                            <TableCell>
                                                {this.renderButtonEmptyTest_()}
                                            </TableCell>
                                        </TableRow> 
                                }
                            </TableBody>
                        </Table>
                        <div className={"submitContainer"}>
                            {this.renderButton()}
                        </div>
                    </div>
            </Paper>
        )
    }

    renderLoader = () => {
        const {isLoading} = this.state;
        if (isLoading) {
            return (
                <div id="overlay">
                    <CircularProgress
                        color="secondary"
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%'
                        }}
                    />
                </div>
            )
        }
        return null;
    }
    render(){
        const {candidate,candidates,title,openSnackbar,message,noPermission, candidatePermission, reportsPermission} = this.state;
        if(noPermission) {
            return <Typography variant="subtitle1">Usted no tiene permiso para acceder a este módulo</Typography>
        }
        return(
            <div className={"tableContainer"}>
            {this.renderLoader()}
            <Snackbar
                onClose={this.handleCloseSnackbar}
                open={openSnackbar}
                autoHideDuration={6000}
                message={<span>{message}</span>}
            />
            {
                candidate===null?
                <Paper>
                    <Toolbar>
                        <Tooltip title="Atras">
                            <IconButton onClick={() => window.history.back()}>
                                <ArrowBackIos />
                            </IconButton>
                        </Tooltip>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                        <div className={"spacer"} />
                        {
                            this.handleValidateIsAdmin() && (
                                <Tooltip title="Descargar plantilla para importación">
                                    <IconButton href="https://firebasestorage.googleapis.com/v0/b/sistema-ssat.appspot.com/o/Importador%20de%20candidatos.xlsx?alt=media&token=ebf3e67e-df61-4d00-a866-7da9548c02af">
                                        <LibraryBooks />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        {
                            this.handleValidateIsAdmin() && (
                                <div>
                                    <input accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" id="icon-button-file" type="file" style={{display: 'none'}}  onChange={this.handleUploadExcel}/>
                                    <label htmlFor="icon-button-file">
                                        <Tooltip title="Importar Candidatos desde Excel">
                                            <IconButton component="span">
                                                <CloudUpload />
                                            </IconButton>
                                        </Tooltip>
                                    </label>                            
                                </div>
                            )
                        }
                        {
                            candidates && (
                                <Tooltip title="Exportar candidatos a Excel">
                                    <ExcelFile
                                        element={
                                            <IconButton>
                                                <CloudDownload />
                                            </IconButton>
                                        }
                                    >
                                        <ExcelSheet dataSet={this.handleDownloadExcel()} name="Candidates"/>
                                    </ExcelFile>
                                </Tooltip>
                            )
                        }
                        <Tooltip title="Nuevo candidato">
                            <IconButton onClick={this.handleAddNew} disabled={!candidatePermission}>
                                <AddCircle style={{color: '#4caf50'}}/>
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                    {
                        candidates &&
                        <div>
                        {
                            candidates.length>0 ?
                            <ReactTable 
                                data={candidates}
                                defaultPageSize={10}
                                previousText= 'Anterior'
                                nextText= 'Siguiente'
                                loadingText= 'Cargando...'
                                noDataText= 'No hay datos'
                                pageText= 'Página'
                                ofText= 'de'
                                rowsText= 'Filas'
                                pageJumpText= 'Ir a página'
                                rowsSelectorText= 'filas por página'
                                filterable 
                                defaultFilterMethod={(filter, row) =>String(row[filter.id]).toLowerCase().includes( filter.value.toLowerCase())}
                                columns={[
                                {
                                    columns: [
                                    {
                                        Header:'Foto',
                                        accessor:'foto',
                                        Cell: row => (
                                            <a href={row.value} target="_blank" rel="noopener noreferrer"><img src={row.value} alt="foto" style={{width:50, height: 50}}/></a>
                                        ),
                                        width:70,
                                    },
                                    {
                                        Header: "Cédula",
                                        accessor: "ci",
                                        width:100,
                                        Cell: row => <div className="cellCustom">{row && row.value && row.value}</div>
                                    },
                                    {
                                        Header: "Nombre",
                                        accessor: "nombre",
                                        width:135,
                                    },
                                    {
                                        Header: "Apellido",
                                        accessor: "primerApellido",
                                        width:135,
                                    },
                                    {
                                        Header: "Ciudad",
                                        accessor: "city",
                                        width:90,
                                        Cell: row => <div className="cellCustom">{row && row.value && row.value}</div>
                                    },
                                    
                                    {
                                        Header:"Email",
                                        accessor:"mail",
                                        width:200,
                                        Cell: row=>(
                                            <a href={"mailto:"+row.value}>{row.value}</a>
                                        )
                                    },
                                    {
                                        Header:"Enviar Email",
                                        accessor:"nombre",
                                        width:125,
                                        Cell: row => candidatePermission ? (
                                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
                                                <Tooltip title={"Enviar credenciales por correo"}>
                                                    <IconButton onClick={()=>this.handleListSendEmail(row.original, false)}>
                                                        <Mail />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Enviar link por correo"}>
                                                    <IconButton onClick={()=>this.handleListSendEmail(row.original, true)}>
                                                        <Mail />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        ): 
                                        <Tooltip title={"No tiene permisos para acceder a este módulo"}>
                                            <IconButton >
                                                <Mail />
                                            </IconButton>
                                        </Tooltip>
                                    },
                                    {
                                        Header:'Activo EPP',
                                        accessor:'active',
                                        width:75,
                                        Cell: row => (
                                            <Tooltip title={row.value ? 'Activo' : 'Inactivo'}>
                                                <IconButton>
                                                    {row.value ? <Done style={{color: "green"}} /> : <Cancel style={{color: "red"}}/>}
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    },
                                    {
                                        Header:'Activo EPF',
                                        accessor:'activeEPF',
                                        width:75,
                                        Cell: row => (
                                            <Tooltip title={row.value ? 'Activo' : 'Inactivo'}>
                                                <IconButton>
                                                    {row.value ? <Done style={{color: "green"}} /> : <Cancel style={{color: "red"}}/>}
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    },
                                    {
                                        Header:"Enviar Whatsapp",
                                        accessor:"nombre",
                                        width:125,
                                        Cell: row => candidatePermission ? (
                                            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
                                                <Tooltip title={"Enviar credenciales por whatsapp"}>
                                                    <IconButton onClick={()=>this.handleListSendWhatsapp(row.original, false)}>
                                                        <Chat />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={"Enviar link por whatsapp"}>
                                                    <IconButton onClick={()=>this.handleListSendWhatsapp(row.original, true)}>
                                                        <Chat />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        ): 
                                        <Tooltip title={"No tiene permisos para acceder a este módulo"}>
                                            <IconButton >
                                                <Chat />
                                            </IconButton>
                                        </Tooltip>
                                    },
                                    {
                                        Header: "Telefono",
                                        accessor: "phone",
                                        width:130,
                                        Cell: row => <div className="cellCustom">{row && row.value && row.value}</div>
                                    },
                                    {
                                        Header:'Detalle',
                                        accessor:'nombre',
                                        width:65,
                                        Cell: row => candidatePermission ? (
                                            <Tooltip title={"Detalle de "+row.value}>
                                                <IconButton onClick={()=>this.handleDetail(row.original,false)}>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        ): 
                                        <Tooltip title={"No tiene permisos para acceder a este módulo"}>
                                            <IconButton >
                                                <Edit />
                                            </IconButton>
                                        </Tooltip>

                                    },
                                    {
                                        Header:'Reportes',
                                        accessor:'id',
                                        width:75,
                                        Cell: row => reportsPermission ? (
                                            <Tooltip title={"Generar reporte"}>
                                                <Link to={'/reporte/'+this.state.company.id+'/'+row.value}  target="_blank">
                                                    <IconButton>
                                                        <ListAltIcon />
                                                    </IconButton>
                                                </Link>
                                            </Tooltip>
                                        ): 
                                        <Tooltip title={"No tiene permisos para acceder a este módulo"}>
                                            <IconButton >
                                                <ListAltIcon />
                                            </IconButton>
                                        </Tooltip>
                                    },
                                    {
                                        Header:'Evaluado',
                                        accessor:'finished',
                                        width:75,
                                        Cell: row => (
                                            <Tooltip title={row.value ? 'Ha rendido la evaluación' : 'No ha rendido la evaluación'}>
                                                <IconButton>
                                                    {row.value ? <Done style={{color: "green"}} /> : <Cancel style={{color: "red"}}/>}
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    },
                                    {
                                        Header:'RGV',
                                        accessor:'reportObject',
                                        width:75,
                                        Cell: row => row.value ? (
                                            <div 
                                                style={{
                                                    margin:'0 auto', 
                                                    borderStyle: 'solid',
                                                    borderColor: resultColorCustomFunction(row && row.value && row.value.resultInt, this.handleAddCargoObjectToCandidate(row.original)), 
                                                    borderWidth: 5,
                                                    borderRadius: '50%',
                                                    width: 40
                                                }}
                                            >
                                                <span style={{ fontSize: 18, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                                    {row && row.value && row.value.resultInt}
                                                </span>
                                            </div>
                                        ) : <span />
                                    },
                                    {
                                        Header:'PE',
                                        accessor:'cargo',
                                        width:75,
                                        Cell: row => <div 
                                                style={{
                                                    margin:'0 auto', 
                                                    borderStyle: 'solid',
                                                    borderColor: '#59b0d7', 
                                                    borderWidth: 5,
                                                    borderRadius: '50%',
                                                    width: 40
                                                }}
                                            >
                                                <span style={{ fontSize: 18, fontFamily: 'Poppins', textAlign: 'center', margin: '0 auto', display: 'table'}}>
                                                    {this.handleCalculateCargoAverage(row.value)}
                                                </span>
                                            </div>
                                    },
                                    {
                                        Header: "Cargo",
                                        accessor: "cargo",
                                        width:400,
                                        Cell: row => <div className="cellCustom">{row && row.value && row.value.name}</div>
                                    },
                                ]
                                }
                                ]}
                                className={"-striped -highlight table"}

                            />

                            :
                            <Typography variant="subtitle1">
                                No se han encontrado resultados
                            </Typography>
                        }
                        </div>
                    }
                </Paper>
            :
                this.renderCandidate()
            }
        </div>
        )
    }
}